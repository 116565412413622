import Endpoint from './Endpoint';

/**
 * Endpoint Collection.
 *
 * @author      Ben Carey <bdmc@sinemacula.co.uk>
 * @copyright   2022 Sine Macula Limited.
 */
export default class EndpointCollection {

    /**
     * Endpoint collection constructor.
     *
     * @param {array}  endpoints
     */
    constructor(endpoints = []) {

        this.items = [];

        endpoints.forEach(endpoint => this.add(new Endpoint(endpoint)));

    }

    /**
     * Add endpoint to collection.
     *
     * @param {object}  endpoint
     */
    add(endpoint) {
        this.items.push(endpoint);
    }

    /**
     * Find and return the endpoint based on the supplied
     * name.
     *
     * @param {string}  name
     */
    find(name) {
        return this.items.find(item => item.name === name);
    }

}
