<template>
    <div class="bg-gray-950 p-3 rounded-t-md grid place-items-center">
        <span class="flex items-center space-x-3 select-none text-gray-50">

            <div
                class="h-5 w-5 text-gray-400 cursor-pointer"
                @click="() => modifyYear(-1)"
            >
                next
            </div>
            <span>{{ year }}</span>

            <div
                class="h-5 w-5 text-gray-400 cursor-pointer"
                @click="() => modifyYear(1)"
            >
                >
            </div>
        </span>
    </div>
</template>

<script setup>
    import dayjs from 'dayjs';
    import { ref } from 'vue';

    const yearEmit = defineEmits([ 'selected' ]);

    const year = ref(dayjs().year());

    function modifyYear(v) {
        year.value += v;
        yearEmit('selected', year.value);
    }
</script>
