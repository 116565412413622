import PaymentMethod from '../../models/PaymentMethod';
import { formatPaymentMethods } from '../../helpers/card.helper';

const namespaced = true;

const getInitialState = () => {
    return {
        paymentMethods: [],
        clientSecret: {},
        selectedPaymentMethod: null
    };
};

const state = getInitialState();

const getters = {
    paymentMethods: state => state.paymentMethods,
    clientSecret: state => state.clientSecret,
    selectedPaymentMethod: state => state.selectedPaymentMethod
};

const mutations = {
    SET_CLIENT_SECRET: (state, payload) => {
        state.clientSecret = payload;
    },
    SET_SELECTED_PAYMENT_METHOD: (state, payload) => {
        state.selectedPaymentMethod = payload;
    },
    SET_PAYMENT_METHODS: (state, payload) => {
        state.paymentMethods = formatPaymentMethods(payload).map(pm => new PaymentMethod(pm));
    },
    ADD_PAYMENT_METHOD: (state, payload) => {
        state.paymentMethods = formatPaymentMethods([
            ...state.paymentMethods,
            payload
        ]).map(paymentMethod => new PaymentMethod(paymentMethod));
    },
    REMOVE_PAYMENT_METHOD_BY_ID: (state, payload) => {
        state.paymentMethods = state.paymentMethods.filter(paymentMethod => paymentMethod.id !== payload);
    },
    UPDATE_PAYMENT_METHOD: (state, payload) => {
        const paymentMethodIndex = state.paymentMethods.findIndex(paymentMethod => paymentMethod.id === payload.id);

        state.paymentMethods[paymentMethodIndex] = payload;

        state.paymentMethods = [ ...state.paymentMethods ];
    },
    RESET_STATE: state => {
        Object.assign(state, getInitialState());
    }
};

const actions = {
    GET_CLIENT_SECRET: async context => {
        try {
            const response = await window.api.call('setup-intent-client-secret');

            context.commit('SET_CLIENT_SECRET', response.data.data);

            return response.data.data;
        } catch (error) {
            console.error('Error fetching client secret:', error);

        }
    },
    CREATE_PAYMENT_METHOD: async (context, { payment_method, is_default }) => {
        const response = await window.api.call('create-payment-methods', {
            payment_method,
            is_default
        });

        context.commit('SET_SELECTED_PAYMENT_METHOD', response.data.data);
        context.commit('ADD_PAYMENT_METHOD', response.data.data);

        return response.data.data;
    },

    GET_USER_PAYMENT_METHODS: async context => {
        try {
            const response = await window.api.call('get-payment-methods');

            context.commit('SET_PAYMENT_METHODS', response.data.data);

            return response.data.data;
        } catch (error) {
            console.error('Error fetching user payment methods:', error);

        }
    },
    DELETE_PAYMENT_METHOD: async (context, payload) => {
        const response = await window.api.call('delete-payment-method', { id: payload });

        context.commit('REMOVE_PAYMENT_METHOD_BY_ID', payload);

        return response.data.data;
    }
};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
};
