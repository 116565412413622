<template>
    <div class="h-full flex flex-col">
        <div class="flex flex-col justify-center px-6 py-8 md:py-8">
            <div class="flex justify-center items-center mb-14">
                <p class="text-navy text-[18px] items-center text-center">
                    Payment Method
                </p>
            </div>
            <div class="flex relative items-center">
                <div>
                    <p class="text-navy text-[28px] w-[286px]">
                        Enter your card details
                    </p>

                    <p
                        v-if="errorMessage"
                        class="text-[14px] text-flushing-pink font-medium"
                    >
                        {{ errorMessage }}
                    </p>
                    <p
                        v-else
                        class="text-navy text-[14px]"
                    >
                        We’ll save these to your account
                    </p>
                </div>

                <img
                    class="absolute -right-[50px] -top-[20px] w-[140px] md:-right-[60px]"
                    :src="require('/static/assets/images/misc/clouds-grey.svg')"
                    alt="Clouds Grey"
                >
            </div>
        </div>

        <CreatePaymentMethodForm />
        <div class="flex mt-auto items-center justify-center px-4 md:px-0">
            <div class="flex w-full h-[65px] md:w-[360px] mb-8">
                <button
                    v-if="!isLoading"
                    type="submit"
                    class="btn-primary h-full mb-8"
                    @click="onClickReviewOrder"
                >
                    Review Order
                </button>
                <img
                    v-else
                    class="animate-spin mx-auto my-auto w-[30px] h-[30px]"
                    :src="
                        require('static/assets/images/misc/icons-spinners-i-os--black.svg')
                    "
                    alt="Icon Spinner"
                >
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref } from '@vue/reactivity';
    import { mapActions } from 'root-js/store/lib.js';
    import { stripe } from 'root-js/services/StripeService';
    import { useGtag } from 'vue-gtag-next';

    import CreatePaymentMethodForm from 'root-js/components/common/CreatePaymentMethodForm.vue';

    const emits = defineEmits([ 'success' ]);

    const { CREATE_PAYMENT_METHOD } = mapActions('payments');

    const isLoading = ref(false);
    const errorMessage = ref('');

    const { event } = useGtag();

    async function onClickReviewOrder() {
        isLoading.value = true;
        try {
            const response = await stripe.confirmSetup();
            if (response.error) {
                errorMessage.value = response.error.message;
            } else {
                await CREATE_PAYMENT_METHOD({
                    payment_method: response.setupIntent.payment_method,
                    is_default: 1
                });
                emits('success');


                event('view_cart');
            }
        } catch (error) {
            console.log(error);
        }

        isLoading.value = false;
    }
</script>
