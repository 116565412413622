const namespaced = true;

const state = {
    drawerShown: null,
    openedCount: 0
};

const getters = {
    drawerShown: state => state.drawerShown,
    openedCount: state => state.openedCount
};

const mutations = {
    SHOW_DRAWER: (state, payload) => {
        state.drawerShown = payload;
    },
    SET_OPEN_COUNT: (state, payload) => {
        state.openedCount = payload;
    }
};

const actions = {};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
};
