<template>
    <input
        autocomplete="off"
        type="text"
        class="
			bg-white
			py-[16px]
			px-[26px]
			rounded-[20px]
			text-[14px] text-navy
			transition
			duration-75
			ease-linear
			placeholder:text-bruised-ego
			shadow-input
			outline-none
			focus:shadow-md
			disabled:bg-slate-50
			disabled:border-slate-300
			disabled:text-slate-300
			disabled:placeholder-slate-300
			disabled:shadow-none
		"
        :class="[ props.sizeClass ]"
        :value="props.value"
        @input="onValueUpdate"
    >
</template>

<script setup>
    const emits = defineEmits([ 'update:value' ]);

    const props = defineProps({
        sizeClass: {
            default: 'w-full md:w-[360px]',
            type: String
        },
        value: String
    });

    function onValueUpdate($event) {
        emits('update:value', $event.target.value);
    }
</script>
