<template>
    <form
        id="update-box-details"
        class="h-full flex flex-col"
        autocomplete="off"
        novalidate
        @submit.prevent="submit"
        @keydown="
            editForm.resetValidation('general');
            editForm.resetValidation($event.target.name);
        "
        @keyup.enter.exact="submit"
    >
        <div class="flex flex-col justify-center px-6 py-8 md:py-8">
            <div class="flex justify-center items-center mb-14">
                <p class="text-navy text-[18px] items-center text-center">
                    Box Details
                </p>
            </div>
            <div class="flex relative items-center">
                <div>
                    <p class="text-navy text-[28px] w-[286px]">
                        Update box details
                    </p>

                    <p class="text-navy text-[14px] w-[243px]">
                        These will help you to keep track of your box
                    </p>
                </div>

                <img
                    class="absolute -right-[50px] -top-[20px] w-[140px] md:-right-[60px]"
                    :src="require('/static/assets/images/misc/clouds-grey.svg')"
                    alt="Clouds Grey"
                >
            </div>
        </div>
        <div class="mb-16">
            <BoxDetails
                v-model:name="editForm.name"
                v-model:description="editForm.description"
                :disabled="isLoading"
            />
            <div class="flex flex-col pt-2 mb-5 px-10">
                <span
                    v-if="editForm.errors.has('name')"
                    class="flex text-flushing-pink text-[10px]"
                    v-text="editForm.errors.get('name')"
                />

                <span
                    v-if="editForm.errors.has('description')"
                    class="flex text-flushing-pink text-[10px]"
                    v-text="editForm.errors.get('description')"
                />
            </div>
        </div>
        <div class="flex mt-auto items-center justify-center px-4 md:px-0">
            <div class="flex w-full h-[65px] md:w-[360px] mb-8">
                <button
                    v-if="!isLoading"
                    class="btn-primary h-full"
                    type="submit"
                >
                    Save
                </button>
                <img
                    v-else
                    class="animate-spin mx-auto my-auto w-[30px] h-[30px]"
                    :src="
                        require('static/assets/images/misc/icons-spinners-i-os--black.svg')
                    "
                    alt="Icon Spinner"
                >
            </div>
        </div>
    </form>
</template>

<script setup>
    import { ref, reactive } from '@vue/reactivity';
    import { computed } from '@vue/runtime-core';
    import { formatBoxDetails } from '../../helpers/box.helper';
    import { mapMutations } from 'root-js/store/lib.js';

    import Form from 'root-js/utils/API/Form';
    import BoxDetails from 'root-js/components/common/BoxDetails.vue';

    const emits = defineEmits([ 'success' ]);

    const props = defineProps({ box: Object });

    const { UPDATE_BOX } = mapMutations('boxes');

    const isLoading = ref(false);

    const editForm = reactive(new Form('update-box',
                                       {
                                           id: { value: props.box.id },
                                           name: { value: props.box.title },
                                           description: { value: props.box.description }
                                       },
                                       null,
                                       { success: () => emits('success') }));

    const isValuesChanged = computed(() => editForm.name !== props.box.title
        || editForm.description !== props.box.description);

    async function submit() {
        if (!isValuesChanged.value) {
            emits('success');
        }

        isLoading.value = true;

        try {
            const { data: boxDetails } = await editForm.submit(true);
            UPDATE_BOX(formatBoxDetails(boxDetails));
            emits('success');
        } catch (error) {

        // Error intentionally ignored or handled elsewhere
        }

        isLoading.value = false;
    }
</script>
