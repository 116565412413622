import dayjs from 'root-js/plugins/Dayjs';

/**
 * Pickup Model.
 *
 * @author      Ben Carey <bdmc@sinemacula.co.uk>
 * @copyright   2022 Sine Macula Limited.
 */
export default class Pickup {
    constructor(data) {

        // Initialize properties
        this.pickupDate = 'N/A';
        this.startTime = 'N/A';
        this.endTime = 'N/A';
        this.charge = 0;
        this.dates = null;

        if (!data) {
            return;
        }

        // Assign data to properties
        Object.assign(this, data);

        const dates = Object.keys(data.dates);
        const firstDate = dates[0];
        const firstTime = data.dates[firstDate][0];
        const lastTime
            = data.dates[firstDate][data.dates[firstDate].length - 1];

        // Format dates and times
        this.pickupDate = dayjs(firstDate).format('MMMM DD, YYYY');
        this.startTime = dayjs(`${firstDate} ${firstTime}`).format('hh:mm A');
        this.endTime = dayjs(`${firstDate} ${lastTime}`).format('hh:mm A');
    }
}
