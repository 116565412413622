<template>
    <div class="w-full">
        <h2 class="text-[22px] xs:text-[28px] text-navy mb-2">
            Got a partner code?
        </h2>
        <p class="text-[14px] text-navy mb-6">
            Enter your partner code if you have one
        </p>

        <div class="xs:flex mb-8 gap-4 items-center">
            <input
                ref="partnerCodeInput"
                v-model="partnerCode"
                placeholder="Partner code (optional)"
                class="
					bg-transparent
					border border-unknown-grey-400
					text-navy
					max-w-full
					w-[298px]
					h-[44px]
					rounded-[25px]
					outline-none
					transition
					duration-75
					ease-linear
					px-[22px]
					mb-6
					placeholder:text-light-bite
					focus:border-navy
					disabled:bg-unknown-grey-500 disabled:text-unknown-grey-600
					xs:mb-0 xs:text-[18px]
					md:w-[310px] md:h-[50px]
				"
            >
            <button
                class="
					hidden
					w-[55px]
					h-[55px]
					p-[10px]
					rounded-full
					bg-flushy-pink-gradient
					shadow-ambient
					hover:bg-bloody-nose-gradient
					disabled:bg-flushy-pink-gradient disabled:opacity-50
					xs:block
				"
                :disabled="!partnerCode"
                @click="$emit('confirm')"
            >
                <i class="icon icon-arrow-right text-white text-[27px]" />
            </button>
            <button
                class="btn-primary max-w-full w-[300px] h-[55px] mb-2 xs:hidden"
                :disabled="!partnerCode"
                @click="$emit('confirm')"
            >
                Continue
            </button>
        </div>

        <div class="flex items-center gap-2">
            <i class="icon icon-help-circle-outline text-navy text-[22px]" />

            <p class="text-navy text-[12px]">
                Don’t have a partner code?
                <button
                    class="text-flushing-pink font-medium hover:text-bloody-nose"
                    @click="$emit('skip')"
                >
                    Skip this step
                </button>
            </p>
        </div>
    </div>
</template>

<script setup>
    import { ref } from '@vue/reactivity';
    import { onMounted } from '@vue/runtime-core';

    const partnerCodeInput = ref(null);
    const partnerCode = ref('');

    onMounted(() => {
        partnerCodeInput.value.focus();
    });
</script>
