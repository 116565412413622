<template>
    <Teleport to="#outside-portal">
        <transition name="fade">
            <div
                v-if="props.isShown"
                class="
					fixed
					top-0
					left-0
					w-screen
					h-screen
					flex
					items-center
					justify-center
					z-[70]
				"
            >
                <div
                    class="bg-white rounded-[11px] relative overflow-hidden"
                    :class="[props.sizeClass, props.spacingClass]"
                >
                    <slot />
                </div>
            </div>
        </transition>
    </Teleport>
</template>

<script setup>
    import { watch } from 'vue';
    import { BUS_EVENTS } from 'root-js/constants/bus-events.const.js';

    import bus from 'root-js/services/BusService';

    const props = defineProps({
        isShown: Boolean,
        sizeClass: {
            type: String,
            default: 'w-[350px] xs:w-[435px] sm:w-[600px]'
        },
        spacingClass: {
            type: String,
            default: 'py-[42px] px-[45px]'
        }
    });

    watch(() => props.isShown,
          () => {
              if (props.isShown) {
                  bus.emit(BUS_EVENTS.SHOW_BACKDROP);
                  return;
              }
              bus.emit(BUS_EVENTS.HIDE_BACKDROP);
          });
</script>
