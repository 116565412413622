<template>
    <svg
        width="50"
        height="50"
        viewBox="0 0 50 50"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g
            stroke="currentColor"
            stroke-width="1.5"
            fill="none"
            fill-rule="evenodd"
            stroke-linecap="round"
            stroke-linejoin="round"
        >
            <path
                d="M41.006 23.7c.595-.63 5.303-19.784 5.294-20.11-.012-.422-.219-.579-.601-.59-.669-.02-19.025 5.368-19.199 5.555M38.2 21.212C36.904 22.565 20.011 39.9 20.011 39.9c-.317-.157-10.057-10.571-10.161-10.918.91-1.035 18.32-17.882 18.32-17.882"
            />
            <path
                d="M14.7 34.4c-.5.625-1.716.461-2.26.582-1.8.4-2.691 1.91-2.054 3.806.183.546.514 1.043.666 1.595.503 1.826-.263 2.993-2.2 2.78-.764-.085-1.506-.39-2.27-.463-1.054-.1-2.181.338-2.177 1.681.001.434-.023.878-.027 1.316-.002.232-.034.46-.122.654-.228.505-.67.709-1.256.634M29.2 20.1c-3.28 3.32-6.594 6.606-9.9 9.9M26.5 9.3c.391.47 11.193 11.331 14.4 14.4"
            />
        </g>
    </svg>
</template>
