<template>
    <div class="relative">
        <Input
            v-model:value="searchInput"
            :placeholder="props.placeholder"
            @input="onValueUpdate"
        />
        <div
            v-if="isOptionsShown"
            v-click-outside="hideOptions"
            class="absolute w-full z-10"
        >
            <div
                class="
					bg-white
					text-[14px] text-left text-navy
					w-full
					max-h-[150px]
					overflow-y-auto
					rounded-[20px]
					mt-2
					shadow-sm
				"
            >
                <div class="flex flex-col py-3">
                    <li
                        v-for="option in options"
                        :key="option"
                        class="
							truncate
							cursor-pointer
							list-none
							whitespace-nowrap
							py-3
							px-[26px]
							hover:bg-gray-100
						"
                        @click="onOptionClicked(option)"
                    >
                        {{ option.displayText }}
                    </li>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref, computed, watch } from 'vue';
    import debounce from 'lodash/debounce';
    import Input from 'root-js/components/common/Input.vue';

    const emits = defineEmits([ 'search' ]);

    const props = defineProps({
        placeholder: String,
        options: Array
    });

    const searchInput = ref('');
    const isOptionsShown = ref(false);
    const selectedOption = ref(null);

    watch(() => props.options,
          () => {
              if (!props.options.length) {
                  return;
              }

              isOptionsShown.value = true;
          });
    const onValueUpdate = debounce(() => {
        emits('search', searchInput.value);
    }, 300);

    const isOptionsObject = computed(() => props.options.length && typeof props.options[0] === 'object');

    function hideOptions() {
        isOptionsShown.value = false;
    }

    function onOptionClicked(option) {
        emits('update:value', option);

        searchInput.value = option.displayText;
        selectedOption.value = option;
        hideOptions();
    }
</script>
