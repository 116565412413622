<template>
    <svg
        width="50"
        height="50"
        viewBox="0 0 50 50"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g
            stroke="currentColor"
            stroke-width="1.44"
            fill="none"
            fill-rule="evenodd"
            stroke-linecap="round"
            stroke-linejoin="round"
        >
            <path
                d="M32.446 12.82c.904.005 1.808-.05 2.71.033 1.466.137 2.877 1.357 3.178 3.061a5.5 5.5 0 0 1 .077.967c-.002 8.242-.006 16.484.007 24.726.001.615.022 1.213-.157 1.814C37.876 44.716 36.527 46 34.924 46a9124.27 9124.27 0 0 0-19.36.001c-1.814.002-3.43-1.593-3.531-3.426-.013-.228-.031-.457-.031-.685-.001-8.39 0-16.78-.002-25.169 0-1.823 1.07-3.336 2.83-3.818.296-.081.617-.08.927-.084.787-.012 4.184.001 4.57 0 3.226-.007 6.453-.007 9.68 0l2.44.001zM31.903 10.097c1.257 0 3.335-.631 3.891-1.4.67-.927.772-1.977.309-3.017-.48-1.078-1.351-1.67-2.523-1.673-5.108-.013-10.216-.003-15.324-.007-1.139 0-2.335.614-2.755 2.117-.328 1.172.052 2.467 1.152 3.23.46.32 1.805.75 3.126.707.065-.002 10.046-.065 12.085.023"
            />
            <path
                d="M35.032 33.764c-.01-.708-.26-1.327-.666-1.9-.209-.295-.028-.612.041-.905.84-3.57-.178-6.592-2.777-9.075-1.343-1.283-3.007-2.008-4.872-2.229-3.115-.368-5.734.636-7.875 2.893-1.248 1.314-2.001 2.9-2.29 4.696-.197 1.221-.195 2.433.148 3.637.123.43.113.835-.171 1.274-.627.97-.698 2.007-.058 3.031.511.819 1.267 1.2 2.207 1.265 1.15.08 1.217.194 1.22 1.225 0 .477-.035.953.392 1.307.191.159.387.257.632.32a19.51 19.51 0 0 0 5.715.58c.917-.04 1.829-.163 2.717-.399.276-.072.555-.12.83-.185.674-.156 1.009-.568 1.027-1.268.01-.37-.04-.738.066-1.11.079-.27.232-.39.483-.43.13-.02.263-.04.394-.038 1.239.014 2.022-.672 2.553-1.706.148-.287.192-.628.284-.944M19.452 10.097v2.709M31.645 10.097v2.709"
            />
            <path
                d="M21.536 26.36c-.333.082-.661.17-.963.343-.92.53-1.3 1.576-1.043 2.693.204.886 1.234 1.72 2.237 1.7 1.047-.02 1.812-.498 2.21-1.403.39-.887.252-1.811-.41-2.557-.514-.578-1.211-.818-1.993-.777M29 26.355c-.319.094-.633.179-.935.338-.877.462-1.286 1.47-1.129 2.51.122.808.914 1.672 1.869 1.85 1.328.247 2.53-.578 2.779-1.776.285-1.372-.445-2.48-1.77-2.85-.259-.072-.515-.068-.776-.072M25.548 33.129v4.065"
            />
        </g>
    </svg>
</template>
