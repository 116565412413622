import { CARD_BRAND, CARD_BRAND_DETAILS } from '../constants/space.const';

export const getCardBrandColor = (cardBrand, colorIndex) => {
    if (!CARD_BRAND_DETAILS[cardBrand]) {
        return CARD_BRAND_DETAILS[CARD_BRAND.SPACE].colors[colorIndex];
    }

    return CARD_BRAND_DETAILS[cardBrand].colors[colorIndex];
};

export const formatPaymentMethods = paymentMethods => {
    if (!paymentMethods) {
        return [];
    }

    const cardBrandsCount = Object.values(CARD_BRAND).reduce((acc, cur) => {
        acc[cur] = 0;
        return acc;
    }, {});

    return paymentMethods.map(paymentMethod => {
        const cardBrand
      = typeof cardBrandsCount[paymentMethod.card_brand] !== 'undefined'
          ? paymentMethod.card_brand
          : CARD_BRAND.SPACE;

        const currentBrandIndex = cardBrandsCount[cardBrand];
        const cardColors = CARD_BRAND_DETAILS[cardBrand].colors;
        const colorIndex = currentBrandIndex % cardColors.length;

        cardBrandsCount[cardBrand] += 1;

        const color = getCardBrandColor(cardBrand, colorIndex);

        return {
            ...paymentMethod,
            color
        };
    });
};
