<template>
    <form
        id="update-user"
        autocomplete="off"
        novalidate
        @submit.prevent="submit"
        @keydown="
            updateUserForm.resetValidation('general');
            updateUserForm.resetValidation($event.target.name);
        "
        @keyup.enter.exact="submit"
    >
        <div class="text-navy">
            <router-link
                :to="{ name: 'Settings' }"
                class="flex items-center mr-4 hover:opacity-90 lg:pointer-events-none"
            >
                <i class="icon icon-arrow-left text-navy mr-4 lg:hidden" />
                <p class="text-[14px] text-navy">
                    Settings / Profile
                </p>
            </router-link>

            <div class="mb-8 relative flex">
                <div class="mt-8 mr-10 w-[250px]">
                    <h1
                        class="text-[28px] text-navy tracking-[-0.7px] leading-[1.29] mb-2"
                    >
                        Your information
                    </h1>
                    <p
                        v-if="updateUserForm.errors.get('image')"
                        class="text-flushing-pink text-[14px]"
                        v-text="updateUserForm.errors.get('image')"
                    />
                    <p
                        v-else
                        class="text-[14px] text-navy"
                    >
                        Edit and save your details
                    </p>
                </div>
                <img
                    class="mt-[0.45rem]"
                    :src="require('/static/assets/images/misc/clouds-grey.svg')"
                    alt="Clouds Grey"
                >
            </div>

            <div class="flex gap-6 mb-4 items-center">
                <div>
                    <i
                        v-if="!imageUrl"
                        class="
							icon icon-profile-circle-solid
							text-unknown-grey text-[140px]
						"
                    />
                    <img
                        v-else
                        :src="imageUrl"
                        class="w-[140px] h-[140px] object-cover object-center rounded-full"
                    >
                </div>
                <div class="flex flex-col">
                    <button
                        class="btn-primary font-normal text-[10px] w-[120px] h-[34px] mb-2"
                        :disabled="isLoading"
                        @click.prevent="$refs.userImageInput.click"
                    >
                        Choose file
                    </button>
                    <input
                        ref="userImageInput"
                        type="file"
                        class="hidden"
                        accept="image/*"
                        @change="onFileChange"
                    >

                    <button
                        class="
							bg-transparent
							border border-navy
							text-navy text-[10px]
							rounded-full
							w-[120px]
							h-[34px]
							mb-2
							opacity-50
							transition
							duration-75
							ease-linear
							disabled:bg-navy-25 disabled:
							hover:none hover:bg-navy hover:text-white
						"
                        :disabled="isLoading"
                        @click.prevent="onDeleteImage"
                    >
                        Delete image
                    </button>

                    <p class="text-[10px] text-navy">
                        Accepted formats: jpg, png, and svg <br>
                        Max file size 5MB
                    </p>
                </div>
            </div>

            <div class="flex flex-col mb-4">
                <Input
                    v-model:value="updateUserForm.first_name"
                    placeholder="First name"
                    size-class="w-full md:w-[360px]"
                    class="mb-4"
                    :class="{
                        'border border-flushing-pink text-flushing-pink':
                            updateUserForm.errors.has('first_name')
                    }"
                    :disabled="isLoading"
                />
                <Input
                    v-model:value="updateUserForm.last_name"
                    placeholder="Last name"
                    size-class="w-full md:w-[360px]"
                    class="mb-4"
                    :class="{
                        'border border-flushing-pink text-flushing-pink':
                            updateUserForm.errors.has('last_name')
                    }"
                    :disabled="isLoading"
                />
                <Input
                    v-model:value="updateUserForm.email"
                    placeholder="Email Address"
                    size-class="w-full md:w-[360px]"
                    :class="{
                        'border border-flushing-pink text-flushing-pink':
                            updateUserForm.errors.has('email')
                    }"
                    :disabled="isLoading"
                />
            </div>

            <div class="flex flex-col pt-2 mb-6">
                <span
                    v-if="updateUserForm.errors.has('email')"
                    class="text-flushing-pink text-[10px] px-5"
                    v-text="updateUserForm.errors.get('email')"
                />

                <span
                    v-if="updateUserForm.errors.has('first_name')"
                    class="text-flushing-pink text-[10px] px-5"
                    v-text="updateUserForm.errors.get('first_name')"
                />
                <span
                    v-if="updateUserForm.errors.has('last_name')"
                    class="text-flushing-pink text-[10px] px-5"
                    v-text="updateUserForm.errors.get('last_name')"
                />
            </div>
        </div>

        <div class="flex w-full h-[65px] md:w-[360px]">
            <button
                v-if="!isLoading"
                class="
					bg-navy
					text-white
					font-medium
					rounded-full
					w-full
					h-full
					transition
					duration-75
					ease-linear
					disabled:bg-navy-25
					md:w-[360px]
				"
                type="submit"
                :disabled="isAddButtonDisabled"
            >
                Save
            </button>
            <img
                v-else
                class="animate-spin mx-auto my-auto w-[30px] h-[30px]"
                :src="
                    require('static/assets/images/misc/icons-spinners-i-os--black.svg')
                "
                alt="Icon Spinner"
            >
        </div>
    </form>
</template>

<script setup>
    import { ref, reactive } from '@vue/reactivity';
    import { onMounted, computed } from '@vue/runtime-core';
    import { useMeta } from 'vue-meta';
    import { mapGetters, mapActions, mapMutations } from 'root-js/store/lib.js';

    import Input from 'root-js/components/common/Input.vue';
    import Form from 'root-js/utils/API/Form';

    useMeta({ title: 'Space / Profile' });

    const emits = defineEmits([ 'success' ]);

    const { user } = mapGetters('user');
    const { SET_USER } = mapMutations('user');
    const { GET_USER } = mapActions('user');

    const isLoading = ref(false);
    const tempImageUrl = ref(null);
    const isImageDeleted = ref(false);

    const updateUserForm = reactive(new Form('update-user',
                                             {
                                                 _method: { value: 'patch' },
                                                 first_name: {
                                                     value: user.value.first_name,
                                                     rule: 'required',
                                                     max: 20
                                                 },
                                                 last_name: {
                                                     value: user.value.last_name,
                                                     rule: 'required',
                                                     max: 20
                                                 },
                                                 email: { value: user.value.email, rule: 'required', max: 20 },
                                                 image: { value: undefined, rule: 'file|image' }
                                             },
                                             null,
                                             { success: () => {} }));

    const isAddButtonDisabled = computed(() => updateUserForm.first_name == user.value.first_name
        && updateUserForm.last_name == user.value.last_name
        && updateUserForm.email == user.value.email
        && tempImageUrl.value != imageUrl.value);

    const imageUrl = computed(() => {
        if (isImageDeleted.value) {
            return null;
        }

        const userImage = user.value.image ? user.value.originalImage : null;

        return tempImageUrl.value || userImage;
    });

    onMounted(() => {
        GET_USER();
    });

    function onDeleteImage() {
        updateUserForm.image = null;
        isImageDeleted.value = true;
    }

    function onFileChange(e) {
        const files = e.target.files || e.dataTransfer.files;
        if (!files.length) {
            return;
        }

        updateUserForm.image = files[0];

        const reader = new FileReader;

        reader.onload = e => {
            tempImageUrl.value = e.target.result;
        };
        reader.readAsDataURL(updateUserForm.image);
    }

    async function submit() {
        isLoading.value = true;

        try {
            const { data: user } = await updateUserForm.submit();
            SET_USER(user);
        } catch (error) {
            console.log('error', error);
        }
        isLoading.value = false;
    }
</script>
