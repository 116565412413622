<template>
    <div class="relative group">
        <button
            class="
				relative
				max-w-full
				w-[98px]
				h-[44px]
				gap-2
				text-navy
				border-navy border
				rounded-[25px]
				flex
				items-center
				cursor-pointer
				transition
				duration-75
				ease-linear
				py-1
				px-3
				spacing-x-2
				disabled:cursor-not-allowed
				disabled:bg-unknown-grey-500
				disabled:text-unknown-grey-600
				disabled:border-opacity-50
				sm:text-[18px] sm:w-[114px] sm:h-[50px]
			"
            :class="{
                'text-unknown-grey-400 border-unknown-grey-400': !isHasSelected,
                'text-navy border-navy': isHasSelected
            }"
            :disabled="props.disabled"
            @click.prevent=""
        >
            <img
                class="w-[24px] h-[20px]"
                :src="props.selected.image"
                alt=""
            >
            {{ props.selected.displayShort }}
            <i
                class="
					icon icon-chevron-down
					text-[12px]
					absolute
					top-1/2
					right-3
					transform
					-translate-y-1/2
				"
            />
        </button>

        <div
            v-if="!props.disabled && !isUserSelect && options.length > 1"
            class="fixed z-10 hidden group-hover:block"
        >
            <div class="bg-white border border-navy text-navy rounded-[25px] mt-2">
                <div class="flex flex-col py-3">
                    <div
                        v-for="option in options"
                        :key="option"
                        class="whitespace-nowrap py-2 px-6 cursor-pointer hover:bg-navy-5"
                        @click.prevent="onSelect(option)"
                    >
                        {{ option.displayOption }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref } from '@vue/reactivity';
    import { computed } from '@vue/runtime-core';

    const emits = defineEmits([ 'update:selected' ]);

    const props = defineProps({
        selected: Object,
        options: Array,
        disabled: Boolean
    });

    const isUserSelect = ref(false);

    const isHasSelected = computed(() => !!props.selected);

    function onSelect(value) {
        isUserSelect.value = true;
        emits('update:selected', value);

        setTimeout(() => {
            isUserSelect.value = false;
        }, 300);
    }
</script>
