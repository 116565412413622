<template>
    <form
        id="pack-details"
        class="h-full flex flex-col"
        autocomplete="off"
        novalidate
        @submit.prevent="submit"
        @keydown="
            editForm.resetValidation('general');
            editForm.resetValidation($event.target.name);
        "
        @keyup.enter.exact="submit"
    >
        <div class="flex flex-col justify-center px-6 py-8 md:py-8">
            <div class="flex justify-center items-center mb-14">
                <p class="text-navy text-[18px] items-center text-center">
                    Box Details
                </p>
            </div>
            <div class="flex relative items-center">
                <div>
                    <p class="text-navy text-[28px] w-[286px]">
                        Add some details
                    </p>
                    <p
                        v-if="editForm.errors.has('general')"
                        class="text-flushing-pink"
                        v-text="editForm.errors.get('general')"
                    />
                    <p
                        v-else
                        class="text-navy text-[14px] w-[243px]"
                    >
                        These will help you to keep track of your box
                    </p>
                </div>

                <img
                    class="absolute -right-[50px] -top-[20px] w-[140px] md:-right-[60px]"
                    :src="require('/static/assets/images/misc/clouds-grey.svg')"
                    alt="Clouds Grey"
                >
            </div>
        </div>
        <BoxDetails
            v-model:name="editForm.name"
            v-model:description="editForm.description"
            :disabled="isLoading"
            :input-tab-index="2"
            :text-area-tab-index="3"
            class="mb-8"
        />
        <div class="flex mt-auto items-center justify-center px-4 md:px-0">
            <div class="flex w-full h-[65px] mb-8 md:w-[360px]">
                <button
                    v-if="!isLoading"
                    class="btn-primary flex items-center justify-center gap-2 h-full"
                    :tabindex="1"
                    data-message="Add Photo Button"
                    type="submit"
                    :disabled="isAddButtonDisabled"
                >
                    <i class="icon icon-camera text-white text-[22px]" />
                    Add Photo
                </button>
                <img
                    v-else
                    class="animate-spin mx-auto my-auto w-[30px] h-[30px]"
                    :src="
                        require('static/assets/images/misc/icons-spinners-i-os--black.svg')
                    "
                    alt="Icon Spinner"
                >
            </div>
        </div>

        <Drawer
            :is-shown="isAddBoxPhotoDrawerShown"
            bg-class="bg-white-noise bg-white"
            :is-back-shown="true"
            :is-close-shown="true"
            @close="SHOW_DRAWER(null)"
            @back="isAddBoxPhotoDrawerShown = false"
        >
            <AddBoxPhoto
                :box="selectedBox"
                @success="isScanGetStartedDrawerShown = true"
            />
        </Drawer>

        <Drawer
            :is-shown="isScanGetStartedDrawerShown"
            bg-class="bg-white-noise bg-white"
            :is-back-shown="true"
            :is-close-shown="true"
            :back-tab-index="3"
            :close-tab-index="4"
            @close="SHOW_DRAWER(null)"
            @back="isScanGetStartedDrawerShown = false"
        >
            <ScanGetStarted />
        </Drawer>
    </form>
</template>

<script setup>
    import { ref, reactive } from '@vue/reactivity';
    import { computed } from '@vue/runtime-core';
    import { formatBoxDetails } from '../../helpers/box.helper';
    import { mapMutations, mapGetters } from 'root-js/store/lib.js';

    import Form from 'root-js/utils/API/Form';
    import BoxDetails from 'root-js/components/common/BoxDetails.vue';
    import AddBoxPhoto from 'root-js/components/your-space/AddBoxPhoto.vue';
    import Drawer from 'root-js/components/common/Drawer.vue';
    import ScanGetStarted from 'root-js/components/your-space/ScanGetStarted.vue';

    const emits = defineEmits(['success', 'unchanged']);

    const props = defineProps({ box: Object });

    const { selectedBox } = mapGetters('boxes');
    const { UPDATE_BOX } = mapMutations('boxes');
    const { SHOW_DRAWER } = mapMutations('drawers');

    const isScanGetStartedDrawerShown = ref(false);
    const isAddBoxPhotoDrawerShown = ref(false);
    const isLoading = ref(false);
    const editForm = reactive(new Form('update-box',
                                       {
                                           id: { value: props.box.id },
                                           name: { value: props.box.title !== 'New Box' ? props.box.title : '' },
                                           description: {
                                               value:
                                                   props.box.description !== 'Imagine the possibilities…'
                                                       ? props.box.description
                                                       : ''
                                           }
                                       },
                                       null,
                                       { success: () => emits('success') }));

    const isAddButtonDisabled = computed(() => editForm.name === 'New Box'
        || editForm.name === ''
        || editForm.description === '');

    async function submit() {
        if (
            editForm.name === props.box.title
            && editForm.description === props.box.description
        ) {
            isAddBoxPhotoDrawerShown.value = true;
            return;
        }

        isLoading.value = true;

        try {
            const { data: boxDetails } = await editForm.submit(true);
            UPDATE_BOX(formatBoxDetails(boxDetails));
            isLoading.value = false;
        } catch (error) {

        // Error intentionally ignored or handled elsewhere
        }

        isLoading.value = false;
        isAddBoxPhotoDrawerShown.value = true;
    }
</script>
