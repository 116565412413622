<template>
    <div
        class="h-full flex flex-col relative"
        autocomplete="off"
        novalidate
    >
        <div
            class="w-full max-w-[360px] h-[208px] rounded-[20px] relative py-6 px-10 mb-2 md:w-[360px]"
            :class="{
                'text-white':
                    CARD_BRAND_COLORS.MUSTARD !== props.paymentMethod.color
            }"
            :style="{ backgroundColor: props.paymentMethod.color }"
        >
            <div
                v-if="props.paymentMethod.is_default"
                class="absolute top-6 right-6 text-[10px] w-[76px] h-[34px] bg-white text-navy rounded-[17px] flex items-center justify-center"
            >
                Primary
            </div>

            <button
                v-else
                class="absolute top-6 right-6 text-[10px] w-[110px] h-[34px] border rounded-[17px] flex items-center justify-center transition duration-75 ease-linear hover:bg-white hover:text-navy hover:border-white"
                :class="{
                    'border-white':
                        CARD_BRAND_COLORS.MUSTARD !== props.paymentMethod.color,
                    'border-navy':
                        CARD_BRAND_COLORS.MUSTARD === props.paymentMethod.color
                }"
                @click="submit"
            >
                Make Primary
            </button>

            <img
                class="-ml-2 mt-6 mb-6"
                :class="props.paymentMethod.cardClass"
                :src="props.paymentMethod.cardImage"
            >

            <p class="text-[20px] tracking-[5.56px] whitespace-nowrap mb-2">
                **** **** **** {{ props.paymentMethod.card_last_four }}
            </p>
            <div class="flex justify-between">
                <p class="text-[12px]" />
                <p class="text-[12px]">
                    Exp {{ props.paymentMethod.exp_month }} /
                    {{ props.paymentMethod.exp_year }}
                </p>
            </div>

            <button
                v-if="!props.paymentMethod.is_default"
                class="absolute -top-1 -right-1 rounded-full text-white bg-flushing-pink w-[18px] h-[18px] flex items-center justify-center hover:bg-bloody-nose"
                @click="$emit('deletePaymentMethod')"
            >
                <i class="icon icon-cross" />
            </button>
        </div>
    </div>
</template>

<script setup>
    import { reactive } from '@vue/reactivity';
    import { mapGetters, mapMutations } from 'root-js/store/lib.js';
    import { CARD_BRAND_COLORS } from 'root-js/constants/space.const.js';

    import Form from 'root-js/utils/API/Form';
    import PaymentMethod from '../../models/PaymentMethod';

    const { paymentMethods } = mapGetters('payments');
    const { UPDATE_PAYMENT_METHOD } = mapMutations('payments');


    const emits = defineEmits(['deletePaymentMethod', 'success']);

    const props = defineProps({ paymentMethod: Object });

    const editForm = reactive(new Form('update-payment-method',
                                       {
                                           id: { value: props.paymentMethod.id },
                                           is_default: { value: 1 }
                                       },
                                       null,
                                       { success: () => emits('success') }));

    async function submit() {
        try {
            const defaultPaymentMethod = paymentMethods.value.find(pm => pm.is_default);

            const updatedCurrentPaymentMethod = new PaymentMethod({
                ...props.paymentMethod,
                is_default: true
            });

            UPDATE_PAYMENT_METHOD(updatedCurrentPaymentMethod);

            if (defaultPaymentMethod && defaultPaymentMethod.id !== props.paymentMethod.id) {
                const updatedDefaultPaymentMethod = new PaymentMethod({
                    ...defaultPaymentMethod,
                    is_default: false
                });

                UPDATE_PAYMENT_METHOD(updatedDefaultPaymentMethod);
            }

            await editForm.submit(true);

        } catch (error) {

        // Error intentionally ignored or handled elsewhere
        }
    }
</script>
