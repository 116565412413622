import { reactive, watch } from 'vue';
import NoScroll from './NoScroll';

import './css/core.css';

/**
 * No Scroll Plugin.
 *
 * This plugin provides functionality to lock scrolling in the DOM.
 *
 * @author      Ben Carey <bdmc@sinemacula.co.uk>
 * @copyright   2022 Sine Macula Limited.
 */
export default {
    install(app) {

        // Make the no scroll object reactive
        const noScroll = reactive(NoScroll);

        // Register the no scroll object
        app.config.globalProperties.$noScroll = noScroll;

        /*
         * Set the locked status depending on the presence of locking
         * identifiers
         */
        watch(() => noScroll.identifiers, value => {
            noScroll.locked = !!value.length;
        }, { deep: true });

        // Lock or unlock the DOM based on the value of the locked boolean
        watch(() => noScroll.locked, value => {

            if (value) {
                document.documentElement.classList.add('no-scroll');
            } else {
                document.documentElement.classList.remove('no-scroll');
            }

        }, { deep: true });

    }
};
