<template>
    <Teleport to="#outside-portal">
        <transition name="fade-scale">
            <div
                v-if="props.isShown"
                class="
					fixed
					top-0
					left-0
					w-screen
					h-screen
					flex
					items-center
					justify-center
					outline-none
					z-[70]
				"
            >
                <div
                    v-click-outside="() => $emit('close')"
                    class="max-w-full px-4"
                    @keyup.esc.exact="$emit('close')"
                >
                    <div class="max-w-full w-[600px] bg-white rounded-[11px]">
                        <div
                            class="
								flex
								justify-center
								relative
								bg-unknown-grey-300
								rounded-t-[11px]
								h-[75px]
								items-center
							"
                        >
                            <button
                                v-if="props.isBackShown"
                                class="absolute left-8 hover:opacity-90"
                                @click="$emit('back')"
                            >
                                <i
                                    class="
										icon icon-arrow-left
										text-navy text-[22px]
										xs:text-[25px]
									"
                                />
                            </button>
                            <div class="flex flex-col">
                                <p class="text-[19px] text-navy">
                                    {{ props.title }}
                                </p>
                            </div>
                            <button
                                v-if="props.isCloseShown"
                                class="absolute right-6 hover:opacity-90"
                                @click="$emit('close')"
                            >
                                <i
                                    class="icon icon-cross text-navy text-[32px] xs:text-[40px]"
                                />
                            </button>
                        </div>

                        <Progressbar
                            v-if="stepPercentage"
                            :value="stepPercentage"
                        />

                        <div
                            class="
								relative
								bg-white
								p-6
								rounded-b-[11px]
								flex
								max-w-full
								w-full
								overflow-hidden
								xs:p-10
							"
                        >
                            <slot />
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </Teleport>
</template>

<script setup>
    import { watch } from 'vue';
    import { BUS_EVENTS } from 'root-js/constants/bus-events.const.js';

    import bus from 'root-js/services/BusService';
    import Progressbar from 'root-js/components/common/Progressbar.vue';

    const props = defineProps({
        isShown: Boolean,
        title: String,
        isCloseShown: Boolean,
        isBackShown: Boolean,
        stepPercentage: [Boolean, Number]
    });

    const emits = defineEmits(['close', 'back']);

    watch(() => props.isShown,
          () => {
              if (props.isShown) {
                  bus.emit(BUS_EVENTS.SHOW_BACKDROP);
                  return;
              }
              bus.emit(BUS_EVENTS.HIDE_BACKDROP);
          });
</script>
