<template>
    <div
        class="
			flex
			w-full
			h-[60px]
			space-x-14
			bg-blue-noise-2
			items-center
			justify-center
		"
    >
        <router-link
            v-for="menu in menus"
            :key="menu.name"
            v-slot="{ href, navigate, isActive }"
            :to="{ name: menu.name }"
            custom
        >
            <a
                :class="{ 'pointer-events-none': isActive }"
                class="
					flex
					justify-center
					items-center
					relative
					text-white text-[18px]
					font-light
					w-[54px]
					h-full
					hover:opacity-90
					group
				"
                :href="href"
                @click="navigate"
            >
                <span
                    v-if="isActive"
                    class="absolute bottom-0 left-0 h-[4px] bg-flushing-pink w-full"
                />
                <i
                    :class="{
                        [menu.icon]: true,
                        'text-white': isActive,
                        'text-light-bite': !isActive
                    }"
                    class="icon text-[24px]"
                />
            </a>
        </router-link>
    </div>
</template>

<script setup>
    import { ref } from 'vue';

    const menus = ref([
        {
            name: 'Your Space',
            icon: 'icon-box-outline'
        },
        {
            name: 'Address Book',
            icon: 'icon-contacts'
        },
        {
            name: 'Settings',
            icon: 'icon-settings'
        }
    ]);
</script>
