import Preloader from './Preloader';

/**
 * Preloader Plugin.
 *
 * This plugin provides functionality to preload image assets into the cache for
 * better user experience.
 *
 * @author      Ben Carey <bdmc@sinemacula.co.uk>
 * @copyright   2022 Sine Macula Limited.
 */
export default {
    install(app) {

        // Provide the preload class to the app instance
        app.config.globalProperties.$preloader = new Preloader;

    }
};
