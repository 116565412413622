<template>
    <div class="text-navy">
        <router-link
            :to="{ name: 'Settings' }"
            class="flex items-center mr-4 hover:opacity-90 lg:pointer-events-none"
        >
            <i class="icon icon-arrow-left text-navy mr-4 lg:hidden" />
            <p class="text-[14px] text-navy">
                Settings / Payment Methods
            </p>
        </router-link>

        <div class="mb-4 relative flex">
            <p class="text-[28px] w-[250px] tracking-[-0.7px] leading-[1.29] mt-8 mr-10">
                Your payment methods
            </p>
            <img
                class=""
                :src="require('/static/assets/images/misc/clouds-grey.svg')"
                alt="Clouds Grey"
            >
        </div>

        <p class="text-[14px] mb-8">
            Manage you cards
        </p>

        <div
            class="bg-gradient-to-r from-navy to-[#051662] text-white rounded-[20px] w-full max-w-[360px] flex p-6 mb-6 md:w-[360px]"
        >
            <div class="mr-2">
                <p class="text-[18px] tracking-[-0.45px] mb-2">
                    Available credit
                </p>
                <p class="text-[12px]">
                    Credits will automatically apply to transactions
                </p>
            </div>
            <div class="relative">
                <p class="text-[40px] relative">
                    <span class="absolute top-2 -left-3 opacity-50 text-[12px]">
                        $</span>
                    {{ readableBalance(user.balance) }}
                </p>
            </div>
        </div>

        <PaymentMethodCard
            v-for="paymentMethod in paymentMethods"
            :key="paymentMethod.id"
            :payment-method="paymentMethod"
            @deletePaymentMethod="onClickDeletePaymentMethod(paymentMethod.id)"
        />

        <div class="flex mt-16 mb-10">
            <button
                class="btn-primary max-w-[360px] h-[65px] md:w-[360px]"
                @click="onClickAddCreatePaymentMethod"
            >
                Add new card
            </button>
        </div>
        <Dialog
            v-model:isShown="isDeleteDialogShown"
            size-class="w-[300px] sm:w-[530px] xs:w-[460px] "
            spacing-class="py-[25px] px-[30px] sm:py-[52px] sm:px-[47px]"
        >
            <div class="flex gap-8 items-center">
                <div class="flex flex-col mb-6 xs:mb-10 w-[300px] max-w-full">
                    <h2 class="text-navy text-[28px] mb-2">
                        Confirm deletion
                    </h2>
                    <p class="text-navy text-[14px]">
                        Are you sure you want to delete this payment method?
                    </p>
                </div>
                <img
                    class="absolute top-8 -right-11 w-[200px] hidden sm:top-8 sm:w-[230px] xs:block"
                    :src="require('/static/assets/images/misc/figures-alerts-payment.png')
                    "
                    alt="Figures Alerts Payment"
                >
            </div>
            <div class="flex gap-2">
                <div class="flex h-[45px] w-[105px] sm:w-[189px] sm:h-[65px] xs:w-[165px] xs:h-[55px] xs:">
                    <button
                        v-if="!isLoading"
                        class="btn-secondary [text-16px]"
                        @click="onClickDeleteConfirm"
                    >
                        Delete
                    </button>
                    <img
                        v-else
                        class="animate-spin mx-auto my-auto w-[30px] h-[30px]"
                        :src="require('static/assets/images/misc/icons-spinners-i-os--black.svg')
                        "
                        alt="Icon Spinner"
                    >
                </div>
                <button
                    class="btn-primary text-[14px] w-[105px] h-[45px] sm:w-[220px] sm:h-[65px] xs:w-[165px] xs:h-[55px] xs:[text-16px]"
                    @click="onClickCancel"
                >
                    Cancel
                </button>
            </div>
        </Dialog>
    </div>
</template>

<script setup>
    import { ref } from '@vue/reactivity';
    import { useMeta } from 'vue-meta';
    import { mapGetters, mapActions, mapMutations } from 'root-js/store/lib.js';
    import { DRAWERS } from 'root-js/constants/drawers.const.js';
    import { onMounted } from '@vue/runtime-core';
    import { readableBalance } from '../../helpers/number.helper';

    import Dialog from 'root-js/components/common/Dialog.vue';
    import PaymentMethodCard from 'root-js/components/common/PaymentMethodCard.vue';
    import { useGtag } from 'vue-gtag-next';

    useMeta({ title: 'Space / Payment Methods' });

    const { paymentMethods } = mapGetters('payments');
    const { user } = mapGetters('user');
    const { SHOW_DRAWER } = mapMutations('drawers');
    const { DELETE_PAYMENT_METHOD, GET_USER_PAYMENT_METHODS }
        = mapActions('payments');

    const isDeleteDialogShown = ref(false);
    const isLoading = ref(false);
    const paymentMethodIdForDelete = ref('');
    const { event } = useGtag();

    onMounted(() => {
        GET_USER_PAYMENT_METHODS();
    });

    function onClickAddCreatePaymentMethod() {
        SHOW_DRAWER(DRAWERS.ADD_PAYMENT_METHOD);

        // Send GA event for clicking "Add new card" button
        event('add_payment_info');
    }

    function onClickDeletePaymentMethod(id) {
        paymentMethodIdForDelete.value = id;
        isDeleteDialogShown.value = true;
    }
    function onClickCancel() {
        isDeleteDialogShown.value = false;
    }

    async function onClickDeleteConfirm() {
        isLoading.value = true;
        try {
            await DELETE_PAYMENT_METHOD(paymentMethodIdForDelete.value);
            isDeleteDialogShown.value = false;
        } catch (error) {

            console.log(error);
        }

        isLoading.value = false;
    }
</script>
