<template>
    <div
        id="product-list"
        class="h-full flex flex-col relative"
    >
        <div class="flex flex-col items-center justify-center p-8">
            <div>
                <p class="text-white text-[18px] text-center mb-8">
                    Store
                </p>
            </div>
            <button
                class="absolute right-6 top-8 z-10 text-white text-[20px] hover:opacity-90"
                @click="isYourCartDrawerShown = true"
            />

            <div
                v-for="product in products"
                :key="product.size"
                class="bg-unknown-grey-700 rounded-[20px] mb-4 w-full h-[414px] p-[30px] z-[70] flex flex-col relative xs:w-[340px]"
            >
                <div class="flex justify-between items-center mb-2">
                    <div class="flex items-center gap-2">
                        <i class="icon icon-measure text-navy text-[24px]" />
                        <p class="text-navy text-[12px]">
                            {{ mmToInches(product.properties[0].value) }} x
                            {{ mmToInches(product.properties[1].value) }} x
                            {{ mmToInches(product.properties[2].value) }} inch
                        </p>
                        <i class="icon icon-weight text-navy text-[14px]" />
                        <p class="text-navy text-[12px]">
                            50 lbs max
                        </p>
                    </div>
                    <div>
                        <i class="icon icon-ar text-navy text-[24px]" />
                    </div>
                </div>

                <h3 class="text-[24px] text-navy">
                    {{ product.name }}
                </h3>
                <div>
                    <img
                        class="absolute top-0 right-0"
                        :src="product.images.media.original"
                    >
                </div>
                <div class="flex items-center justify-between mt-auto">
                    <button
                        class="btn-secondary max-w-[220px] h-[50px] mr-2 xs:mr-0"
                        @click="onProductDetailsClicked(product)"
                    >
                        View product
                    </button>
                    <button
                        class="icon icon-add-to-bag text-white text-[24px] bg-flushy-pink-gradient rounded-full p-[13px] shadow-ambient hover:bg-bloody-nose-gradient"
                        @click="onClickAddToCart(product)"
                    />
                </div>
                <div
                    class="flex flex-col bg-flushy-pink-gradient items-center justify-center rounded-full shadow-price w-[106px] h-[106px] absolute top-[80px] right-[35px] mt-4"
                >
                    <div class="items-center mt-2">
                        <p
                            class="text-white text-[36px] leading-[30px] relative text-center ml-[6px]"
                        >
                            <span
                                class="absolute top-[-10px] -left-3 opacity-60 text-[18px]"
                            >
                                $
                            </span>
                            {{
                                readableDecimalNumber(product?.plans[0]?.price)
                            }}
                        </p>
                        <p
                            class="text-white text-[14px] text-center opacity-60"
                        >
                            a month
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <img
            class="w-[111px] h-[66px] absolute bottom-[150px] right-[115px]"
            :src="require('static/assets/images/misc/clouds-pink.png')"
        >
        <img
            class="w-[199px] h-[118px] absolute bottom-[430px] -left-[55px]"
            :src="require('static/assets/images/misc/clouds-pink.png')"
            alt="Clouds Pink"
        >
        <div
            class="flex items-center justify-center mt-auto text-white p-[21px]"
        >
            <div>
                <button
                    class="text-[12px] hover:opacity-90"
                    @click="$emit('close')"
                >
                    I’ll do this later. <span class="font-medium"> Skip</span>
                </button>
            </div>
        </div>
        <Drawer
            :is-shown="isProductDetailsDrawerShown"
            bg-class="bg-white-noise bg-white"
            :is-back-shown="true"
            :is-close-shown="false"
            :back-tab-index="3"
            @back="isProductDetailsDrawerShown = false"
        >
            <ProductDetails :product="selectedProduct" />
        </Drawer>
        <Drawer
            :is-shown="isYourCartDrawerShown"
            bg-class="bg-white-noise bg-white"
            :is-back-shown="true"
            :is-close-shown="true"
            @close="SHOW_DRAWER(null)"
            @back="isYourCartDrawerShown = false"
        >
            <YourCart />
        </Drawer>
    </div>
</template>

<script setup>
    import { ref } from '@vue/reactivity';
    import { onMounted } from '@vue/runtime-core';
    import { mapGetters, mapMutations, mapActions } from 'root-js/store/lib.js';
    import { readableDecimalNumber } from 'root-js/helpers/number.helper';


    import Drawer from 'root-js/components/common/Drawer.vue';
    import YourCart from 'root-js/components/your-space/YourCart.vue';
    import ProductDetails from 'root-js/components/your-space/ProductDetails.vue';
    import { mmToInches } from 'root-js/helpers/box.helper';
    import { useGtag } from 'vue-gtag-next';

    const props = defineProps({ product: Object });

    const { cartBoxesById } = mapGetters('store');
    const { selectedBox } = mapGetters('boxes');
    const { products } = mapGetters('store');
    const { SHOW_DRAWER } = mapMutations('drawers');
    const { SET_CART_PRODUCT } = mapMutations('store');
    const { GET_STORE_SHIPPING } = mapActions('boxes');

    const isYourCartDrawerShown = ref(false);
    const isProductDetailsDrawerShown = ref(false);
    const selectedProduct = ref(null);
    const { event } = useGtag();

    onMounted(() => {
        GET_STORE_SHIPPING({ shipment_type: 'order' });
    });

    function onProductDetailsClicked(product) {
        selectedProduct.value = product;
        isProductDetailsDrawerShown.value = true;

        // Send GA event for viewing product details
        event('view_item', {
            currency: 'USD',
            value: readableDecimalNumber(products.value.reduce((total, product) => total + product?.plans[0]?.price, 0)), // Sum of all product prices
            items: [ {
                item_id: product.id,
                item_name: product.name,
                price: readableDecimalNumber(product.plans[0].price)
            } ]
        });
    }

    function onClickAddToCart(product) {
        const quantity = cartBoxesById.value[product.id]
            ? cartBoxesById.value[product.id].quantity + 1
            : 1;

        SET_CART_PRODUCT({
            ...product,
            quantity
        });

        isYourCartDrawerShown.value = true;

        // Send GA event for adding product to cart
        event('add_to_cart', {
            currency: 'USD',
            value: readableDecimalNumber(product.plans[0].price * quantity),
            items: [ {
                item_id: product.id,
                item_name: product.name,
                price: readableDecimalNumber(product.plans[0].price),
                quantity
            } ]
        });
    }
</script>
