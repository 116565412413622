import { reactive, watch } from 'vue';
import usePreloader from './usePreloader';
import bus from 'root-js/services/BusService';

/**
 * Preload app composable.
 *
 * Provide the app preloader to a component.
 *
 * @author      Ben Carey <bdmc@sinemacula.co.uk>
 * @copyright   2022 Sine Macula Limited.
 */
export default () => {

    const properties = reactive({
        preloading: false,
        initiate: false,
        loaded: false,
        images: [],
        progress: 0,
        progressBarDuration: 0
    });

    const preloader = usePreloader();

    // Register the preload listeners
    bus.on('_preload.initiate', () => properties.initiate = true);
    bus.on('_preload.unloaded', () => properties.loaded = false);
    bus.on('_preload.images', images => {
        properties.images = [ ...new Set([...properties.images, ...images]) ];
    });

    // Preload all images for the active page
    const preload = () => {

        // Prevent the preload from being called again
        properties.preloading = true;

        // Fire the preloading initiated event
        bus.emit('_preload.initiated');

        // Scrape all images from the DOM
        properties.images = properties.images.concat(Array.from(document.querySelectorAll('img')));

        // Reset the progress bar duration when no images are supplied
        properties.progressBarDuration = properties.images.length ? properties.progressBarDuration : 0;

        // Preload the images
        preloader.run(properties.images, {
            progress: progress => properties.progress = progress,
            complete: () => {

                // Wait until after any progress animation  has completed
                setTimeout(() => {

                    // Fire the preloading completed event
                    bus.emit('_preload.completed');

                    // Set the preload defaults
                    ({
                        ...properties, progress: 100,
                        initiate: false,
                        loaded: true,
                        preloading: false,
                        images: []
                    });

                }, properties.progressBarDuration);

            }
        });
    };

    // Watch for changes to the preload properties
    watch(() => properties, () => {

        if (properties.loaded === false && properties.initiate === true && properties.preloading === false) {
            preload();
        }

    }, { deep: true });

    return {
        properties,
        preload,
        preloader
    };

};
