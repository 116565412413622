export const DEFAULT_ADDRESS_LABELS = [
    '🏠 Home',
    '🌴 Vacation',
    '🧑‍💻 Work',
    '👥 Friend'
];

export const US_STATES = [
    {
        displayText: 'Alabama',
        value: 'AL'
    },
    {
        displayText: 'Alaska',
        value: 'AK'
    },
    {
        displayText: 'Arizona',
        value: 'AZ'
    },
    {
        displayText: 'Arkansas',
        value: 'AR'
    },
    {
        displayText: 'American Samoa',
        value: 'AS'
    },
    {
        displayText: 'California',
        value: 'CA'
    },
    {
        displayText: 'Colorado',
        value: 'CO'
    },
    {
        displayText: 'Connecticut',
        value: 'CT'
    },
    {
        displayText: 'Delaware',
        value: 'DE'
    },
    {
        displayText: 'District of Columbia',
        value: 'DC'
    },
    {
        displayText: 'Florida',
        value: 'FL'
    },
    {
        displayText: 'Georgia',
        value: 'GA'
    },
    {
        displayText: 'Guam',
        value: 'GU'
    },
    {
        displayText: 'Hawaii',
        value: 'HI'
    },
    {
        displayText: 'Idaho',
        value: 'ID'
    },
    {
        displayText: 'Illinois',
        value: 'IL'
    },
    {
        displayText: 'Indiana',
        value: 'IN'
    },
    {
        displayText: 'Iowa',
        value: 'IA'
    },
    {
        displayText: 'Kansas',
        value: 'KS'
    },
    {
        displayText: 'Kentucky',
        value: 'KY'
    },
    {
        displayText: 'Louisiana',
        value: 'LA'
    },
    {
        displayText: 'Maine',
        value: 'ME'
    },
    {
        displayText: 'Maryland',
        value: 'MD'
    },
    {
        displayText: 'Massachusetts',
        value: 'MA'
    },
    {
        displayText: 'Michigan',
        value: 'MI'
    },
    {
        displayText: 'Minnesota',
        value: 'MN'
    },
    {
        displayText: 'Mississippi',
        value: 'MS'
    },
    {
        displayText: 'Missouri',
        value: 'MO'
    },
    {
        displayText: 'Montana',
        value: 'MT'
    },
    {
        displayText: 'Nebraska',
        value: 'NE'
    },
    {
        displayText: 'Nevada',
        value: 'NV'
    },
    {
        displayText: 'New Hampshire',
        value: 'NH'
    },
    {
        displayText: 'New Jersey',
        value: 'NJ'
    },
    {
        displayText: 'New Mexico',
        value: 'NM'
    },
    {
        displayText: 'New York',
        value: 'NY'
    },
    {
        displayText: 'North Carolina',
        value: 'NC'
    },
    {
        displayText: 'North Dakota',
        value: 'ND'
    },
    {
        displayText: 'Northern Mariana Islands',
        value: 'CM'
    },
    {
        displayText: 'Ohio',
        value: 'OH'
    },
    {
        displayText: 'Oklahoma',
        value: 'OK'
    },
    {
        displayText: 'Oregon',
        value: 'OR'
    },
    {
        displayText: 'Pennsylvania',
        value: 'PA'
    },
    {
        displayText: 'Puerto Rico',
        value: 'PR'
    },
    {
        displayText: 'Rhode Island',
        value: 'RI'
    },
    {
        displayText: 'South Carolina',
        value: 'SC'
    },
    {
        displayText: 'South Dakota',
        value: 'SD'
    },
    {
        displayText: 'Tennessee',
        value: 'TN'
    },
    {
        displayText: 'Texas',
        value: 'TX'
    },
    {
        displayText: 'Trust Territories',
        value: 'TT'
    },
    {
        displayText: 'Utah',
        value: 'UT'
    },
    {
        displayText: 'Vermont',
        value: 'VT'
    },
    {
        displayText: 'Virginia',
        value: 'VA'
    },
    {
        displayText: 'Virgin Islands',
        value: 'VI'
    },
    {
        displayText: 'Washington',
        value: 'WA'
    },
    {
        displayText: 'West Virginia',
        value: 'WV'
    },
    {
        displayText: 'Wisconsin',
        value: 'WI'
    },
    {
        displayText: 'Wyoming',
        value: 'WY'
    }
];
