import { loadStripe } from '@stripe/stripe-js';
import tailwindConfig from '../../../tailwind.config';

const style = {
    base: {
        '::placeholder': {
            color: tailwindConfig.theme.extend.colors['bruised-ego'],
            fontSize: '14px',
            fontStyle: 'normal'
        }
    }
};

class StripeService {
    constructor() {
        this.stripe = null;
        this.elements = null;
        this.publishableKey = process.env.STRIPE_PK_KEY;
        this.cardNumberElement = null;
        this.cardCvcElement = null;
        this.cardExpiryElement = null;
        this.paymentElement = null;
    }

    async load() {
        this.stripe = await loadStripe(this.publishableKey);
    }

    async initElements(clientSecret) {
        if (!this.stripe) {
            await this.load();
        }

        this.elements = this.stripe.elements({ clientSecret });

        return this.elements;
    }

    async createCardElements(clientSecret) {
        const elements = await this.initElements(clientSecret);

        this.paymentElement = elements.create('payment');

        this.cardNumberElement = elements.create('cardNumber', {
            classes: { base: ' pl-[10px] p-[16px] placeholder:text-bruised-ego text-[14px] text-navy outline-none w-full shadow-input focus:shadow-md ' },
            placeholder: 'Card Number',
            style
        });

        this.cardCvcElement = elements.create('cardCvc', {
            classes: { base: 'bg-white p-[16px] pl-[26px] rounded-[20px] w-full shadow-input focus:shadow-md md:w-[172px]' },
            placeholder: 'CVC',
            style
        });

        this.cardExpiryElement = elements.create('cardExpiry', {
            classes: { base: 'bg-white p-[16px] pl-[26px] rounded-[20px] w-full shadow-input focus:shadow-md md:w-[172px]' },
            placeholder: 'Expiry (MM/YY)',
            style
        });
    }

    async confirmSetup() {
        return this.stripe.confirmSetup({
            elements: this.elements,
            redirect: 'if_required'
        });
    }
}

export const stripe = new StripeService;
