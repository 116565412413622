<template>
    <svg
        width="50"
        height="50"
        viewBox="0 0 50 50"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g
            stroke="currentColor"
            stroke-width="1.44"
            fill="none"
            fill-rule="evenodd"
        >
            <path
                d="M12.578 44.128S2.354 39.476 9.59 31.776c3.484-3.708 6.57-4.17 7.235-14.437.468-7.219 4.404-13.795 8.494-13.314M38.06 44.128s10.224-4.652 2.989-12.352c-3.485-3.708-6.57-4.17-7.236-14.437-.468-7.219-4.404-13.795-8.494-13.314M16.596 40.638s2.517 0 3.932 1.616C21.944 43.869 24.146 45 26.191 45"
            />
            <path
                d="M35.787 40.638s-2.517 0-3.932 1.616C30.439 43.869 28.236 45 26.19 45"
            />
        </g>
    </svg>
</template>
