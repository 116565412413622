<template>
    <div
        id="terms-and-condition"
        class="h-full flex flex-col"
    >
        <div class="flex flex-col justify-center px-6 py-8 md:py-8">
            <div class="flex justify-center items-center mb-14">
                <p class="text-navy text-[18px] items-center text-center">
                    Terms
                </p>
            </div>
            <div class="flex relative items-center">
                <div>
                    <p class="text-navy text-[28px] w-[286px]">
                        We need you to agree to this
                    </p>
                    <p class="text-navy text-[14px]">
                        The following items are prohibited from all boxes
                    </p>
                </div>

                <img
                    class="absolute -right-[50px] -top-[20px] w-[140px] md:-right-[60px]"
                    :src="require('/static/assets/images/misc/clouds-grey.svg')"
                    alt="Clouds Grey"
                >
            </div>
        </div>
        <div class="px-6 mb-16">
            <div class="flex justify-between mb-6">
                <div class="flex flex-col items-center justify-center">
                    <div class="relative mb-2">
                        <ProbihitedIcon />
                        <DrugsIcon
                            class="
								text-navy
								absolute
								top-1/2
								left-1/2
								transform
								-translate-x-1/2 -translate-y-1/2
							"
                        />
                    </div>
                    <p class="text-[12px] text-navy">
                        NO drugs
                    </p>
                </div>
                <div class="flex flex-col items-center justify-center">
                    <div class="relative mb-2">
                        <ProbihitedIcon />
                        <FirearmsIcon
                            class="
								text-navy
								absolute
								top-1/2
								left-1/2
								transform
								-translate-x-1/2 -translate-y-1/2
							"
                        />
                    </div>
                    <p class="text-[12px] text-navy">
                        NO firearms
                    </p>
                </div>
                <div class="flex flex-col items-center justify-center">
                    <div class="relative mb-2">
                        <ProbihitedIcon />
                        <ExplosivesIcon
                            class="
								text-navy
								absolute
								top-1/2
								left-1/2
								transform
								-translate-x-1/2 -translate-y-1/2
								scale-x-[-1]
							"
                        />
                    </div>
                    <p class="text-[12px] text-navy">
                        NO explosives
                    </p>
                </div>
            </div>
            <div class="flex justify-between mb-6">
                <div class="flex flex-col items-center justify-center">
                    <div class="relative mb-2">
                        <ProbihitedIcon />
                        <LivestockIcon
                            class="
								text-navy
								absolute
								top-1/2
								left-1/2
								transform
								-translate-x-1/2 -translate-y-1/2
							"
                        />
                    </div>
                    <p class="text-[12px] text-navy">
                        NO livestock
                    </p>
                </div>
                <div class="flex flex-col items-center justify-center">
                    <div class="relative mb-2">
                        <ProbihitedIcon />
                        <OrgansIcon
                            class="
								text-navy
								absolute
								top-1/2
								left-1/2
								transform
								-translate-x-1/2 -translate-y-1/2
							"
                        />
                    </div>
                    <p class="text-[12px] text-navy">
                        NO organs
                    </p>
                </div>
                <div class="flex flex-col items-center justify-center">
                    <div class="relative mb-2">
                        <ProbihitedIcon />
                        <FoodIcon
                            class="
								text-navy
								absolute
								top-1/2
								left-1/2
								transform
								-translate-x-1/2 -translate-y-1/2
							"
                        />
                    </div>
                    <p class="text-[12px] text-navy">
                        NO food
                    </p>
                </div>
            </div>
            <div class="flex justify-between mb-6">
                <div class="flex flex-col items-center justify-center">
                    <div class="relative mb-2">
                        <ProbihitedIcon />
                        <PlantsIcon
                            class="
								text-navy
								absolute
								top-1/2
								left-1/2
								transform
								-translate-x-1/2 -translate-y-1/2
							"
                        />
                    </div>
                    <p class="text-[12px] text-navy">
                        NO plants
                    </p>
                </div>
                <div class="flex flex-col items-center justify-center">
                    <div class="relative mb-2">
                        <ProbihitedIcon />
                        <FlammablesIcon
                            class="
								text-navy
								absolute
								top-1/2
								left-1/2
								transform
								-translate-x-1/2 -translate-y-1/2
							"
                        />
                    </div>
                    <p class="text-[12px] text-navy">
                        NO flammables
                    </p>
                </div>
                <div class="flex flex-col items-center justify-center">
                    <div class="relative mb-2">
                        <ProbihitedIcon />
                        <BatteriesIcon
                            class="
								text-navy
								absolute
								top-1/2
								left-1/2
								transform
								-translate-x-1/2 -translate-y-1/2
							"
                        />
                    </div>
                    <p class="text-[12px] text-navy">
                        NO batteries
                    </p>
                </div>
            </div>
            <div class="flex justify-between mb-6">
                <div class="flex flex-col items-center justify-center">
                    <div class="relative mb-2">
                        <ProbihitedIcon />
                        <OdorsIcon
                            class="
								text-navy
								absolute
								top-1/2
								left-1/2
								transform
								-translate-x-1/2 -translate-y-1/2
							"
                        />
                    </div>
                    <p class="text-[12px] text-navy">
                        NO odors
                    </p>
                </div>
                <div class="flex flex-col items-center justify-center">
                    <div class="relative mb-2">
                        <ProbihitedIcon />
                        <CombustiblesIcon
                            class="
								text-navy
								absolute
								top-1/2
								left-1/2
								transform
								-translate-x-1/2 -translate-y-1/2
							"
                        />
                    </div>
                    <p class="text-[12px] text-navy">
                        NO combustibles
                    </p>
                </div>
                <div class="flex flex-col items-center justify-center">
                    <div class="relative mb-2">
                        <ProbihitedIcon />
                        <LiquidsIcon
                            class="
								text-navy
								absolute
								top-1/2
								left-1/2
								transform
								-translate-x-1/2 -translate-y-1/2
							"
                        />
                    </div>
                    <p class="text-[12px] text-navy">
                        NO liquids
                    </p>
                </div>
            </div>
        </div>

        <div class="flex mt-auto items-center justify-center px-4 md:px-0">
            <button
                class="btn-primary h-[65px] mb-8 md:w-[360px]"
                :tabindex="1"
                data-message="I Agree Button"
                @click="$emit('agree')"
            >
                I agree
            </button>
        </div>
    </div>
</template>

<script setup>
    import { mapMutations } from 'root-js/store/lib.js';

    import ProbihitedIcon from 'root-js/components/svgs/ProbihitedIcon.vue';
    import DrugsIcon from 'root-js/components/svgs/DrugsIcon.vue';
    import FirearmsIcon from 'root-js/components/svgs/FirearmsIcon.vue';
    import ExplosivesIcon from 'root-js/components/svgs/ExplosivesIcon.vue';
    import LivestockIcon from 'root-js/components/svgs/LivestockIcon.vue';
    import OrgansIcon from 'root-js/components/svgs/OrgansIcon.vue';
    import FoodIcon from 'root-js/components/svgs/FoodIcon.vue';
    import PlantsIcon from 'root-js/components/svgs/PlantsIcon.vue';
    import FlammablesIcon from 'root-js/components/svgs/FlammablesIcon.vue';
    import BatteriesIcon from 'root-js/components/svgs/BatteriesIcon.vue';
    import OdorsIcon from 'root-js/components/svgs/OdorsIcon.vue';
    import CombustiblesIcon from 'root-js/components/svgs/CombustiblesIcon.vue';
    import LiquidsIcon from 'root-js/components/svgs/LiquidsIcon.vue';

    import Drawer from 'root-js/components/common/Drawer.vue';

    const { SHOW_DRAWER } = mapMutations('drawers');
</script>
