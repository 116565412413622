<template>
    <div class="relative group">
        <div
            class="
				py-1
				px-3
				border-navy-20 border-[1.5px]
				rounded-full
				flex
				items-center
				cursor-pointer
				transition
				duration-75
				ease-linear
				group-hover:border-navy-60
			"
        >
            <div class="mr-3">
                <i
                    v-if="!user?.image?.original"
                    class="
						icon icon-profile-circle-solid
						text-[20px] text-lighter-grey
						transition
						duration-75
						ease-linear
						group-hover:text-navy-60
					"
                />
                <img
                    v-else
                    :src="user?.image?.original"
                    class="w-[20px] h-[20px] rounded-full"
                >
            </div>

            <span
                class="text-navy text-[14px] mr-2"
                v-text="user?.full_name"
            />

            <i
                class="
					icon icon-chevron-down
					text-[6px] text-unknown-grey
					transition
					duration-75
					ease-linear
					group-hover:text-navy
				"
            />
        </div>

        <div
            class="
				absolute
				top-[32px]
				right-0
				hidden
				shadow-ambient
				group-hover:block
			"
        >
            <div
                class="border border-unknown-grey bg-white text-navy rounded-[6px] mt-2"
            >
                <div class="flex flex-col py-3 border-b border-unknown-grey">
                    <router-link
                        v-for="menu in menus"
                        :key="menu.name"
                        v-slot="{ href, navigate }"
                        :to="{ name: menu.name }"
                        custom
                    >
                        <a
                            class="whitespace-nowrap py-2 px-6 hover:opacity-70"
                            :href="href"
                            @click="navigate"
                        >
                            {{ menu.name }}
                        </a>
                    </router-link>
                </div>
                <button
                    class="
						flex
						items-center
						px-6
						py-4
						text-navy-60
						transition
						duration-75
						ease-linear
						hover:text-navy
					"
                    @click="signOut"
                >
                    <i class="icon icon-leave mr-2" />
                    Sign out
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref } from '@vue/reactivity';
    import { mapGetters, mapActions } from 'root-js/store/lib.js';
    import router from 'root-js/routes.js';
    import {} from 'vuex';

    const { user } = mapGetters('user');
    const { UNSET_AUTH } = mapActions('auth');
    const { GET_USER } = mapActions('user');

    const menus = ref([
        { name: 'Profile' },
        { name: 'Payment Methods' }
    ]);

    async function signOut() {
        await UNSET_AUTH();
        router.push({ name: 'Welcome' });
    }
</script>
