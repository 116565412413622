<template>
    <div class="w-full">
        <h2 class="text-[22px] xs:text-[28px] text-navy mb-2">
            Select your unit
        </h2>
        <p
            v-if="selectedUnit"
            class="text-[14px] text-navy mb-6"
        >
            <span class="font-medium">{{ selectedUnit.display }}</span>
            {{ selectedUnit.value }}
        </p>
        <p
            v-else
            class="text-[14px] text-navy mb-6"
        >
            Please select your unit
        </p>
        <div class="items-center gap-4 mb-8 xs:flex">
            <UnitDropDown
                v-model:selected="selectedUnit"
                :options="units"
            />
            <button
                class="
					hidden
					w-[55px]
					h-[55px]
					rounded-full
					bg-flushy-pink-gradient
					shadow-ambient
					transition
					duration-75
					ease-linear
					p-[10px]
					hover:bg-bloody-nose-gradient
					disabled:bg-flushy-pink-gradient disabled:opacity-50
					xs:block
				"
                :disabled="!selectedUnit"
                @click="$emit('confirm')"
            >
                <i class="icon icon-arrow-right text-white text-[27px]" />
            </button>

            <button
                class="btn-primary max-w-full w-[300px] h-[55px] mb-2 xs:hidden"
                :disabled="!selectedUnit"
                @click="$emit('confirm')"
            >
                Continue
            </button>
        </div>

        <div class="flex items-center gap-2">
            <i class="icon icon-help-circle-outline text-navy text-[22px]" />

            <p class="text-navy text-[12px]">
                Unit not listed?
                <span>
                    <button class="font-medium text-flushing-pink hover:text-bloody-nose">
                        Contact support
                    </button>
                </span>
            </p>
        </div>
    </div>
</template>

<script setup>
    import { ref } from '@vue/reactivity';

    import UnitDropDown from 'root-js/components/core/UnitDropDown.vue';

    const selectedUnit = ref(null);
    const units = ref([
        {
            display: '79 Bultman Road',
            value: '2823 E. MLK St., Austin, TX78702'
        },
        {
            display: '05143 Service Hill',
            value: '2823 E. MLK St., Austin, TX78702'
        },
        {
            display: '85001 School Drive',
            value: '2823 E. MLK St., Austin, TX78702'
        },
        {
            display: '1795 Aberg Parkway',
            value: '2823 E. MLK St., Austin, TX78702'
        }
    ]);
</script>
