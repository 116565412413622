const namespaced = true;

const state = { orders: []};

const getters = { orders: state => state.orders };

const mutations = {
    SET_ORDERS: (state, payload) => {
        state.order = payload;
    }
};

const actions = {
    GET_ORDERS: async context => {
        const response = await window.api.call('get-order');
        context.commit('SET_ORDERS', response.data.data);
        return response.data.data;
    }
};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
};
