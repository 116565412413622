<template>
    <div id="box-intro">
        <div class="bg-dutty-grey w-full h-[280px] relative group">
            <AppBoxImage
                class="w-full h-full object-cover"
                :box="props.box"
            />
            <div
                v-if="props.isEditable"
                class="items-center justify-center hidden space-x-4 bg-dusk-stalk absolute top-0 w-full h-full cursor-pointer transition duration-75 ease-linear hover:opacity-90 group-hover:flex"
            >
                <div class="flex flex-col items-center justify-center">
                    <button
                        class="h-[36px] w-[36px] bg-navy rounded-full hover:bg-opacity-90"
                        @click="isChangeImageDrawerShown = true"
                    >
                        <i
                            class="icon icon icon-camera text-white text-[16px]"
                        />
                    </button>
                    <p class="text-white text-[14px]">
                        Change Image
                    </p>
                </div>
            </div>
        </div>
        <div class="px-[21px] pt-[21px]">
            <div class="flex items-center justify-between mb-2">
                <h3 class="text-navy text-[22px]">
                    {{ props.box.title }}
                </h3>
                <button
                    v-if="props.isEditable"
                    class="icon icon-edit text-navy text-[19px]"
                    :tabindex="props.editTabIndex"
                    data-message="Edit Button"
                    @click="isEditDetailsDrawerShown = true"
                />
            </div>
            <div class="flex gap-2 mb-2 items-center">
                <i class="icon icon-calendar text-navy text-[14px]" />
                <p class="text-[14px] text-navy">
                    {{ props.box.dateCreated }}
                </p>
            </div>
        </div>
        <Drawer
            :is-shown="isChangeImageDrawerShown"
            bg-class="bg-white-noise bg-white"
            :is-close-shown="true"
            :is-back-shown="true"
            @close="SHOW_DRAWER(null)"
            @back="isChangeImageDrawerShown = false"
        >
            <AddBoxPhoto
                submit-text="Done"
                :box="selectedBox"
                @close="isChangeImageDrawerShown = false"
                @success="isChangeImageDrawerShown = false"
            />
        </Drawer>

        <Drawer
            :is-shown="isEditDetailsDrawerShown"
            bg-class="bg-white-noise bg-white"
            :is-close-shown="true"
            :is-back-shown="true"
            @close="SHOW_DRAWER(null)"
            @back="isEditDetailsDrawerShown = false"
        >
            <UpdateBoxDetails
                :box="selectedBox"
                @close="SHOW_DRAWER(null)"
                @success="isEditDetailsDrawerShown = false"
            />
        </Drawer>
    </div>
</template>

<script setup>
    import AppBoxImage from 'root-js/components/core/AppBoxImage';
    import { ref } from '@vue/reactivity';
    import { mapMutations, mapGetters } from 'root-js/store/lib.js';

    import Drawer from 'root-js/components/common/Drawer.vue';
    import AddBoxPhoto from 'root-js/components/your-space/AddBoxPhoto.vue';
    import UpdateBoxDetails from 'root-js/components/your-space/UpdateBoxDetails.vue';

    const props = defineProps({
        box: Object,
        address: String,
        isEditable: Boolean,
        editTabIndex: Number
    });

    const { SHOW_DRAWER } = mapMutations('drawers');
    const { selectedBox } = mapGetters('boxes');

    const isChangeImageDrawerShown = ref(false);
    const isEditDetailsDrawerShown = ref(false);
</script>
