<template>
    <div
        id="order-placed"
        class="h-full flex flex-col"
    >
        <div class="flex flex-col justify-center px-6 py-8 md:py-8">
            <div class="flex justify-center items-center mb-14" />
            <div class="flex relative items-center">
                <div>
                    <p class="text-white text-[48px] w-[286px]">
                        Done
                    </p>
                    <p class="text-white text-[18px] w-[310px]">
                        Your order has been placed and will be dispatched shortly
                    </p>
                </div>

                <img
                    class="absolute -right-[50px] -top-[20px] w-[140px] md:-right-[60px]"
                    :src="require('/static/assets/images/misc/clouds-pink.png')"
                    alt="Clouds Pink"
                >
                <img
                    class="
						absolute
						w-[111px]
						left-[170px]
						top-[270px]
						md:left-[192px] md:top-[280px]
					"
                    :src="require('/static/assets/images/misc/clouds-pink.png')"
                    alt="Clouds Pink"
                >
                <img
                    class="absolute w-[173px] -left-[45px] top-[170px]"
                    :src="require('/static/assets/images/misc/clouds-pink.png')"
                    alt=""
                >
            </div>
        </div>
        <div class="flex flex-col mt-auto items-center justify-center px-8 mb-4">
            <img
                class="relative w-[700px] max-w-none left-[170px] mt-52 mb-8 md:mt-0"
                :src="require('/static/assets/images/misc/figures-boxes-checkout.png')"
                alt="Figures Boxes Checkout"
            >
        </div>

        <div class="flex items-center justify-center px-4 md:px-0">
            <button
                class="btn-primary h-[65px] mb-8 md:w-[360px]"
                @click="onClickOrderPlacedClose"
            >
                Go to Your Space
            </button>
        </div>
    </div>
</template>

<script setup>
    import { mapMutations } from 'root-js/store/lib.js';
    import { useGtag } from 'vue-gtag-next';

    const { RESET_CART_PRODUCT } = mapMutations('store');
    const { SHOW_DRAWER } = mapMutations('drawers');
    const { event } = useGtag();

    function onClickOrderPlacedClose() {
        event('order_complete');
        RESET_CART_PRODUCT();
        SHOW_DRAWER(null);
    }
</script>
