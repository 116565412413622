/**
 * User Model.
 *
 * @author      Ben Carey <bdmc@sinemacula.co.uk>
 * @copyright   2022 Sine Macula Limited.
 */
export default class User {

    /**
     * Create a new User instance.
     *
     * @param {object}  data
     */
    constructor(data) {
        Object.assign(this, data);

        // Define the required fields for the model
        this._required = [ 'phone' ];
    }

    get originalImage() {
        return this.image.original;
    }

    /**
     * Determine whether the model is valid.
     *
     * @returns {boolean}
     */
    isValidModel() {
        return this._required.every(key => Object.keys(this).includes(key));
    }
}
