<template>
    <div class="flex">
        <div
            class="bg-grey-star bg-cover w-full h-screen z-10 hidden md:block"
        />
        <div
            class="
				flex flex-col flex-shrink-0
				relative
				bg-blue-noise-2
				overflow-y-auto
				w-full
				h-screen
				shadow-view
				p-6
				md:w-[380px]
				lg:w-[400px]
			"
        >
            <img
                class="w-[150px] absolute right-[30px] top-[90px] opacity-70"
                :src="require('/static/assets/images/misc/clouds-pink.png')"
                alt=""
            >
            <img
                class="
					w-[110px]
					absolute
					scale-x-[-1]
					top-[150px]
					left-[-20px]
					opacity-50
				"
                :src="require('/static/assets/images/misc/clouds-pink.png')"
                alt=""
            >
            <div class="flex justify-end mb-44">
                <button
                    class="
						bg-transparent
						rounded-full
						text-white
						font-medium
						border border-white
						text-[13.3px]
						w-[120px]
						h-[30px]
						transition
						duration-75
						ease-linear
						hover:bg-white hover:text-navy
						mb-2
					"
                >
                    Learn more
                </button>
            </div>
            <div class="flex flex-col items-center justify-center">
                <img
                    class="w-[163px] h-[50px] mb-14"
                    :src="require('static/assets/images/brand/logo-white.svg')"
                    title=""
                    alt=""
                >
                <p class="text-center text-white text-[28px] mb-2">
                    Enter partner code
                </p>
                <p class="text-center text-white text-[14px] w-[346px] mb-12">
                    Your partner code is used to identify your associated amenity
                </p>

                <input
                    v-model="partnerCode"
                    placeholder="Partner code"
                    type="text"
                    class="
						flex
						group
						bg-navy
						w-full
						h-[60px]
						p-[16px]
						pl-[26px]
						rounded-[20px]
						mb-4
						space-x-2
						text-[14px] text-left text-white
						transition
						duration-75
						ease-linear
						placeholder:text-bruised-ego
						shadow-input
						outline-none
						focus:shadow-md
						md:w-[300px]
					"
                >
                <router-link :to="{ name: 'Welcome' }">
                    <button
                        class="
							bg-flushing-pink
							rounded-full
							text-white
							font-medium
							w-full
							h-[65px]
							transition
							duration-75
							ease-linear
							hover:bg-bloody-nose
							mb-8
							md:w-[300px]
							disabled:opacity-50 disabled:hover:bg-flushing-pink
						"
                        :disabled="!partnerCode"
                    >
                        Take me to Space
                    </button>
                </router-link>

                <button class="flex items-center gap-2 hover:opacity-90">
                    <i class="icon icon-help-circle-outline text-white text-[22px]" />

                    <p class="text-white text-[12px]">
                        Where do I find my partner code?
                    </p>
                </button>
                <img
                    class="
						w-[125px]
						relative
						bottom-[-30px]
						right-[-110px]
						opacity-60
						mb-16
					"
                    :src="require('/static/assets/images/misc/clouds-pink.png')"
                    alt=""
                >
            </div>
            <div class="flex mt-auto items-center justify-center space-x-6">
                <a
                    v-for="(social, index) in socials"
                    :key="index"
                    :href="social.url"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text-[14px] text-white hover:opacity-90"
                >
                    <i :class="[ social.icon ]" />
                </a>
            </div>
            <p class="text-[10px] text-dutty-grey text-center mt-4">
                © 2022 Space Storage, Inc.
            </p>
        </div>
    </div>
</template>


<script setup>
    import { ref } from '@vue/reactivity';
    import { SOCIALS } from 'root-js/constants/temp.const.js';

    const partnerCodeInput = ref(null);
    const partnerCode = ref('');

    const socials = ref(SOCIALS);
</script>
