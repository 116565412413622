<template>
    <svg
        width="85"
        height="85"
        viewBox="0 0 85 85"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g
            transform="translate(2 2)"
            stroke="#DD0473"
            stroke-width="1.5"
            fill="none"
            fill-rule="evenodd"
        >
            <path
                d="M12.536 69.429c3.857-3.536 51.143-55.286 55-58.822"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <circle
                cx="40.5"
                cy="40.5"
                r="40.5"
            />
        </g>
    </svg>
</template>
