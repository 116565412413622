import User from 'root-js/models/User';

// Ensure the module is namespaced
const namespaced = true;

/*
 *|-----------------------------------------------------------------------------
 *| State
 *|-----------------------------------------------------------------------------
 */
const state = {
    token: localStorage.getItem('token') || null,
    refreshToken: localStorage.getItem('refreshToken') || null,
    device: localStorage.getItem('device') || null,
    phoneDetails: {
        number: '',
        countryCode: '',
        completeNumber: ''
    },
    os: 'web'
};

/*
 *|-----------------------------------------------------------------------------
 *| Getters
 *|-----------------------------------------------------------------------------
 */
const getters = {

    /**
     * Determine if the user is authenticated.
     *
     * @param {object} state
     * @returns {boolean}
     */
    isAuthenticated: state => !!state.token,
    device: state => state.device,
    os: state => state.os,
    phoneDetails: state => state.phoneDetails,
    token: state => state.token
};

/*
 *|-----------------------------------------------------------------------------
 *| Mutations
 *|-----------------------------------------------------------------------------
 */
const mutations = {
    SET_PHONE_DETAILS: (state, { id, number, countryCode, completeNumber }) => {
        state.phoneDetails = { id, number, countryCode, completeNumber };
    },

    UPDATE_PHONE_DETAILS: (state, payload) => {
        state.phoneDetails = {
            ...state.phoneDetails,
            ...payload
        };
    },

    SET_AUTH: (state, payload) => {
        localStorage.setItem('token', payload.token);
        localStorage.setItem('refreshToken', payload.refresh_token);

        window.api.setToken(payload.token);

        state.token = payload.token;
        state.refreshToken = payload.refresh_token;
    },

    SET_DEVICE: (state, identifier) => {
        state.device = identifier;
    },
    UNSET_AUTH: state => {
        state.token = null;
        state.refreshToken = null;
    }
};

/*
 *|-----------------------------------------------------------------------------
 *| Actions
 *|-----------------------------------------------------------------------------
 */
const actions = {
    SET_DEVICE: ({ commit }, identifier) => {
        localStorage.setItem('device', identifier);

        commit('SET_DEVICE', identifier);
    },
    UNSET_AUTH: async ({ commit, dispatch }) => {
        try {
            await window.api.call('delete-auth'); // Assuming this is an API call

            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');

            window.api.setToken(null);

            // Remove all user related data
            commit('user/UNSET_USER', null, { root: true });
            commit('boxes/RESET_STATE', null, { root: true });
            commit('payments/RESET_STATE', null, { root: true });
            commit('addresses/RESET_STATE', null, { root: true });

            commit('store/UNSET_IS_GET_STARTED_SHOWN', null, { root: true });
            commit('UNSET_AUTH');
        } catch (error) {
            console.error('Error during unset authentication:', error);

        }
    },
    REFRESH_TOKEN: async ({ commit, state }) => {
        const response = await window.api.call('refresh-auth', { refresh_token: state.refreshToken });

        commit('SET_AUTH', response.data.data);

        return response.data.data;
    }
};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
};
