<template>
    <metainfo>
        <template #title="{ content }">
            {{ content }}
        </template>
    </metainfo>

    <router-view class="font-sans" />

    <Backdrop />
</template>

<script setup>
    import { useMeta } from 'vue-meta';
    import { inject } from 'vue';

    import usePreloadApp from 'root-js/plugins/VuePreloader/composables/usePreloadApp';
    import Backdrop from 'root-js/components/common/Backdrop.vue';
    import bus from 'root-js/services/BusService';

    const config = inject('$config');

    // Set the default meta information
    useMeta({ title: config.app.meta.title });

    // Preload the app
    usePreloadApp();
</script>
