<template>
    <transition name="fade">
        <div
            v-if="openedCount > 0"
            class="
				fixed
				top-0
				left-0
				bg-black bg-opacity-40
				h-screen
				w-screen
				z-[60]
				flex
				justify-center
				items-center
				backdrop-blur-sm
			"
            @click="SHOW_DRAWER(null)"
        />
    </transition>
</template>

<script setup>
    import { watch } from '@vue/runtime-core';
    import { BUS_EVENTS } from 'root-js/constants/bus-events.const.js';
    import { mapMutations, mapGetters } from 'root-js/store/lib.js';

    import bus from 'root-js/services/BusService';
    import router from 'root-js/routes.js';

    const { openedCount } = mapGetters('drawers');
    const { SHOW_DRAWER, SET_OPEN_COUNT } = mapMutations('drawers');

    const isFirefoxBrowser = navigator.userAgent.includes('Firefox');

    const appContent = document.getElementById('app');

    watch(() => router.currentRoute,
          () => {
              SET_OPEN_COUNT(0);
          },
          { deep: true });

    watch(() => openedCount,
          () => {
              if (!isFirefoxBrowser) {
                  return;
              }

              if (openedCount.value <= 0) {
                  appContent.classList.remove('blur-sm');
                  return;
              }

              appContent.classList.add('blur-sm');
          });

    bus.on(BUS_EVENTS.SHOW_BACKDROP, () => {
        SET_OPEN_COUNT(openedCount.value + 1);
    });

    bus.on(BUS_EVENTS.HIDE_BACKDROP, () => {
        SET_OPEN_COUNT(openedCount.value - 1);
    });
</script>
