import { onMounted, nextTick } from 'vue';
import usePreloadComponent from 'root-js/plugins/VuePreloader/composables/usePreloadComponent';
import bus from 'root-js/services/BusService';

/**
 * Preload route composable.
 *
 * Provide the route preloader to a view.
 *
 * @author      Ben Carey <bdmc@sinemacula.co.uk>
 * @copyright   2022 Sine Macula Limited.
 */
export default (images = [], preloadOnMount = true) => {

    // Append the images to the main instance array
    usePreloadComponent(images);

    // Initialise the preload
    const preload = () => {
        nextTick().then(() => {
            bus.emit('_preload.initiate');
        });
    };

    // Define the on mounted hook
    onMounted(() => {

        /*
         * Run the preload when the DOM is ready unless specifically delayed by
         * the route
         */
        if (preloadOnMount) {
            preload();
        }

    });

    return { preload };
};
