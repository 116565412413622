<template>
    <form
        id="edit-address"
        class="h-full flex flex-col"
        autocomplete="off"
        novalidate
        @submit.prevent="submit"
        @keydown="
            editForm.resetValidation('general');
            editForm.resetValidation($event.target.name);
        "
        @keyup.enter.exact="submit"
    >
        <div class="flex flex-col justify-center px-6 py-8 md:py-8">
            <div class="flex justify-center items-center mb-14">
                <p class="text-navy text-[18px] items-center text-center">
                    Edit Address
                </p>
            </div>
            <div class="flex relative items-center">
                <div>
                    <div class="flex space-x-3">
                        <p class="text-navy text-[28px]">
                            {{ selectedAddress.label }}
                        </p>
                    </div>
                    <p
                        v-if="editForm.errors.has('general')"
                        class="text-flushing-pink"
                        v-text="editForm.errors.get('general')"
                    />
                    <p
                        v-else
                        class="text-navy text-[14px] w-[253px]"
                    >
                        Edit your address
                    </p>
                </div>

                <img
                    class="absolute -right-[50px] -top-[20px] w-[140px] md:-right-[60px]"
                    :src="require('/static/assets/images/misc/clouds-grey.svg')"
                    alt="Clouds Grey"
                >
            </div>
        </div>

        <div class="flex flex-col items-center justify-center px-4 h-full">
            <SelectDropdown
                v-model:value="editForm.label"
                class="w-full mb-4"
                :options="DEFAULT_ADDRESS_LABELS"
                :disabled="isLoading"
                :input-class="{ 'border border-flushing-pink': editForm.errors.has('label') }"
                placeholder="🏠  Type of address (e.g Home)"
            />

            <Input
                v-model:value="selectedAddress.address_line_1"
                placeholder="Address"
                disabled
                class="mb-4"
            />
            <Input
                v-model:value="selectedAddress.address_line_2"
                placeholder="Apartment, suite, etc (optional)"
                disabled
                class="mb-4"
            />
            <Input
                v-model:value="selectedAddress.city"
                placeholder="City"
                disabled
                class="mb-4"
            />
            <div class="flex space-x-4 w-full items-center justify-center">
                <Input
                    v-model:value="selectedAddress.state"
                    placeholder="State"
                    size-class="w-full md:w-[172px]"
                    disabled
                />
                <Input
                    v-model:value="selectedAddress.postal_code"
                    placeholder="Zip Code"
                    size-class="w-full md:w-[172px]"
                    disabled
                />
            </div>
            <div class="mb-5 self-start">
                <span
                    v-if="editForm.errors.has('label')"
                    class="text-flushing-pink text-[10px] px-5"
                    v-text="editForm.errors.get('label')"
                />
            </div>
            <div class="flex mt-auto items-center justify-center px-4 w-full">
                <div class="flex w-full h-[65px] md:w-[360px] mb-8">
                    <button
                        v-if="!isLoading"
                        class="btn-primary h-full"
                        type="submit"
                        :disabled="isAddButtonDisabled"
                    >
                        Save
                    </button>
                    <img
                        v-else
                        class="animate-spin mx-auto my-auto w-[30px] h-[30px]"
                        :src="
                            require('static/assets/images/misc/icons-spinners-i-os--black.svg')
                        "
                        alt="Icon Spinner"
                    >
                </div>
            </div>
        </div>
    </form>
</template>

<script setup>
    import { ref, reactive } from '@vue/reactivity';
    import { computed } from '@vue/runtime-core';
    import { mapMutations, mapGetters } from 'root-js/store/lib.js';
    import { DEFAULT_ADDRESS_LABELS } from 'root-js/constants/address.const';

    import Input from 'root-js/components/common/Input.vue';
    import SelectDropdown from 'root-js/components/common/SelectDropdown.vue';
    import Form from 'root-js/utils/API/Form';

    const emits = defineEmits([ 'success' ]);

    const { selectedAddress } = mapGetters('addresses');
    const { EDIT_ADDRESS } = mapMutations('addresses');

    const isLoading = ref(false);
    const editForm = reactive(new Form('edit-address',
                                       {
                                           id: {
                                               value: selectedAddress.value.id,
                                               rule: 'required',
                                               max: 20
                                           },
                                           label: {
                                               value: selectedAddress.value.label,
                                               rule: 'required',
                                               max: 20
                                           }
                                       },
                                       null,
                                       { success: () => emits('success') }));

    const isAddButtonDisabled = computed(() => editForm.label === selectedAddress.value.label);

    async function submit() {
        isLoading.value = true;

        try {
            const { data: address } = await editForm.submit(true);
            EDIT_ADDRESS(address);
            isLoading.value = false;
        } catch (error) {

        // Error intentionally ignored or handled elsewhere
        }
        isLoading.value = false;
    }
</script>
