<template>
    <svg
        width="50"
        height="50"
        viewBox="0 0 50 50"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g
            stroke="currentColor"
            stroke-width="1.5"
            fill="none"
            fill-rule="evenodd"
            stroke-linecap="round"
            stroke-linejoin="round"
        >
            <path
                d="M35.003 22.945c1.198 2.117 2.18 4.347 2.33 6.778.287 4.647-1.432 8.478-5.212 11.249-3.174 2.326-6.724 3.012-10.546 2.037-3.157-.805-5.625-2.591-7.443-5.322-1.651-2.48-2.358-5.217-2.069-8.132.29-2.906 1.56-5.511 3.1-7.958C16.814 18.976 23.359 8.85 24.684 7c0 0 7.914 11.695 10.32 15.945z"
            />
            <path
                d="M32.72 31.292c-.394 3.337-2.078 5.716-5.229 6.986-.724.292-1.467.557-2.273.511"
            />
        </g>
    </svg>
</template>
