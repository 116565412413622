<template>
    <img
        :src="src"
        :alt="props.box.title"
        :srcset="srcset"
    >
</template>

<script setup>
    import { computed } from '@vue/runtime-core';

    const props = defineProps({
        box: Object,
        image: String,
        isDeleted: Boolean
    });

    const src = computed(() => {
        if (props.isDeleted) {
            return require('static/assets/images/misc/hero.png');
        }

        return (
            props.image
            || props.box?.image?.original
            || require('static/assets/images/misc/hero.png')
        );
    });

    const srcset = computed(() => {
        if (props.isDeleted) {
            return null;
        }

        return (
            props.image
            || Object.keys(props.box.image || {})
                .filter(key => !isNaN(key))
                .map(key => {
                    const { image } = props.box;
                    return `${image[key]} ${key}w`;
                })
                .join(',')
        );
    });
</script>
