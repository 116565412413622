import { GET_STARTED_KEY } from '../../constants/keys.const';

const namespaced = true;

const state = {
    products: [],
    selectedProduct: null,
    cartBoxes: {},
    isGetStartedShown: !!localStorage.getItem(GET_STARTED_KEY)
};

const getters = {
    products: state => state.products,
    selectedProduct: state => state.selectedProduct,
    cartBoxes: state => Object.values(state.cartBoxes),
    cartBoxesById: state => state.cartBoxes,
    totalCartBoxesQuantity: state => Object.values(state.cartBoxes).reduce((acc, cur) => acc + cur.quantity,
        0),
    isGetStartedShown: state => state.isGetStartedShown
};

const mutations = {
    SET_PRODUCTS: (state, payload) => {
        state.products = payload.map(product => {
            return {
                ...product,
                images: product.media.find(media => media.type === 'image') || { media: { original: '' }}
            };
        });
    },
    SET_CART_PRODUCT: (state, payload) => {

        if (state.cartBoxes[payload.id]) {

            // Update the quantity of the existing product
            state.cartBoxes[payload.id].quantity = payload.quantity;
        } else {

            // Add the new product to cartBoxes
            state.cartBoxes = {
                ...state.cartBoxes,
                [payload.id]: payload
            };
        }

    },
    RESET_CART_PRODUCT: state => {
        state.cartBoxes = [];
    },
    SET_SELECTED_PRODUCT: (state, payload) => {
        state.selectedProduct = payload;
    },
    SET_IS_GET_STARTED_SHOWN: (state, payload) => {
        localStorage.setItem(GET_STARTED_KEY, payload);

        state.isGetStartedShown = payload;
    },
    UNSET_IS_GET_STARTED_SHOWN: state => {
        localStorage.removeItem(GET_STARTED_KEY);

        state.isGetStartedShown = false;
    }
};

const actions = {
    GET_PRODUCTS: async context => {
        try {
            const response = await window.api.call('get-products');

            context.commit('SET_PRODUCTS', response.data.data);

            return response.data.data;
        } catch (error) {
            console.error('Error fetching products:', error);

        }
    }
};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
};
