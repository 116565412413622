<template>
    <div
        id="choose-payment-method"
        class="h-full flex flex-col"
    >
        <div class="flex flex-col justify-center px-6 py-8 md:py-8">
            <div class="flex justify-center items-center mb-14">
                <p class="text-navy text-[18px] items-center text-center">
                    Payment Method
                </p>
            </div>
            <div class="flex relative items-center">
                <div>
                    <p class="text-navy text-[28px] w-[286px]">
                        Choose Payment Method
                    </p>
                </div>

                <img
                    class="absolute -right-[50px] -top-[20px] w-[140px] md:-right-[60px]"
                    :src="require('/static/assets/images/misc/clouds-grey.svg')"
                    alt=""
                >
            </div>
        </div>
        <div
            v-for="(paymentMethod, index) in paymentMethods"
            :key="index"
            class="flex items-center justify-center px-6"
            :class="{
                '':
                    selectedPaymentMethod
                    && paymentMethod.id === selectedPaymentMethod.id
            }"
        >
            <div
                class="
					w-full
					h-[208px]
					rounded-[20px]
					relative
					py-6
					px-10
					mb-2
					md:w-[360px]
				"
                :class="{ 'text-white': CARD_BRAND_COLORS.MUSTARD !== paymentMethod.color }"
                :style="{ backgroundColor: paymentMethod.color }"
            >
                <button
                    class="
						absolute
						top-6
						right-6
						text-[10px]
						w-[110px]
						h-[34px]
						border
						rounded-[17px]
						flex
						items-center
						justify-center
						transition
						duration-75
						ease-linear
						hover:bg-white hover:text-navy hover:border-white
					"
                    :class="{
                        'border-white': CARD_BRAND_COLORS.MUSTARD !== paymentMethod.color,
                        'border-navy': CARD_BRAND_COLORS.MUSTARD === paymentMethod.color
                    }"
                    :tabindex="2"
                    @click="onSelectPaymentMethod(paymentMethod)"
                >
                    Select
                </button>

                <img
                    class="-ml-2 mt-6 mb-6"
                    :class="paymentMethod.cardClass"
                    :src="paymentMethod.cardImage"
                >
                <p class="text-[20px] tracking-[5.56px] whitespace-nowrap mb-2">
                    **** **** **** {{ paymentMethod.card_last_four }}
                </p>
                <div class="flex justify-between">
                    <p class="text-[12px]" />
                    <p class="text-[12px]">
                        Exp {{ paymentMethod.exp_month }} /
                        {{ paymentMethod.exp_year }}
                    </p>
                </div>
            </div>
        </div>
        <div class="flex mt-auto pt-16 items-center justify-center px-4">
            <button
                class="btn-primary h-[65px] mb-6 md:w-[360px]"
                :tabindex="1"
                data-message="Add Card Button"
                @click="onAddCardClick"
            >
                Add Card
            </button>
        </div>
    </div>
</template>

<script setup>
    import { mapMutations, mapGetters } from 'root-js/store/lib.js';
    import { CARD_BRAND_COLORS } from 'root-js/constants/space.const.js';
    import { useGtag } from 'vue-gtag-next';

    const emits = defineEmits([ 'select' ]);

    const { paymentMethods, selectedPaymentMethod } = mapGetters('payments');
    const { SET_SELECTED_PAYMENT_METHOD } = mapMutations('payments');


    const { event } = useGtag();

    function onSelectPaymentMethod(paymentMethod) {
        SET_SELECTED_PAYMENT_METHOD(paymentMethod);
        emits('select');
    }

    function onAddCardClick() {

        // Send event for adding payment info
        event('add_payment_info');

        emits('add');
    }
</script>
