<template>
    <form
        id="complete-profile"
        class="h-full flex flex-col"
        autocomplete="off"
        novalidate
        @submit.prevent="submit"
        @keydown="
            updateUserForm.resetValidation('general');
            updateUserForm.resetValidation($event.target.name);
            createUserForm.resetValidation('general');
            createUserForm.resetValidation($event.target.name);
        "
        @keyup.enter.exact="submit"
    >
        <div class="flex flex-col justify-center px-6 py-8 md:py-8">
            <div class="flex justify-center items-center mb-14">
                <p class="text-navy text-[18px] items-center text-center">
                    Your Details
                </p>
            </div>
            <div class="flex relative items-center">
                <div>
                    <p class="text-navy text-[28px]">
                        Complete Profile
                    </p>

                    <p
                        v-if="updateUserForm.errors.has('general')"
                        class="text-flushing-pink text-[14px] w-[225px] md:w-[253px]"
                        v-text="updateUserForm.errors.get('general')"
                    />

                    <p
                        v-if="createUserForm.errors.has('general')"
                        class="text-flushing-pink text-[14px] w-[225px] md:w-[253px]"
                        v-text="createUserForm.errors.get('general')"
                    />

                    <p
                        v-else
                        class="text-navy text-[14px] w-[225px] md:w-[253px]"
                    >
                        Enter your details to proceed to checkout
                    </p>
                </div>

                <img
                    class="absolute -right-[50px] -top-[20px] w-[140px] md:-right-[60px]"
                    :src="require('/static/assets/images/misc/clouds-grey.svg')"
                    alt="Clouds Grey"
                >
            </div>
        </div>

        <div class="flex flex-col items-center justify-center px-4">
            <Input
                id="first_name"
                v-model:value="updateUserForm.first_name"
                placeholder="First Name"
                class="mb-4"
                :disabled="isLoading"
            />
            <Input
                id="last_name"
                v-model:value="updateUserForm.last_name"
                placeholder="Last Name"
                class="mb-4"
                :disabled="isLoading"
            />
            <Input
                id="email_address"
                v-model:value="updateUserForm.email"
                placeholder="Email address"
                :disabled="isLoading"
            />
        </div>
        <div class="flex flex-col pt-2 mb-5 px-10">
            <span
                v-if="updateUserForm.errors.has('first_name')"
                class="flex text-flushing-pink text-[10px]"
                v-text="updateUserForm.errors.get('first_name')"
            />

            <span
                v-if="updateUserForm.errors.has('last_name')"
                class="flex text-flushing-pink text-[10px]"
                v-text="updateUserForm.errors.get('last_name')"
            />
            <span
                v-if="updateUserForm.errors.has('email')"
                class="flex text-flushing-pink text-[10px]"
                v-text="updateUserForm.errors.get('email')"
            />
        </div>

        <div class="flex mt-auto items-center justify-center px-4 md:px-0">
            <div class="flex w-full h-[65px] md:w-[360px] mb-8">
                <button
                    v-if="!isLoading"
                    class="btn-primary h-full mb-8"
                    type="submit"
                    :disabled="isCheckoutButtonDisabled"
                >
                    Next
                </button>
                <img
                    v-else
                    class="animate-spin mx-auto my-auto w-[30px] h-[30px]"
                    :src="
                        require('static/assets/images/misc/icons-spinners-i-os--black.svg')
                    "
                    alt="Icon Spinner"
                >
            </div>
        </div>
    </form>
</template>

<script setup>
    import { ref, reactive } from '@vue/reactivity';
    import { computed, watch } from '@vue/runtime-core';
    import { mapActions, mapGetters, mapMutations } from 'root-js/store/lib.js';

    import Drawer from 'root-js/components/common/Drawer.vue';
    import AddAddress from 'root-js/components/address-book/AddAddress.vue';
    import SelectAddress from 'root-js/components/your-space/SelectAddress.vue';
    import Input from 'root-js/components/common/Input.vue';
    import Form from 'root-js/utils/API/Form';

    const emits = defineEmits([ 'success' ]);

    const { addresses } = mapGetters('addresses');
    const { user, locale } = mapGetters('user');
    const { SET_USER } = mapMutations('user');
    const { SHOW_DRAWER } = mapMutations('drawers');
    const { GET_USER } = mapActions('user');

    const isAddDeliverAddressDrawerShown = ref(false);
    const isSelectDeliveryAddressShown = ref(false);
    const isLoading = ref(false);

    const updateUserForm = reactive(new Form('update-user',
                                             {
                                                 _method: { value: 'patch' },
                                                 first_name: {
                                                     value: user?.value?.first_name,
                                                     rule: 'required',
                                                     max: 20
                                                 },
                                                 last_name: {
                                                     value: user?.value?.last_name,
                                                     rule: 'required',
                                                     max: 20
                                                 },
                                                 email: { value: user?.value?.email, rule: 'required|email' },
                                                 locale: { value: locale, rule: 'required' }
                                             },
                                             null,
                                             { success: () => emits('success') }));

    const createUserForm = reactive(new Form('create-user',
                                             {
                                                 _method: { value: 'post' },
                                                 first_name: {
                                                     value: user?.value?.first_name,
                                                     rule: 'required',
                                                     max: 20
                                                 },
                                                 last_name: {
                                                     value: user?.value?.last_name,
                                                     rule: 'required',
                                                     max: 20
                                                 },
                                                 email: { value: user?.value?.email, rule: 'required|email' },
                                                 locale: { value: locale, rule: 'required' }
                                             },

                                             null,
                                             { success: () => emits('success') }));

    const isCheckoutButtonDisabled = computed(() => {
        const requiredFields = ['first_name', 'last_name', 'email'];

        return !requiredFields.every(field => updateUserForm[field]);
    });

    watch(() => updateUserForm.first_name,
          () => {
              createUserForm.first_name = updateUserForm.first_name;
          });
    watch(() => updateUserForm.last_name,
          () => {
              createUserForm.last_name = updateUserForm.last_name;
          });
    watch(() => updateUserForm.email,
          () => {
              createUserForm.email = updateUserForm.email;
          });

    watch(() => locale,
          () => {
              updateUserForm.locale = locale;
              createUserForm.locale = locale;
          },
          { immediate: true });

    async function submit() {
        isLoading.value = true;

        try {
            const { data: userDetails }
                = !user.value || !user.value.initialised_at
                    ? await createUserForm.submit(true)
                    : await updateUserForm.submit(true);

            SET_USER(userDetails);
        } catch (error) {
            console.log('error', error);
        }
        isLoading.value = false;
    }
</script>


