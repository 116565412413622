<template>
    <AuthenticatedLayout>
        <div class="p-4 xs:p-8 lg:p-12">
            <router-link
                :to="{ name: 'Your Space' }"
                class="flex items-center mb-4 pt-24 md:pt-0 hover:opacity-90"
            >
                <i class="icon icon-arrow-left text-navy mr-4" />
                <p class="text-[14px] text-navy">
                    Back
                </p>
            </router-link>

            <div class="flex items-center relative mb-2">
                <p class="text-[72px] text-flushing-pink font-medium leading-none">
                    404
                </p>
                <img
                    class="absolute -top-6 left-[200px] md:left-[325px] w-[145px]"
                    :src="require('/static/assets/images/misc/clouds-grey.svg')"
                    alt="Clouds Grey"
                >
            </div>
            <p class="text-[32px] text-navy mb-2">
                Page not found
            </p>
            <p class="text-[18px] text-navy">
                Sorry, the page you were looking for could not be found
            </p>
        </div>
    </AuthenticatedLayout>
</template>


<script setup>
    import { useMeta } from 'vue-meta';

    import AuthenticatedLayout from 'root-js/layouts/AuthenticatedLayout';

    useMeta({ title: 'Space / Not Found' });
</script>
