<template>
    <div
        id="help-center"
        class="h-full flex flex-col"
    >
        <div class="flex flex-col justify-center px-6 py-8 md:py-8">
            <div class="flex justify-center items-center mb-14">
                <p class="text-navy text-[18px] items-center text-center">
                    Help Center
                </p>
            </div>
            <div class="flex relative items-center">
                <div>
                    <p class="text-navy text-[28px]">
                        Contact us
                    </p>

                    <p class="text-navy text-[14px] mb-4">
                        Get in touch with our support team
                    </p>
                </div>

                <img
                    class="absolute -right-[50px] -top-[20px] w-[140px] md:-right-[60px]"
                    :src="require('/static/assets/images/misc/clouds-grey.svg')"
                    alt="Clouds Grey"
                >
            </div>
            <div>
                <button
                    class="flex items-center space-x-3 mb-2 group hover:opacity-90"
                    :tabindex="1"
                >
                    <i class="icon icon-phone text-flushing-pink text-[15px]" />
                    <a
                        href="tel:+1843-564-7416"
                        class="text-navy text-[14px]"
                    >
                        +1843-564-7416
                    </a>
                </button>
                <button
                    class="flex items-center space-x-3 mb-2 group hover:opacity-90"
                    :tabindex="2"
                >
                    <i class="icon icon-mail text-flushing-pink text-[15px]" />
                    <p class="text-navy text-[14px]">
                        <a href="mailto:">support@spacereimagined.io </a>
                    </p>
                </button>
                <button
                    class="flex items-center space-x-3 mb-2 group hover:opacity-90"
                    :tabindex="3"
                    data-message="Frequently Asked Questions Button"
                    @click="isFaqDrawerShown = true"
                >
                    <i class="icon icon-paper text-flushing-pink text-[15px]" />
                    <p class="text-navy text-[14px]">
                        Frequently asked questions
                    </p>
                </button>
            </div>
        </div>

        <div
            v-if="false"
            class="flex gap-2 items-center mb-6 px-[20px]"
        >
            <div class="w-2/4 bg-dusk-stalk h-[1px] opacity-60" />
            <div>
                <p class="text-dusk-stalk text-[12px] opacity-60">
                    OR
                </p>
            </div>
            <div class="w-2/4 bg-dusk-stalk h-[1px] opacity-60" />
        </div>

        <form
            v-if="true"
            autocomplete="off"
            novalidate
            @submit.prevent="submit"
            @keydown="
                form.resetValidation('general');
                form.resetValidation($event.target.name);
            "
            @keyup.enter.exact="submit"
        >
            <div class="px-8 mb-4">
                <p
                    v-if="form.errors.has('general')"
                    class="text-flushing-pink text-[14px]"
                    v-text="form.errors.get('general')"
                />
                <p
                    v-else
                    class="text-navy text-[14px]"
                >
                    Get in touch with our support team
                </p>
            </div>

            <div
                class="flex flex-col items-center justify-center px-4 xs:px-0 mb-16"
            >
                <Input
                    id="subject"
                    v-model:value="form.subject"
                    placeholder="Subject"
                    class="mb-4"
                    :disabled="isLoading"
                />

                <textarea
                    id="message"
                    v-model="form.message"
                    :disabled="isLoading"
                    class="bg-white py-[16px] px-[26px] pb-[313px] rounded-[20px] w-full placeholder:text-bruised-ego text-[14px] text-navy outline-none resize-none transition duration-75 ease-linear shadow-input focus:shadow-md md:w-[360px] disabled:bg-slate-50 disabled:border-slate-300 disabled:text-slate-300 disabled:placeholder-slate-300 disabled:shadow-none"
                    placeholder="Explain your issue…"
                />
            </div>
            <div class="flex flex-col pt-2 mb-5 px-10">
                <span
                    v-if="form.errors.has('subject')"
                    class="flex text-flushing-pink text-[10px]"
                    v-text="form.errors.get('subject')"
                />

                <span
                    v-if="form.errors.has('message')"
                    class="flex text-flushing-pink text-[10px]"
                    v-text="form.errors.get('message')"
                />
            </div>

            <div class="flex mt-auto items-center justify-center px-4 md:px-0">
                <div class="flex w-full h-[65px] md:w-[360px] mb-8">
                    <button
                        v-if="!isLoading"
                        class="btn-primary h-[65px]"
                        type="submit"
                        :disabled="isSumbitButtonDisabled"
                    >
                        Submit
                    </button>
                    <img
                        v-else
                        class="animate-spin mx-auto my-auto w-[30px] h-[30px]"
                        :src="
                            require('static/assets/images/misc/icons-spinners-i-os--black.svg')
                        "
                        alt="Icon Spinner"
                    >
                </div>
            </div>
        </form>

        <Drawer
            :is-shown="isFaqDrawerShown"
            bg-class="bg-white-noise bg-white"
            :is-back-shown="true"
            :is-close-shown="true"
            @close="SHOW_DRAWER(null)"
            @back="isFaqDrawerShown = false"
        >
            <FrequentlyAskedQuestions />
        </Drawer>
    </div>
</template>

<script setup>
    import { reactive, ref } from '@vue/reactivity';
    import { computed } from '@vue/runtime-core';
    import { mapMutations } from 'root-js/store/lib.js';

    import Drawer from 'root-js/components/common/Drawer.vue';
    import Input from 'root-js/components/common/Input.vue';
    import FrequentlyAskedQuestions from 'root-js/components/core/FrequentlyAskedQuestions.vue';
    import Form from 'root-js/utils/API/Form';

    const emits = defineEmits(['success', 'close']);

    const { SHOW_DRAWER } = mapMutations('drawers');

    const isLoading = ref(false);
    const isFaqDrawerShown = ref(false);

    const form = reactive(new Form('help-center',
                                   {
                                       subject: {
                                           value: '',
                                           rule: 'required'
                                       },
                                       message: {
                                           value: '',
                                           rule: 'required'
                                       }
                                   },
                                   null,
                                   { success: () => SHOW_DRAWER(null) }));

    const isSumbitButtonDisabled = computed(() => {
        const requiredFields = ['subject', 'message'];

        return !requiredFields.every(field => form[field]);
    });

    async function submit() {
        isLoading.value = true;
        try {
            await form.submit(true);
        } catch (error) {

        // Error intentionally ignored or handled elsewhere
        }
        isLoading.value = false;
        emits('close');
    }
</script>
