<template>
    <Teleport to="#outside-portal">
        <transition :name="animation">
            <div
                v-if="props.isShown"
                ref="drawer"
                :class="[ props.bgClass ]"
                class="
					overflow-y-auto overflow-x-hidden
					z-[70]
					h-screen
					w-full
					absolute
					top-0
					right-0
					outline-none
					xs:w-[400px]
				"
                :style="{ zIndex: drawerIndex + 60 }"
                tabindex="0"
                @keyup.esc.exact="$emit('close')"
            >
                <div class="absolute top-0 left-0 w-full p-8 flex items-center z-10">
                    <button
                        v-if="props.isBackShown"
                        class="
							icon icon-arrow-left
							text-navy text-[20px]
							hover:opacity-90
							absolute
							top-[35px]
						"
                        :tabindex="props.backTabIndex"
                        data-message="Back Button"
                        @click="$emit('back')"
                    />

                    <button
                        v-if="props.isCloseShown"
                        class="
							ml-auto
							-mr-3
							icon icon-cross-circle-outline
							text-navy text-[31px]
							hover:opacity-90
						"
                        :tabindex="props.closeTabIndex"
                        data-message="Close Button"
                        @click="$emit('close')"
                    />
                </div>

                <slot />
            </div>
        </transition>
    </Teleport>
</template>

<script setup>
    import { computed, onUnmounted, ref, watch } from 'vue';
    import { mapGetters } from 'root-js/store/lib.js';
    import { BUS_EVENTS } from 'root-js/constants/bus-events.const.js';

    import bus from 'root-js/services/BusService';

    const emits = defineEmits(['close', 'back']);
    const props = defineProps({
        closeTabIndex: Number,
        backTabIndex: Number,
        isShown: Boolean,
        isCloseShown: Boolean,
        isBackShown: Boolean,
        bgClass: {
            default: 'bg-white',
            type: String
        }
    });

    const { openedCount } = mapGetters('drawers');

    const drawer = ref(null);
    const drawerIndex = ref(1);

    const animation = computed(() => {
        if (props.isShown) {
            return 'slide';
        }

        return 'slide-back';
    });

    onUnmounted(() => {
        if (props.isShown) {
            bus.emit(BUS_EVENTS.HIDE_BACKDROP);
        }
    });

    watch(() => props.isShown,
          () => {
              if (props.isShown) {
                  bus.emit(BUS_EVENTS.SHOW_BACKDROP);
                  drawerIndex.value = openedCount.value;

                  setTimeout(() => {
                      drawer.value?.focus();
                  }, 500);

                  return;
              }
              bus.emit(BUS_EVENTS.HIDE_BACKDROP);
          });
</script>
