import { parsePhoneNumberFromString } from 'libphonenumber-js/mobile';

export { Validator as default };

/**
 * Handle all data validation.
 *
 * @author      Ben Carey <bdmc@sinemacula.co.uk>
 * @copyright   2021 Sine Macula Limited.
 */
class Validator {

    /**
     * Create a new Validator instance.
     *
     * @param {object}  rules
     */
    constructor(rules) {
        this.rules = this._buildRules(rules);
        this.labels = {};
    }

    /**
     * Fetch all relevant data for the form.
     *
     * @param {object}  rules
     * @return object
     */
    _buildRules(rules) {
        const processed = {};

        /*
         * Loop through the rules and build the processed
         * object
         */
        Object.entries(rules).forEach(([key, value]) => {
            if (value !== '') {
                processed[key] = {};

                const rules = value.split('|');

                rules.forEach(rule => {
                    const parts = rule.split(':');

                    parts[1] = parts[1] ? parts[1].split(',') : [];

                    processed[key][parts[0]] = parts[1];
                });
            }
        });

        return processed;
    }

    /**
     * Get the field label based on the supplied field
     * name.
     *
     * @param {string}  field
     * @return {string}
     */
    _getFieldLabel(field) {
        if (typeof this.labels[field] === 'undefined') {
            this.labels[field] = field
                .toLowerCase()
                .split('_')

                /* .map((s) => s.charAt(0).toUpperCase() + s.substring(1)) */
                .join(' ');
        }

        return this.labels[field];
    }

    /**
     * Validate the specified fields.
     *
     * @param {object}  values
     * @param {object|null}  rules
     * @return {object}
     */
    validate(values, rules = null) {
        const errors = {};

        rules = rules == null ? this.rules : this._buildRules(rules);

        /*
         * Loop through the values and validate them for each
         * each associated test
         */
        Object.entries(values).forEach(([field, value]) => {

            // No need to continue of the field has no rules specified
            if (typeof rules[field] === 'undefined') {
                return;
            }

            errors[field] = [];

            // Loop through the rules and run the tests
            Object.entries(rules[field]).forEach(([test, parameters]) => {

                // Ensure the validation rule has a corresponding test
                if (typeof this[`_${test}`] !== 'function') {
                    console.warn(`Invalid validation rule supplied: ${test}`);
                    return;
                }

                // Run the test
                try {
                    this[`_${test}`](field, value, parameters);
                } catch (error) {
                    errors[field].push(error);
                }
            });

            if (!errors[field].length) {
                delete errors[field];
            }
        });

        return Object.entries(errors).length === 0
            && errors.constructor === Object
            ? true
            : errors;
    }

    /**
     * Validate whether the supplied value is present.
     *
     * @param {string}  field
     * @param {string|int}  value
     * @returns {void}
     */
    _required(field, value) {
        const label = this._getFieldLabel(field);

        if (value == null || value === '') {
            throw `The ${label} field is required.`;
        }
    }

    /**
     * Validate whether the supplied value is greater
     * than the specified value. For integers, it will
     * test to see if the number is greater than, for
     * strings it will test to see if the length is
     * greater than.
     *
     * @param {string}  field
     * @param {string|int}  value
     * @param {array}  parameters
     * @return {void}
     */
    _min(field, value, parameters) {
        const label = this._getFieldLabel(field);

        if (isNaN(value) && value.length < parameters[0]) {
            throw `The ${label} must be at least ${parameters[0]} characters.`;
        } else if (!isNaN(value) && value < parameters[0]) {
            throw `The ${label} must be at least ${parameters[0]}.`;
        }
    }

    /**
     * Validate whether the supplied value is smaller
     * than the specified value. For integers, it will
     * test to see if the number is smaller than, for
     * strings it will test to see if the length is
     * smaller than.
     *
     * @param {string}  field
     * @param {string|int}  value
     * @param {array}  parameters
     * @returns {void}
     */
    _max(field, value, parameters) {
        const label = this._getFieldLabel(field);

        if (isNaN(value) && value.length > parameters[0]) {
            throw `The ${label} may not be greater than ${parameters[0]} characters.`;
        } else if (!isNaN(value) && value > parameters[0]) {
            throw `The ${label} may not be greater than ${parameters[0]}.`;
        }
    }

    /**
     * Validate whether the size of the supplied value
     * matches the specified value. For integers, it will
     * test to see if the number is equal to the specified
     * size, and for strings it will test to see if the
     * length matches the supplied size.
     *
     * @param {string}  field
     * @param {string|int}  value
     * @param {array}  parameters
     * @returns {void}
     */
    _size(field, value, parameters) {
        const label = this._getFieldLabel(field);

        parameters[0] = parseInt(parameters[0]);

        if (Array.isArray(value) && value.length !== parameters[0]) {
            throw `The ${label} must contain ${parameters[0]} items.`;
        } else if (
            isNaN(value)
            && !Array.isArray(value)
            && value.length !== parameters[0]
        ) {
            throw `The ${label} must be ${parameters[0]} characters.`;
        } else if (
            !isNaN(value)
            && !Array.isArray(value)
            && value !== parameters[0]
        ) {
            throw `The ${label} must be ${parameters[0]} characters.`;
        }
    }

    /**
     * Validate whether the supplied value is a string.
     *
     * @param {string}  field
     * @param {string|int}  value
     * @returns {void}
     */
    _string(field, value) {
        const label = this._getFieldLabel(field);

        if (typeof value !== 'string' || !isNaN(value)) {
            throw `The ${label} must be a string.`;
        }
    }

    /**
     * Validate whether the supplied value is a valid
     * number.
     *
     * @param {string}  field
     * @param {string|int}  value
     * @returns {void}
     */
    _numeric(field, value) {
        const label = this._getFieldLabel(field);

        if (isNaN(value)) {
            throw `The ${label} must be a number.`;
        }
    }

    /**
     * Validate whether the supplied value is in the
     * supplied array.
     *
     * @param {string}  field
     * @param {string|int}  value
     * @param {array}  parameters
     * @returns {void}
     */
    _in(field, value, parameters) {
        const label = this._getFieldLabel(field);

        if (!parameters.includes(value)) {
            throw `The selected ${label} is invalid.`;
        }
    }

    /**
     * Validate whether the supplied value matches only alpha characters.
     *
     * @param {string}  field
     * @param {string|int}  value
     * @returns {void}
     */
    _alphadash(field, value) {
        const label = this._getFieldLabel(field);
        const regex = /^[a-z-]+$/i;

        if (!regex.test(value)) {
            throw `The ${label} may only contain letters and hyphens.`;
        }
    }

    /**
     * Validate whether the supplied value is a file.
     *
     * @param {string}  field
     * @param {string|int}  value
     * @returns {void}
     */
    _file(field, value) {
        if (!value) {
            return;
        }

        const label = this._getFieldLabel(field);

        if (!(value instanceof File)) {
            throw `The ${label} must be a file.`;
        }
    }

    /**
     * Validate whether the supplied file is an image.
     *
     * @param {string}  field
     * @param {string|int}  value
     * @param {array}  parameters
     * @returns {void}
     */
    _image(field, value, parameters) {
        if (!value) {
            return;
        }

        const label = this._getFieldLabel(field);

        if (!value.type || value.type.split('/')[0] !== 'image') {
            throw `The ${label} must be an image.`;
        }
    }

    /**
     * Validate whether the supplied value is a valid
     * email address.
     *
     * @param {string}  field
     * @param {string|int}  value
     * @return {void}
     */
    _email(field, value) {
        const label = this._getFieldLabel(field);
        const regex
            = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!regex.test(String(value).toLowerCase())) {
            throw `The ${label} must be a valid email address.`;
        }
    }

    /**
     * Validate whether the supplied value is a valid
     * phone number.
     *
     * @param {string}  field
     * @param {string|int}  value
     * @return {void}
     */
    _phone(field, value) {
        const label = this._getFieldLabel(field);
        const phoneNumber = parsePhoneNumberFromString(String(value), 'GB');

        if (!phoneNumber || !phoneNumber.isValid()) {
            throw `The ${label} field contains an invalid phone number.`;
        }
    }
}
