<template>
    <div
        class="
			flex
			bg-white
			rounded-[12px]
			py-3
			px-[16px]
			text-center
			shadow-input
			h-[60px]
			focus:shadow-md
		"
    >
        <input
            type="text"
            class="
				bg-transparent
				text-[18px]
				w-[25px]
				text-center
				transition
				duration-75
				ease-linear
				text-navy
				outline-none
				mr-2
			"
            disabled
            placeholder="0"
            :value="props.value"
        >
        <div class="flex flex-col justify-between">
            <button
                class="icon icon-chevron-up text-navy text-[13px]"
                :tabindex="props.increaseTabIndex"
                data-message="Increase Button"
                @click="increase"
            />
            <button
                class="icon icon-chevron-down text-navy text-[13px]"
                :tabindex="props.decreaseTabIndex"
                data-message="Decrease Button"
                @click="decrease"
            />
        </div>
    </div>
</template>

<script setup>
    const emits = defineEmits([ 'update:value' ]);

    const props = defineProps({
        increaseTabIndex: Number,
        decreaseTabIndex: Number,
        value: Number
    });

    function increase() {
        emits('update:value', props.value + 1);
    }

    function decrease() {
        if (props.value - 1 < 0) {
            return;
        }

        emits('update:value', props.value - 1);
    }
</script>
