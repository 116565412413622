<template>
    <svg
        width="50"
        height="50"
        viewBox="0 0 50 50"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g
            stroke="currentColor"
            fill="none"
            fill-rule="evenodd"
            stroke-linecap="round"
            stroke-linejoin="round"
        >
            <path
                d="M21.094 43c-4.719-1.608-7.016-6.347-7.655-8.979-.995-4.085-.393-8.41 2.784-14.365C18.562 15.273 24.52 7 24.52 7s5.959 8.273 8.297 12.656c3.177 5.956 3.78 10.28 2.783 14.365-.637 2.627-2.927 7.355-7.63 8.97"
                stroke-width="1.44"
            />
            <path
                d="M27.87 43c2.465-1.262 3.399-5.387.787-9.368-3.067-4.677-2.414-7.352-2.414-7.352s-2.83 1.071-3.477 5.772c-.328 2.383.241 5.636.241 5.636s-2.983-.307-2.507-5.036"
                stroke-width="1.5"
            />
            <path
                d="M21.094 43c-2.656-1.295-3.368-7.637-.645-10.367"
                stroke-width="1.5"
            />
        </g>
    </svg>
</template>
