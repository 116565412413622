<template>
    <div
        id="product-details"
        class="h-full flex flex-col relative"
    >
        <div class="flex flex-col justify-center p-8">
            <div class="flex justify-center items-center mb-8">
                <p class="text-navy text-[18px] items-center text-center">
                    Store
                </p>
            </div>
            <button
                class="absolute right-6 top-8 z-10 text-navy text-[20px] hover:opacity-90"
            >
                <CartNotification
                    data-message="Cart Button"
                    :cart-tab-index="2"
                    @click="isYourCartDrawerShown = true"
                />
            </button>
            <div class="flex justify-between items-center">
                <div class="flex items-center gap-2">
                    <i class="icon icon-measure text-navy text-[29px]" />
                    <p class="text-navy text-[12px] mr-2">
                        {{ mmToInches(props.product.properties[0].value) }} x
                        {{ mmToInches(props.product.properties[1].value) }} x
                        {{ mmToInches(props.product.properties[2].value) }} inch
                    </p>
                    <i class="icon icon-weight text-navy text-[14px]" />
                    <p class="text-navy text-[12px]">
                        50 lbs max
                    </p>
                </div>
            </div>
            <h3 class="text-[32px] text-navy mb-20">
                {{ props.product.name }}
            </h3>
            <div>
                <div
                    class="flex flex-col bg-flushy-pink-gradient items-center justify-center rounded-full shadow-price w-[106px] h-[106px] absolute top-[180px] right-[30px] z-10"
                >
                    <div class="items-center mt-2">
                        <p
                            class="text-white text-[36px] leading-[30px] relative text-center ml-[6px]"
                        >
                            <span
                                class="absolute top-[-10px] -left-2 opacity-60 text-[18px]"
                            >
                                $
                            </span>
                            {{
                                readableDecimalNumber(
                                    props.product.plans[0].price
                                )
                            }}
                        </p>
                        <p
                            class="text-white text-[14px] text-center opacity-60"
                        >
                            a month
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="relative">
            <img
                class="absolute top-[-210px]"
                :src="props.product.images.media.original"
            >
        </div>
        <div class="px-8 mt-48">
            <p class="text-navy text-[16px] mb-16">
                {{ props.product.description }}
            </p>
        </div>
        <div class="flex mt-auto items-center justify-center px-4">
            <button
                class="btn-primary h-[65px] mb-8"
                :tabindex="1"
                data-message="Add Cart Button"
                @click="onClickAddToCart"
            >
                Add to Cart
            </button>
        </div>
        <Drawer
            :is-shown="isYourCartDrawerShown"
            bg-class="bg-white-noise bg-white"
            :is-back-shown="true"
            :is-close-shown="true"
            :back-tab-index="6"
            :close-tab-index="7"
            @close="SHOW_DRAWER(null)"
            @back="isYourCartDrawerShown = false"
        >
            <YourCart />
        </Drawer>
    </div>
</template>

<script setup>
    import { ref } from '@vue/reactivity';
    import { mapMutations, mapGetters } from 'root-js/store/lib.js';
    import { readableDecimalNumber } from 'root-js/helpers/number.helper';


    import CartNotification from 'root-js/components/core/CartNotification.vue';
    import Drawer from 'root-js/components/common/Drawer.vue';
    import YourCart from 'root-js/components/your-space/YourCart.vue';
    import { mmToInches } from 'root-js/helpers/box.helper';
    import { useGtag } from 'vue-gtag-next';

    const isYourCartDrawerShown = ref(false);

    const { cartBoxesById } = mapGetters('store');
    const { SHOW_DRAWER } = mapMutations('drawers');
    const { SET_CART_PRODUCT } = mapMutations('store');

    const props = defineProps({ product: Object });
    const { event } = useGtag();

    function onClickAddToCart() {
        const quantity = cartBoxesById.value[props.product.id]
            ? cartBoxesById.value[props.product.id].quantity + 1
            : 1;

        SET_CART_PRODUCT({
            ...props.product,
            quantity
        });

        // Send GA event for adding product to cart
        event('add_to_cart', {
            currency: 'USD',
            value: readableDecimalNumber(props.product.plans[0].price * quantity),
            items: [ {
                item_id: props.product.id,
                item_name: props.product.name,
                price: readableDecimalNumber(props.product.plans[0].price),
                quantity
            } ]
        });

        isYourCartDrawerShown.value = true;
    }
</script>
