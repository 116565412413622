<template>
    <div
        class="flex flex-col space-x-5 bg-white rounded-md mt-2"
        v-bind="$attrs"
    >
        <div class="flex justify-items-start p-4">
            <i
                class="icon icon-cross rounded-full bg-flushing-pink text-white p-1 cursor-pointer"
                @click="emit('toggle:calendar')"
            />
        </div>
        <p class="text-navy text-[22px] text-center">
            Select a pick-up date
        </p>
        <div class="flex flex-col flex-grow">
            <Month
                v-model="localSelectedValues.month"
                :year="localSelectedValues.year"
            />
            <Dates
                :selected-values="localSelectedValues"
                :selected-date="selectedDateValue"
                :current-active-month-year="currentActiveDateValues"
                :valid-end-date="validEndDate"
                @selected="changeDate"
            />
        </div>
        <div class="flex justify-between p-2">
            <div class="w-1/2">
                <button
                    v-show="showPreviousButton"
                    class="text-navy text-[16px] flex items-baseline gap-2"
                    @click="changeMonth(localSelectedValues.month - 1)"
                >
                    <i class="icon icon-arrow-left text-navy text-[16px]" />
                    Previous month
                </button>
            </div>
            <div class="w-1/2 flex justify-end">
                <button
                    v-show="showNextButton"
                    class="text-navy text-[16px] flex items-baseline gap-2"
                    @click="changeMonth(localSelectedValues.month + 1)"
                >
                    Next month
                    <i class="icon icon-arrow-right text-navy text-[16px]" />
                </button>
            </div>
        </div>
    </div>
</template>

  <script setup>
    import dayjs from 'dayjs';
    import { defineAsyncComponent, ref, reactive, computed, watch } from 'vue';
    import { mapGetters } from 'root-js/store/lib.js';

    const Year = defineAsyncComponent(() => import('./Year.vue'));
    const Month = defineAsyncComponent(() => import('./Month.vue'));
    const Dates = defineAsyncComponent(() => import('./Dates.vue'));

    const { pickupDetails } = mapGetters('boxes');

    const props = defineProps({
        selectedDateValue: { type: Number },
        selectedValues: { type: Object },
        currentActiveDateValues: { type: Object }
    });

    const emit = defineEmits([
        'update:selectedDateValue',
        'update:selectedValues',
        'toggle:calendar'
    ]);

    // Create a local reactive copy of selectedValues
    const localSelectedValues = reactive({ ...props.selectedValues });

    function changeMonth(newMonth) {
        let adjustedMonth = newMonth;
        let adjustedYear = localSelectedValues.year;

        if (newMonth > 11) {
            adjustedMonth = 0;
            adjustedYear++;
        } else if (newMonth < 0) {
            adjustedMonth = 11;
            adjustedYear--;
        }

        // Update the local copy
        localSelectedValues.month = adjustedMonth;
        localSelectedValues.year = adjustedYear;

        // Emit the updated values
        emit('update:selectedValues', { ...localSelectedValues });
    }

    function changeDate(v) {
        emit('update:selectedDateValue', v);
    }

    const showPreviousButton = computed(() => {
        const tomorrow = dayjs().add(1, 'day');
        const currentMonth = dayjs(new Date(localSelectedValues.year, localSelectedValues.month, 1));
        return currentMonth.isAfter(tomorrow, 'month');
    });

    const showNextButton = computed(() => {
        const currentMonth = dayjs(new Date(localSelectedValues.year, localSelectedValues.month, 1));

        const validDates = Object.keys(pickupDetails.value.dates)
            .map(date => dayjs(date))
            .slice(0, 31);

        return currentMonth.isBefore(validDates[validDates.length - 1], 'month');
    });

    const validEndDate = computed(() => {
        let daysToAdd = 0;
        let currentDate = dayjs().add(1, 'day');

        while (daysToAdd < 30) {
            if (currentDate.day() !== 0 && currentDate.day() !== 6) {
                daysToAdd++;
            }
            currentDate = currentDate.add(1, 'day');
        }

        return currentDate.toDate();
    });
  </script>
