<template>
    <div class="flex flex-col mt-auto items-center justify-center px-6 mb-4">
        <div
            class="bg-white items-center w-full rounded-[20px] p-[20px] shadow-input"
            :class="{ 'opacity-50 pointer-events-none': props.disabled }"
        >
            <div
                v-if="showCreaditTag && (userBalance > 0 || hasBundle)"
                class="flex justify-end"
            >
                <Tag
                    text="Credit applied"
                />
            </div>

            <div class="flex justify-between">
                <div class="flex flex-col">
                    <p class="text-navy text-[18px] mb-1">
                        {{ props.title }}
                    </p>
                    <p class="text-navy text-[12px]">
                        {{ props.description }}
                    </p>
                </div>

                <div class="w-[100px] flex items-center justify-center">

                    <div class="relative">

                        <p
                            :class="{
                                'text-navy text-[18px] absolute left-[-15px] -top-[1px]':
                                    !props.discountedPrice,
                                'text-gray-400 text-[18px] absolute left-[-15px] -top-[1px]':
                                    props.discountedPrice
                            }"
                        >
                            $
                        </p>

                        <p class="text-navy text-[40px] leading-[40px]">
                            <span
                                v-if="props.discountedPrice"
                                class="line-through decoration-2 text-gray-400 text-[30px]"
                            >
                                {{ readableDecimalNumber(props.price) }}
                            </span>
                            <span>
                                {{
                                    readableDecimalNumber(
                                        props.price
                                            - (props.discountedPrice ?? 0)
                                    )
                                }}
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <div
                v-if="props.cardLastFour || props.address"
                class="space-y-2 mt-4"
            >
                <div
                    v-if="props.cardLastFour"
                    class="flex space-x-2"
                >
                    <img
                        class="w-[25px]"
                        :src="
                            require('/static/assets/images/misc/credit-cards-cards-mastercard.png')
                        "
                        alt="Mastercard"
                    >
                    <p class="text-[12px] text-navy">
                        **** **** **** {{ props.cardLastFour }}
                    </p>
                    <button
                        v-if="props.isEditable"
                        class="icon icon-edit text-navy text-[14px] hover:opacity-90"
                        :tabindex="props.editPaymentTabIndex"
                        data-message="Edit Payment Button"
                        @click.prevent="isChoosePaymentMethodDrawerShown = true"
                    />
                </div>
                <div
                    v-if="props.address"
                    class="flex space-x-2"
                >
                    <p
                        class="text-[12px] text-navy truncate"
                        v-text="props.address"
                    />
                    <button
                        class="icon icon-edit text-navy text-[14px] hover:opacity-90"
                        :tabindex="props.editAddressTabIndex"
                        data-message="Edit Payment Button"
                        @click.prevent="handleEditAddress"
                    />
                </div>

                <div
                    v-if="props.pickupDate"
                    class="flex space-x-2"
                >
                    <i
                        class="icon icon-calendar text-[13px] text-flushing-pink"
                    />
                    <p
                        class="text-[12px] text-navy truncate"
                        v-text="props.pickupDate"
                    />
                </div>
            </div>
        </div>
        <Drawer
            :is-shown="isCreatePaymentMethodDrawerShown"
            bg-class="bg-white-noise bg-white"
            :is-back-shown="true"
            :is-close-shown="true"
            @close="SHOW_DRAWER(null)"
            @back="isCreatePaymentMethodDrawerShown = false"
        >
            <CreatePaymentMethod
                @success="
                    isCreatePaymentMethodDrawerShown = false,
                    isChoosePaymentMethodDrawerShown = false
                "
            />
        </Drawer>
        <Drawer
            :is-shown="isChoosePaymentMethodDrawerShown"
            bg-class="bg-white-noise bg-white"
            :is-back-shown="true"
            :is-close-shown="true"
            @close="SHOW_DRAWER(null)"
            @back="isChoosePaymentMethodDrawerShown = false"
        >
            <ChoosePaymentMethod
                @select="isChoosePaymentMethodDrawerShown = false"
                @add="isCreatePaymentMethodDrawerShown = true"
            />
        </Drawer>
        <Drawer
            :is-shown="isSelectAddressDrawerShown"
            bg-class="bg-white-noise bg-white"
            :is-back-shown="true"
            :is-close-shown="true"
            @close="SHOW_DRAWER(null)"
            @back="isSelectAddressDrawerShown = false"
        >
            <SelectAddress
                @choosePaymentMethod="isSelectAddressDrawerShown = false"
            />
        </Drawer>
    </div>
</template>

<script setup>
    import { ref } from 'vue';
    import {
        readableNumber,
        readableDecimalNumber
    } from 'root-js/helpers/number.helper';
    import { mapMutations, mapGetters  } from 'root-js/store/lib.js';

    import Drawer from 'root-js/components/common/Drawer.vue';
    import ChoosePaymentMethod from 'root-js/components/your-space/ChoosePaymentMethod.vue';
    import SelectAddress from 'root-js/components/your-space/SelectAddress.vue';
    import CreatePaymentMethod from 'root-js/components/your-space/CreatePaymentMethod.vue';
    import Tag from 'root-js/components/common/Tag.vue';

    const emits = defineEmits([ 'changeAddress' ]);

    const props = defineProps({
        editPaymentTabIndex: Number,
        editAddressTabIndex: Number,
        disabled: Boolean,
        title: String,
        description: String,
        address: String,
        cardLastFour: String,
        price: Number,
        isCardBrandShown: Boolean,
        isEditable: Boolean,
        isLoading: Boolean,
        pickupDate: String,
        discountedPrice: Number,
        back: {
            type: Function,
            default: null
        },
        showCreaditTag: {
            type: Boolean,
            default: false
        },
        hasBundle: {
            type: Boolean,
            default: false
        }
    });

    const { SHOW_DRAWER } = mapMutations('drawers');
    const { user } = mapGetters('user');

    const isSelectAddressDrawerShown = ref(false);
    const isChoosePaymentMethodDrawerShown = ref(false);
    const isCreatePaymentMethodDrawerShown = ref(false);
    const userBalance = ref(user.value.balance * -1);

    function handleEditAddress() {
        if (props.back) {
            props.back();
        } else {
            isSelectAddressDrawerShown.value = true;
        }
    }
</script>
