<template>
    <svg
        width="50"
        height="50"
        viewBox="0 0 50 50"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g
            stroke="currentColor"
            stroke-width="1.5"
            fill="none"
            fill-rule="evenodd"
            stroke-linecap="round"
            stroke-linejoin="round"
        >
            <path
                d="M14.317 14.45c-.935.854-2.23 1.98-3.008 2.994-1.168 1.524-1.918 3.216-2.2 5.096-.244 1.628-.065 3.235.405 4.816.902 3.041 2.6 5.66 4.485 8.174 1.378 1.838 2.978 3.48 4.75 4.956 3.042 2.531 6.477 4.319 10.388 5.118 1.509.308 3.064.434 4.611.28.897-.089 1.727-.417 2.4-1.036 1.293-1.19 2.187-2.643 2.919-4.22a22.408 22.408 0 0 0 1.723-5.506c.298-1.659.39-3.343.315-5.041a24.721 24.721 0 0 0-.941-5.725c-.77-2.697-1.978-5.174-3.773-7.37-.468-.572-1.129-1.019-1.315-1.806-.046-.195-.311-.168-.496-.184-.86-.078-1.545.293-2.118.867-.684.686-1.09 1.496-1.026 2.495.026.426.197.714.605.864.498.183.992.363 1.436.675.85.598 1.277 1.4 1.385 2.414.198 1.856-.61 3.306-1.853 4.588-.683.705-2.353 2.52-2.502 2.72-.198.27-.071.422.103.6.401.407 3.601 2.783 3.92 3.047.102.083.188.172.327.131.54-.16 2.939-.028 3.6-.044"
            />
            <path
                d="M35.126 15.35c.625-.564 4.654-1.156 5.882-1.39.72-.136.787-.332.74-1.103-.082-1.348-.343-2.672-.468-4.013-.062-.656-.27-.83-.91-.831-1.382-.005-2.744.19-4.105.41-.298.049-5.084-4.013-10.028-2.424-1.762.488-3.28 1.436-4.728 2.58-1.138.798-2.187 3.327-2.294 3.865-.308 1.544-.105 3.004.77 4.336.952 1.449 2.274 2.328 3.967 2.632.018.003.03.04.047.043.905.137 1.197-.113 1.183-1.06-.046-3.117 1.062-5.535 3.786-7.246 2.725-1.711 6.97-2.307 7.297-2.726M27.27 33.192c-.6-.541-1.553-.993-2.357-1.074-1.701-.17-3.101.432-4.303 1.522-.28.255-.54.372-.9.333M20.34 9.006c-.553-.39-2.147-2.852-2.637-3.497-.496-.654-.632-.607-1.294-.256a239.059 239.059 0 0 0-4.924 2.735c-.74.502-.728.596-.3 1.321.607 1.031 2.51 3.91 2.81 4.341.17.247.27.51.308.8M34.2 33.35c-.19.562-.09 1.16-.162 1.736-.187 1.475-1.479 2.57-2.358 2.674M30.42 29.57c-.574.615-3.617 4.152-3.833 4.465-.572.826-1.048 1.708-1.16 2.757-.098.907-.06 1.373.28 2.228"
            />
        </g>
    </svg>
</template>
