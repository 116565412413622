<template>
    <div
        id="product-cart"
        class="h-full flex flex-col relative"
    >
        <div class="flex flex-col p-8 h-full">
            <div class="flex justify-center items-center mb-14">
                <p class="text-navy text-[18px] items-center text-center">
                    Your Cart
                </p>
            </div>
            <div class="relative mb-6">
                <h3
                    v-if="shownCartBoxes.length"
                    class="text-navy text-[28px]"
                >
                    Your boxes
                </h3>
                <p
                    v-if="shownCartBoxes.length"
                    class="text-navy text-[14px]"
                >
                    Review your cart
                </p>

                <img
                    class="absolute -right-[50px] -top-[20px] w-[140px] md:-right-[60px]"
                    :src="require('/static/assets/images/misc/clouds-grey.svg')"
                    alt="Clouds Grey"
                >
            </div>

            <div
                v-if="shownCartBoxes.length"
                class="flex flex-col"
            >
                <div
                    v-for="(cartBox, index) in shownCartBoxes"
                    :key="index"
                    class="flex items-center mb-4"
                >
                    <img
                        class="bg-unknown-grey-800 rounded-lg w-[88px] flex-shrink-0 mr-4"
                        :src="cartBox.images.media.original"
                    >

                    <div class="mr-4">
                        <p
                            class="text-navy text-[18px] w-[100px] truncate xs:w-[125px]"
                        >
                            {{ cartBox.name }}
                        </p>
                        <p
                            class="text-navy text-[12px] w-[100px] truncate xs:w-[125px]"
                        >
                            ${{
                                readableDecimalNumber(cartBox.plans[0].price)
                            }}/m per box
                        </p>
                        <button
                            class="text-flushing-pink text-[12px] hover:text-bloody-nose"
                            :tabIndex="3"
                            data-message="Delete Button"
                            @click="removeFromCart(cartBox)"
                        >
                            Delete
                        </button>
                    </div>
                    <InputQuantity
                        :value="cartBox.quantity"
                        :decrease-tab-index="4"
                        :increase-tab-index="5"
                        data-message="Delete Button"
                        class="ml-auto"
                        @update:value="(quantity) => updateCartQuantity(cartBox, quantity, cartBox.quantity)"
                    />
                </div>
            </div>

            <div
                v-else
                class="flex flex-col items-center justify-center h-full"
            >
                <i class="icon icon-bag text-navy text-[54px] mb-8" />
                <p class="text-navy text-[14px] text-center">
                    Your cart is empty
                </p>
            </div>
        </div>

        <div class="mt-auto">
            <BillingDetailsCard
                v-if="shownCartBoxes.length"
                title="Monthly total"
                description="3 month minimum order"
                :price="totalPrice"
            />
            <div class="flex items-center justify-center px-4">
                <button
                    v-if="shownCartBoxes.length"
                    class="btn-primary h-[65px] mb-8 xs:w-[360px] xs:mx-4"
                    :tabindex="1"
                    data-message="Checkout Button"
                    @click="onCheckoutClicked"
                >
                    Checkout
                </button>
                <button
                    v-else
                    class="btn-primary h-[65px] mb-8 xs:w-[360px] xs:mx-4"
                    :tabindex="2"
                    data-message="Checkout Button"
                    @click="toggleProductListDrawer"
                >
                    Add Boxes
                </button>
            </div>
        </div>
        <Drawer
            :is-shown="isCompleteProfileShown"
            bg-class="bg-white-noise bg-white"
            :is-back-shown="true"
            :is-close-shown="true"
            @close="SHOW_DRAWER(null)"
            @back="isCompleteProfileShown = false"
        >
            <CompleteProfile @success="onSuccessCompleteProfile" />
        </Drawer>
        <Drawer
            v-if="products?.length > 1"
            :is-shown="isProductListDrawerShown"
            bg-class="bg-blue-noise"
            :is-close-shown="false"
        >
            <ProductList />
        </Drawer>

        <Drawer
            v-else
            :is-shown="isProductListDrawerShown"
            bg-class="bg-white-noise bg-white"
            :is-close-shown="false"
        >
            <ProductDetails :product="products[0]" />
        </Drawer>
        <Drawer
            :is-shown="isAddAddressDrawerShown"
            bg-class="bg-white-noise bg-white"
            :is-back-shown="true"
            :is-close-shown="true"
            @close="SHOW_DRAWER(null)"
            @back="isAddAddressDrawerShown = false"
        >
            <AddAddress
                :is-selected-address="true"
                @success="onSuccessCreateAddress"
            />
        </Drawer>

        <Drawer
            :is-shown="isSelectAddressDrawerShown"
            bg-class="bg-white-noise bg-white"
            :is-back-shown="true"
            :is-close-shown="true"
            :back-tab-index="3"
            :close-tab-index="4"
            @close="SHOW_DRAWER(null)"
            @back="isSelectAddressDrawerShown = false"
        >
            <SelectAddress
                @choosePaymentMethod="isSelectPaymentMethodDrawerShown = true"
                @createPaymentMethod="isCreatePaymentMethodDrawerShown = true"
            />
        </Drawer>
        <Drawer
            :is-shown="isCreatePaymentMethodDrawerShown"
            bg-class="bg-white-noise bg-white"
            :is-back-shown="true"
            :is-close-shown="true"
            @close="SHOW_DRAWER(null)"
            @back="isCreatePaymentMethodDrawerShown = false"
        >
            <CreatePaymentMethod
                @success="isYourCartSummaryDrawerShown = true"
            />
        </Drawer>
        <Drawer
            :is-shown="isSelectPaymentMethodDrawerShown"
            bg-class="bg-white-noise bg-white"
            :is-back-shown="true"
            :is-close-shown="true"
            :back-tab-index="3"
            :close-tab-index="4"
            @close="SHOW_DRAWER(null)"
            @back="
                isSelectPaymentMethodDrawerShown = false;
                isSelectAddressDrawerShown = true;
            "
        >
            <ChoosePaymentMethod
                @select="isYourCartSummaryDrawerShown = true"
                @add="isCreatePaymentMethodDrawerShown = true"
            />
        </Drawer>
        <Drawer
            :is-shown="isYourCartSummaryDrawerShown"
            bg-class="bg-white-noise bg-white"
            :is-back-shown="true"
            :is-close-shown="true"
            :back-tab-index="8"
            :close-tab-index="9"
            @close="SHOW_DRAWER(null)"
            @back="isYourCartSummaryDrawerShown = false"
        >
            <YourCartSummary />
        </Drawer>
    </div>
</template>

<script setup>
    import { ref } from 'vue';
    import { computed } from 'vue';
    import { onMounted } from '@vue/runtime-core';
    import { mapGetters, mapMutations, mapActions } from 'root-js/store/lib.js';
    import { readableDecimalNumber } from 'root-js/helpers/number.helper';

    import Drawer from 'root-js/components/common/Drawer.vue';
    import BillingDetailsCard from 'root-js/components/core/BillingDetailsCard.vue';
    import InputQuantity from 'root-js/components/common/InputQuantity.vue';
    import CompleteProfile from 'root-js/components/your-space/CompleteProfile.vue';
    import ProductList from 'root-js/components/your-space/ProductList.vue';
    import SelectAddress from 'root-js/components/your-space/SelectAddress.vue';
    import ChoosePaymentMethod from 'root-js/components/your-space/ChoosePaymentMethod.vue';
    import CreatePaymentMethod from 'root-js/components/your-space/CreatePaymentMethod.vue';
    import YourCartSummary from 'root-js/components/your-space/YourCartSummary.vue';
    import AddAddress from 'root-js/components/address-book/AddAddress.vue';
    import ProductDetails from 'root-js/components/your-space/ProductDetails.vue';
    import { useGtag } from 'vue-gtag-next';

    const { cartBoxes } = mapGetters('store');
    const { user: currentUser } = mapGetters('user');
    const { addresses } = mapGetters('addresses');
    const { paymentMethods } = mapGetters('payments');
    const { SHOW_DRAWER } = mapMutations('drawers');
    const { SET_CART_PRODUCT } = mapMutations('store');
    const { GET_USER } = mapActions('user');
    const { products } = mapGetters('store');

    const isProductListDrawerShown = ref(false);
    const isCompleteProfileShown = ref(false);
    const isAddAddressDrawerShown = ref(false);
    const isSelectAddressDrawerShown = ref(false);
    const isCreatePaymentMethodDrawerShown = ref(false);
    const isSelectPaymentMethodDrawerShown = ref(false);
    const isYourCartSummaryDrawerShown = ref(false);

    const { event } = useGtag();

    const shownCartBoxes = computed(() => cartBoxes.value.filter(box => !!+box.quantity));

    const totalPrice = computed(() => cartBoxes.value.reduce((acc, cur) => {
        const total = cur.plans[0].price * cur.quantity;
        return acc + total;
    }, 0));

    onMounted(() => {
        GET_USER();
    });


    function onCheckoutClicked() {

        // Send GA event for initiating checkout
        event('begin_checkout', {
            currency: 'USD',
            value: readableDecimalNumber(totalPrice.value),
            items: shownCartBoxes.value.map(box => {
                return {
                    item_id: box.id,
                    item_name: box.name,
                    price: readableDecimalNumber(box.plans[0].price),
                    quantity: box.quantity
                };
            })
        });

        if (
            !currentUser.value
            || !currentUser.value.email
            || !currentUser.value.first_name
        ) {
            isCompleteProfileShown.value = true;
            return;
        } else if (!addresses.value.length) {
            isAddAddressDrawerShown.value = true;
            return;
        }
        isSelectAddressDrawerShown.value = true;
    }

    function onSuccessCreateAddress() {
        if (!paymentMethods.value.length) {
            isCreatePaymentMethodDrawerShown.value = true;
            isAddAddressDrawerShown.value = false;

            return;
        }

        isSelectPaymentMethodDrawerShown.value = true;
        isSelectAddressDrawerShown.value = true;

        setTimeout(() => {
            isAddAddressDrawerShown.value = false;
        }, 500);
    }

    function onSuccessCompleteProfile() {
        if (addresses.value.length) {
            isSelectAddressDrawerShown.value = true;
            return;
        }

        isAddAddressDrawerShown.value = true;
    }

    function toggleProductListDrawer() {

        // GET_PRODUCTS();
        isProductListDrawerShown.value = !isProductListDrawerShown.value;
    }


    function removeFromCart(cartBox) {

        // Send GA event for removing product from cart
        event('remove_from_cart', {
            currency: 'USD',
            value: readableDecimalNumber(cartBox.plans[0].price * cartBox.quantity),
            items: [ {
                item_id: cartBox.id,
                item_name: cartBox.name,
                price: readableDecimalNumber(cartBox.plans[0].price),
                quantity: cartBox.quantity
            } ]
        });

        // Set the product quantity to 0 to remove it from the cart
        SET_CART_PRODUCT({ ...cartBox, quantity: 0 });
    }

    function updateCartQuantity(cartBox, newQuantity, oldQuantity) {
        if (newQuantity > oldQuantity) {

            // Send GA event for adding product to cart
            event('add_to_cart', {
                currency: 'USD',
                value: readableDecimalNumber(cartBox.plans[0].price * (newQuantity - oldQuantity)),
                items: [ {
                    item_id: cartBox.id,
                    item_name: cartBox.name,
                    price: readableDecimalNumber(cartBox.plans[0].price),
                    quantity: newQuantity - oldQuantity
                } ]
            });
        } else if (newQuantity < oldQuantity) {

            // Send GA event for removing product from cart
            event('remove_from_cart', {
                currency: 'USD',
                value: readableDecimalNumber(cartBox.plans[0].price * (oldQuantity - newQuantity)),
                items: [ {
                    item_id: cartBox.id,
                    item_name: cartBox.name,
                    price: readableDecimalNumber(cartBox.plans[0].price),
                    quantity: oldQuantity - newQuantity
                } ]
            });
        }

        // Update the product quantity in the cart
        SET_CART_PRODUCT({ ...cartBox, quantity: newQuantity });
    }
</script>
