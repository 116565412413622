<template>
    <div
        id="request-box"
        class="h-full flex flex-col"
    >
        <div class="flex flex-col justify-center px-6 py-8 md:py-8">
            <div class="flex justify-center items-center mb-14">
                <p class="text-navy text-[18px] items-center text-center">
                    Request Box
                </p>
            </div>
            <div class="flex relative items-center">
                <div>
                    <p class="text-navy text-[28px] w-[286px]">
                        Where are we sending your box?
                    </p>
                    <p class="text-navy text-[14px]">
                        Choose an address
                    </p>
                </div>

                <img
                    class="absolute -right-[50px] -top-[20px] w-[140px] md:-right-[60px]"
                    :src="require('/static/assets/images/misc/clouds-grey.svg')"
                    alt="Clouds Grey"
                >
            </div>
        </div>
        <div class="px-6 mb-16">
            <BoxDetailsCard :box="props.box" />
            <SelectDropdown
                class="w-full"
                :value="selectedDeliveryAddress?.label"
                :options="addressOptions"
                placeholder="🏠 Choose an address"
                @update:value="(e) => SET_DELIVERY_ADDRESS(e.value)"
            />
        </div>

        <div class="flex mt-auto items-center justify-center px-4 md:px-0">
            <button
                class="btn-primary h-[65px] mb-8 md:w-[360px]"
                :tabindex="1"
                data-message="Continue Button"
                :disabled="isContinueButtonDisabled"
                @click="isReviewRequestShown = true"
            >
                Continue
            </button>
        </div>
        <Drawer
            :is-shown="isReviewRequestShown"
            bg-class="bg-white-noise bg-white"
            :is-back-shown="true"
            :is-close-shown="true"
            :close-tab-index="6"
            :back-tab-index="5"
            @close="SHOW_DRAWER(null)"
            @back="isReviewRequestShown = false"
        >
            <ReviewRequest :box="selectedBox" />
        </Drawer>
    </div>
</template>

<script setup>
    import { ref } from '@vue/reactivity';
    import { onMounted, computed } from '@vue/runtime-core';
    import { mapGetters, mapActions, mapMutations } from 'root-js/store/lib.js';

    import SelectDropdown from 'root-js/components/common/SelectDropdown.vue';
    import BoxDetailsCard from 'root-js/components/core/BoxDetailsCard.vue';
    import Drawer from 'root-js/components/common/Drawer.vue';
    import ReviewRequest from 'root-js/components/your-space/ReviewRequest.vue';

    const props = defineProps({ box: Object });

    const isReviewRequestShown = ref(false);

    const { addresses } = mapGetters('addresses');
    const { selectedDeliveryAddress } = mapGetters('boxes');
    const { selectedBox } = mapGetters('boxes');
    const { SHOW_DRAWER } = mapMutations('drawers');
    const { SET_DELIVERY_ADDRESS } = mapMutations('boxes');
    const { GET_ADDRESSES } = mapActions('addresses');

    const addressOptions = computed(() => addresses.value.map(address => {
        return {
            displayText: address.label,
            value: address
        };
    }));

    const isContinueButtonDisabled = computed(() => !selectedDeliveryAddress.value);

    onMounted(() => {
        GET_ADDRESSES();
    });
</script>
