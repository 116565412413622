<template>
    <form
        class="w-full"
        autocomplete="off"
        novalidate
        data-rewardful="true"
        @submit.prevent="submit"
        @keydown="
            form.resetValidation('general');
            form.resetValidation($event.target.name);
        "
        @keyup.enter.exact="submit"
    >
        <h2 class="text-[22px] xs:text-[28px] text-navy mb-2">
            Enter the verification code
        </h2>

        <p
            v-if="form.errors.has('general') || errorMessage"
            class="text-[14px] text-flushing-pink font-medium mb-6"
        >
            {{ form.errors.get('general') || errorMessage }}
        </p>
        <p
            v-else
            class="text-[14px] text-navy mb-6"
        >
            You will have received a 5 digit code to
            <span class="font-medium">{{ phoneDetails.completeNumber }}</span>
        </p>
        <div class="flex gap-2 mb-2 xs:mb-4">
            <input
                v-for="(otp, index) in otps"
                :key="index"
                :ref="
                    (el) => {
                        if (el) otpInput[index] = el;
                    }
                "
                v-model="otps[index]"
                name="code"
                class="bg-transparent text-navy text-center max-w-full w-[54px] h-[45px] rounded-[25px] border outline-none px-[20px] mb-4 placeholder:text-light-bite disabled:bg-unknown-grey-500 disabled:text-unknown-grey-600 disabled:border-unknown-grey-400 focus:border-navy xs:w-[60px] xs:h-[50px] xs:py-[10px] xs:px-[23px] xs:text-[18px]"
                :class="{
                    'border-unknown-grey-400': !otp && !form.errors.has('code'),
                    'border-navy': otp && !form.errors.has('code'),
                    'border-flushing-pink text-flushing-pink':
                        form.errors.has('code')
                }"
                maxlength="1"
                type="text"
                inputmode="numeric"
                placeholder="0"
                :disabled="isLoading"
                @keypress="isNumber($event)"
                @keydown="onKeydown(otps[index])"
                @keyup="onKeyup($event)"
            >
        </div>

        <div class="flex h-[55px] w-[300px] mb-6 xs:w-[270px] xs:h-[65px]">
            <button
                v-if="!isLoading"
                ref="verifyButton"
                type="submit"
                class="btn-primary h-full mb-2"
                :disabled="!isOtpFilled"
            >
                Verify
            </button>
            <img
                v-else
                class="animate-spin mx-auto my-auto w-[30px] h-[30px]"
                :src="
                    require('static/assets/images/misc/icons-spinners-i-os--black.svg')
                "
                alt="Icon Spinner"
            >
        </div>

        <ResendOtp @error="onResendOtpError" />
    </form>
</template>

<script setup>
    import { reactive, ref } from '@vue/reactivity';
    import { computed, onMounted, watch } from '@vue/runtime-core';
    import { mapMutations, mapGetters } from 'root-js/store/lib.js';

    import Form from 'root-js/utils/API/Form';
    import ResendOtp from 'root-js/components/welcome/ResendOtp.vue';
    import { useGtag } from 'vue-gtag-next';


    const emits = defineEmits(['completeProfile', 'finish']);
    const props = defineProps({ mode: String });

    const { SET_AUTH } = mapMutations('auth');
    const { SET_USER } = mapMutations('user');

    const { phoneDetails } = mapGetters('auth');


    const isLoading = ref(false);
    const verifyButton = ref(null);
    const otpInput = ref([]);
    const otps = ref([null, null, null, null, null]);
    const storeValue = ref(null);
    const errorMessage = ref('');
    const { event } = useGtag();


    const form = reactive(new Form('verify-otp',
                                   {
                                       id: { value: '', rule: 'required' },
                                       phone: { value: '', rule: 'required' },
                                       code: { value: '', rule: 'required' },
                                       referral: { value: '' }
                                   },
                                   null));

    const isOtpFilled = computed(() => otps.value.every(otp => !!otp));

    watch(() => otps.value,
          () => {
              form.code = otps.value.join('');
          },
          { deep: true });

    watch(() => phoneDetails,
          () => {
              form.id = phoneDetails.value.id;
              form.phone = phoneDetails.value.completeNumber;
          },
          { immediate: true, deep: true });

    onMounted(() => {
        otpInput.value[0].focus();
        if (window.Rewardful && window.Rewardful.Forms) {
            window.Rewardful.Forms.attach();
        }
    });

    function onResendOtpError(errors) {
        if (!errors) {
            errorMessage.value = '';
            return;
        }
        errorMessage.value = errors.general[0];
    }

    function onKeydown(value) {
        storeValue.value = value;
    }

    function onKeyup($event) {
        if ($event.keyCode === 9 || $event.keyCode === 13) {
            return;
        }
        if ($event.keyCode === 8 && storeValue.value) {
            return;
        }
        if ($event.keyCode === 8 && !storeValue.value) {
            $event.target.previousElementSibling?.focus();
            storeValue.value = null;
            return;
        }

        $event.target.nextElementSibling?.focus();
    }

    function isNumber($event) {
        const keyCode = $event.keyCode ? $event.keyCode : $event.which;
        if (keyCode < 48 || keyCode > 57) {
            $event.preventDefault();
        }
    }

    async function submit() {
        isLoading.value = true;

        // Extract the referral ID if available
        const referralInput = document.querySelector('input[name="referral"]');
        const referral = referralInput ? referralInput.value : null;

        // Include referral ID in the form data if available
        form.referral = referral;

        try {
            const { data: tokenDetails } = await form.submit(true);
            SET_AUTH(tokenDetails);

            // Send GA event for successful OTP verification
            event('login', { method: 'SMS' });

            checkUser();
        } catch (error) {
            setTimeout(() => {
                otpInput.value[otpInput.value.length - 1].focus();
            }, 0);
            isLoading.value = false;
            if (error.response.status === 403) {
                emits('back');
            }
        }
    }

    async function checkUser() {
        try {
            const response = await window.api.call('get-self');
            const userDetails = response.data.data;
            SET_USER(userDetails);


            if (userDetails.email && userDetails.first_name) {
                emits('finish');
                return;
            }

            emits('completeProfile');
        } catch (error) {
            emits('completeProfile'); // Success otp but user is not created
        }

        isLoading.value = false;
    }
</script>
