<template>
    <div
        class="bg-blue-noise-1 h-screen pt-14 pb-8 hidden flex-col lg:flex"
        :class="{
            'w-[100px] xl:w-[320px]': isSidebarExpand,
            'w-[100px]': !isSidebarExpand
        }"
    >
        <template v-if="isSidebarExpand">
            <img
                class="h-[50px] mx-auto mb-16 hidden xl:block"
                :src="require('static/assets/images/brand/logo-white.svg')"
                alt="Space Logo"
            >
            <img
                class="h-[50px] mx-auto mb-16 block xl:hidden"
                :src="
                    require('static/assets/images/brand/logo-white--mobile.svg')
                "
                alt="Space Logo"
            >
        </template>
        <img
            v-else
            class="h-[50px] mx-auto mb-16"
            :src="require('static/assets/images/brand/logo-white--mobile.svg')"
            alt="Space Logo"
        >

        <button
            class="absolute hidden top-1/2 right-0 -translate-y-1/2 bg-navy w-[20px] h-[54px] flex items-center justify-center hover:opacity-80 xl:block"
            @click="toggleSidebar"
        >
            <i
                class="icon text-white text-[12px]"
                :class="{
                    'icon-chevron-left': isSidebarExpand,
                    'icon-chevron-right': !isSidebarExpand
                }"
            />
        </button>

        <router-link
            v-for="menu in menus"
            :key="menu.name"
            v-slot="{ href, navigate, isActive }"
            :to="{ name: menu.name }"
            custom
        >
            <a
                class="relative text-white text-[18px] font-light w-full pl-[2.35rem] flex items-center h-[54px] my-1 group"
                :href="href"
                @click="navigate"
            >
                <span
                    v-if="isActive"
                    class="absolute top-0 left-0 bg-flushing-pink w-[8px] h-full"
                />
                <span
                    class="absolute top-1/2 -right-[130px] transform -translate-y-1/2 bg-flushing-pink w-[150px] p-[10px] rounded-xl invisible group-hover:visible text-center text-[16px]"
                    :class="{
                        'block xl:hidden': isSidebarExpand,
                        block: !isSidebarExpand
                    }"
                >
                    <i
                        class="block h-4 w-4 bg-flushing-pink absolute top-1/2 -left-1 transform -translate-y-1/2 rotate-45"
                    />
                    {{ menu.name }}
                </span>

                <div class="flex items-center gap-2">
                    <i
                        :class="{
                            [menu.icon]: true,
                            'text-white': isActive,
                            'text-light-bite': !isActive
                        }"
                        class="icon text-[24px] mr-6"
                    />

                    <span
                        v-if="isSidebarExpand"
                        class="hidden xl:block text-[18px]"
                        :class="{
                            'text-white': isActive,
                            'text-light-bite': !isActive
                        }"
                    >
                        {{ menu.name }}
                    </span>
                </div>
            </a>
        </router-link>

        <div class="mt-auto flex justify-center items-center flex-col">
            <i class="icon icon-box-solid text-[24px] text-white mb-2" />
            <p class="text-dutty-grey text-[10px] hidden xl:block">
                {{ copyrightText }}
            </p>
            <p class="text-dutty-grey text-[10px] block xl:hidden">
                © Space
            </p>
        </div>
    </div>
</template>

<script setup>
    import { defineEmits } from 'vue';
    import { computed, ref, unref } from '@vue/reactivity';

    const emit = defineEmits([ 'toggle' ]);

    const isSidebarExpand = ref(true);

    const menus = ref([
        {
            name: 'Your Space',
            icon: 'icon-box-outline'
        },
        {
            name: 'Address Book',
            icon: 'icon-contacts'
        },
        {
            name: 'Settings',
            icon: 'icon-settings'
        }
    ]);

    const copyrightText = computed(() => {
        const currentYear = (new Date).getFullYear();
        return unref(isSidebarExpand)
            ? `© ${currentYear} Space Storage, Inc.`
            : `© Space`;
    });

    function toggleSidebar() {
        isSidebarExpand.value = !isSidebarExpand.value;
        emit('toggle', isSidebarExpand.value);
    }
</script>
