<template>
    <div
        class="bg-white w-full rounded-[20px] mb-4 shadow-ambient relative"
        :class="{ 'opacity-50 pointer-events-none': props.disabled }"
    >
        <UploadBoxImage
            class="rounded-t-[26px] h-[205px] w-full object-cover object-center"
            :box="props.box"
            :disabled="isLoading"
            @update:tags="onUpdateTags"
            @loaded="$emit('loaded')"
        />

        <button
            v-if="props.isEditable"
            class="absolute h-[36px] w-[36px] bg-navy rounded-full top-[140px] right-[20px] hover:bg-opacity-90 hover: hidden"
            :tabindex="props.editBoxTabIndex"
            @click.prevent=""
        >
            <i class="icon icon icon-camera text-white text-[16px]" />
        </button>
        <div class="p-[20px]">
            <div class="flex items-center justify-between">
                <p class="text-[22px] text-navy">
                    {{ props.box.title }}
                </p>
                <button
                    v-if="props.isEditable"
                    class="icon icon-edit text-navy text-[20px] hover:opacity-90"
                    @click.prevent="isEditDetailsDrawerShown = true"
                />
            </div>

            <p class="text-[14px] text-navy w-[300px] mb-4">
                {{ props.box.description }}
            </p>

            <div class="flex flex-wrap gap-2">
                <p
                    v-for="(tag, index) in props.box.tags"
                    :key="index"
                    class="bg-transparent text-[12px] text-navy border border-dusk-stalk break-all rounded-full py-[5px] px-[11px]"
                >
                    {{ tag }}
                </p>
            </div>
        </div>

        <Drawer
            :is-shown="isEditDetailsDrawerShown"
            bg-class="bg-white-noise bg-white"
            :is-close-shown="true"
            :is-back-shown="true"
            @close="SHOW_DRAWER(null)"
            @back="isEditDetailsDrawerShown = false"
        >
            <UpdateBoxDetails
                :box="selectedBox"
                @close="SHOW_DRAWER(null)"
                @success="isEditDetailsDrawerShown = false"
            />
        </Drawer>
    </div>
</template>

<script setup>
    import { ref } from '@vue/reactivity';
    import { mapMutations, mapGetters } from 'root-js/store/lib.js';
    import { readableDate } from 'root-js/helpers/date.helper.js';

    import Drawer from 'root-js/components/common/Drawer.vue';
    import AddBoxPhoto from 'root-js/components/your-space/AddBoxPhoto.vue';
    import UpdateBoxDetails from 'root-js/components/your-space/UpdateBoxDetails.vue';
    import UploadBoxImage from 'root-js/components/core/UploadBoxImage.vue';

    const props = defineProps({
        editBoxTabIndex: Number,
        disabled: Boolean,
        box: Object,
        isEditable: Boolean,
        isLoading: Boolean,
        displayDate: String,
        displayTime: String,
        address: String
    });

    const emits = defineEmits([ 'update:tags' ]);

    const { selectedBox } = mapGetters('boxes');
    const { SHOW_DRAWER } = mapMutations('drawers');

    const isChangeImageDrawerShown = ref(false);
    const isEditDetailsDrawerShown = ref(false);

    function onUpdateTags(tags) {
        emits('update:tags', tags);
    }
</script>
