<template>
    <svg
        width="50"
        height="50"
        viewBox="0 0 50 50"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g
            stroke="currentColor"
            stroke-width="1.5"
            fill="none"
            fill-rule="evenodd"
            stroke-linecap="round"
            stroke-linejoin="round"
        >
            <path
                d="M16.276 27.8c-4.522-.286-8.32-2.753-10.076-5.506C6.544 16.33 13.155 12.2 21.8 12.2"
            />
            <path
                d="M6.026 21.8c-2.383 2.655-.502 11.838 4.467 13.464 2.345 6.321 6.923 6.097 10.72 5.386 1.229-1.272 1.414-2.356 1.787-3.59-.744-2.544-3.266-4.04-5.36-4.489.26-1.72-.493-3.957-1.721-4.967C18.445 26.92 19.72 25.327 20.6 24M23.4 37.95c3-.99 10.097-.624 12.6.95-.9 2.79-2.25 4.334-4.5 5.7-1.632 0-5.213-.159-9.9-2.85M40.942 12.2c3.765-.28 5.058-4.387 1.798-6.3-4.762-2.794-8.238 3.376-13.67.746-2.754-1.334-6.403-3.23-16.87.713"
            />
            <path
                d="M21.058 24.2h8.67c8.02 0 11.15-6.577 11.272-12H21.058c-2.456 3.27-1.95 8.846 0 12zM18 33.8c-1.406 1.122-3.15 2.945-7.2 2.244M18.2 26.6l3.6 3.6M20.6 24.2l3.6 3.6M25.4 24.2l3.6 3.6"
            />
        </g>
    </svg>
</template>
