<template>
    <div class="max-w-full w-[600px] border-t border-b border-unknown-grey-200">
        <div
            class="
				h-[5px]
				bg-flushing-pink
				rounded-r-2xl
				transition-width
				duration-100
			"
            :style="{ width: `${value}%` }"
        />
    </div>
</template>

<script>
    export default {
        props: {
            value: {
                default: 0,
                type: Number
            }
        }
    };
</script>
