<template>
    <svg
        width="50"
        height="50"
        viewBox="0 0 50 50"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g
            stroke="currentColor"
            stroke-width="1.44"
            fill="none"
            fill-rule="evenodd"
            stroke-linecap="round"
            stroke-linejoin="round"
        >
            <path
                d="m5 41.507 6.537-1.598a4.639 4.639 0 0 1 2.36.028l10.5 2.894a4.759 4.759 0 0 0 3.868-.615l17.055-11.11c-.97-2.128-3.26-3.074-5.259-2.156L33.8 32.029"
            />
            <path
                d="M20.71 35.8h10.908c1.205 0 2.182-.849 2.182-1.897s-.977-1.897-2.182-1.897h-6.545l-6.068-1.582a12.004 12.004 0 0 0-5.365-.151L5 32.006M31.935 18.037c9 2.08 11.795-2.727 9.981-11.037-5.217 1.96-13.981 2.443-9.981 11.037z"
            />
            <path
                d="M29.48 24.28c1.435-5.1 6.352-10.968 8.64-12.96M18.328 30.04c6.256-5.76 12.759-5.224 17.407-2.525l2.385 1.373M23.72 15.64c2.962 1.066 5.046 3.496 5.441 6.345l.319 2.295"
            />
        </g>
    </svg>
</template>
