/**
 * Handle all form errors.
 *
 * @author      Ben Carey <bdmc@sinemacula.co.uk>
 * @copyright   2022 Sine Macula Limited.
 */
export default class Errors {

    /**
     * Create a new Errors instance.
     */
    constructor() {
        this.errors = {};
    }

    /**
     * Determine if any errors exist for the given field.
     *
     * @param {string}  field
     */
    has(field) {
        return Object.prototype.hasOwnProperty.call(this.errors, field);
    }

    /**
     * Determine if we have any errors.
     */
    any() {
        return Object.keys(this.errors).length > 0;
    }

    /**
     * Retrieve the error message for a field.
     *
     * @param   {string}  field
     * @returns  {string|void}
     */
    get(field) {
        if (this.errors[field]) {
            return Array.isArray(this.errors[field])
                ? this.errors[field][0]
                : this.errors[field];
        }
    }

    /**
     * Record the new errors.
     *
     * @param {object}  errors
     * @param {boolean}  overwrite
     */
    record(errors, overwrite = true) {

        if (overwrite) {
            this.errors = errors;
        } else {
            this.errors = { ...this.errors, ...errors };
        }

    }

    /**
     * Clear one or all error fields.
     *
     * @param {string|array|null}  fields
     */
    clear(fields = null) {

        if (fields) {

            fields = Array.isArray(fields) ? fields : [ fields ];

            fields.forEach(item => delete this.errors[item]);

            return;

        }

        // Clear all errors
        this.errors = {};

    }
}
