<template>
    <svg
        width="50"
        height="50"
        viewBox="0 0 50 50"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g
            stroke="currentColor"
            stroke-width="1.5"
            fill="none"
            fill-rule="evenodd"
            stroke-linecap="round"
            stroke-linejoin="round"
        >
            <path
                d="M14.473 12c-.697.719-1.663 1.161-2.027 2.223-.426 1.246-.663 2.48-.172 3.745.072.183.072.343.083.512.15 2.718.516 5.41.895 8.102.208 1.475.249 2.973.424 4.453.236 1.995.548 3.98.787 5.976.08.672.125 1.356.173 2.027.11 1.538 1.323 2.873 2.843 3.48 1.214.483 2.411.459 3.64.463 2.92.01 5.84-.034 8.76.018 1.47.027 2.748-.44 3.92-1.24.966-.663 1.375-1.689 1.514-2.825.243-1.99.51-3.98.696-5.975.185-1.965.512-3.91.723-5.871.206-1.927.471-3.848.695-5.773.181-1.55.292-3.112.518-4.656.273-1.862-.487-3.2-1.925-4.254-.135-.1-.268-.202-.403-.304"
            />
            <path
                d="M24.415 12c-.122 1.195-.454 2.368-.411 3.581.038 1.124.195 2.238.63 3.277.417.998 1.134 1.743 2.123 2.319 1.103.642 2.237 1.249 3.116 2.242.768.868 1.088 1.875 1.287 2.91.545 2.827 1.873 4.164 4.84 4.671"
            />
            <path
                d="M11.778 17c-.917-.027-1.45-.642-2.144-1.13-2.09-1.468-2.973-3.534-3.329-5.838-.15-.97-.576-2.019-.05-3.032 0 0 3.375 2.458 4.739 3.203 1.746.951 3.573 1.703 5.706 1.632 3.444-.115 6.894-.031 10.342-.031 2.31 0 4.62.006 6.93-.003.69-.004 1.34-.175 2.02-.341 2.885-.704 7.62-4.46 7.62-4.46.7.837.26 1.797.158 2.642-.296 2.454-1.196 4.667-3.365 6.19-.184.13-.275.384-.409.58M38.349 17.578c.171 0 .617.08.743 0 1.885-1.236 3.545-.248 5.127.717.684.418 1.446.94 1.781 1.842-1.756 1.595-4.516 2.706-6.806 1.025-.26-.19-.886-.298-1.194-.409M9.855 17c-1.255.395-2.818.568-3.9 1.578C5.385 19.11 4 20.22 4 20.22c.658.396 1.296.84 1.98 1.177 1.534.754 3.095.85 4.633.002.353-.195.953-.446 1.387-.375"
            />
        </g>
    </svg>
</template>
