<template>
    <form
        id="add-box-photo"
        class="h-full flex flex-col"
        autocomplete="off"
        novalidate
        @submit.prevent="submit"
        @keydown="
            editForm.resetValidation('general');
            editForm.resetValidation($event.target.name);
        "
    >
        <div class="flex flex-col justify-center px-6 pt-8 md:pt-8">
            <div class="flex justify-center items-center mb-14">
                <p class="text-navy text-[18px] items-center text-center">
                    Add Box Photo
                </p>
                <p
                    v-if="editForm.errors.has('general')"
                    class="text-flushing-pink text-[18px] h-[81px]"
                    v-text="editForm.errors.get('general')"
                />
            </div>
        </div>

        <div class="mb-16 px-6">
            <BoxPhoto
                v-model:tags="editForm.tags"
                :box="props.box"
                :class="{ 'opacity-50 pointer-events-none': isLoading || isBoxPhotoLoading }"
                @loading="isBoxPhotoLoading = true"
                @loaded="onUploadSuccess"
                @delete="onDeleteSuccess"
            />

            <p
                v-for="displayError in displayErrors"
                :key="displayError"
                class="text-flushing-pink text-[10px] ml-5"
            >
                {{ displayError }}
            </p>
        </div>
        <div class="flex mt-auto items-center justify-center px-4 md:px-0">
            <div class="flex w-full h-[65px] md:w-[360px] mb-8">
                <button
                    v-if="!isLoading"
                    class="btn-primary h-full"
                    type="submit"
                    :disabled="isBoxPhotoLoading"
                >
                    {{ props.submitText }}
                </button>
                <img
                    v-else
                    class="animate-spin mx-auto my-auto w-[30px] h-[30px]"
                    :src="
                        require('static/assets/images/misc/icons-spinners-i-os--black.svg')
                    "
                    alt="Icon Spinner"
                >
            </div>
        </div>
    </form>
</template>

<script setup>
    import { ref, reactive } from '@vue/reactivity';
    import { formatBoxDetails } from '../../helpers/box.helper';
    import { mapMutations } from 'root-js/store/lib.js';

    import Form from 'root-js/utils/API/Form';
    import BoxPhoto from 'root-js/components/common/BoxPhoto.vue';
    import Drawer from 'root-js/components/common/Drawer.vue';

    const emits = defineEmits([ 'success' ]);

    const props = defineProps({
        submitText: {
            type: String,
            default: 'Done'
        },
        box: Object
    });

    const { UPDATE_BOX } = mapMutations('boxes');
    const { SHOW_DRAWER } = mapMutations('drawers');

    const isLoading = ref(false);
    const isImageChanged = ref(false);
    const isImageDeleted = ref(false);
    const isBoxPhotoLoading = ref(false);
    const metaErrors = ref('');
    const displayErrors = ref([]);
    const editForm = reactive(new Form('update-box',
                                       {
                                           id: { value: props.box.id },
                                           tags: { value: props.box.tags }
                                       },
                                       null,
                                       { success: () => emits('success') }));

    function onUploadSuccess() {
        isBoxPhotoLoading.value = false;
        isImageChanged.value = true;
    }

    function onDeleteSuccess() {
        isImageDeleted.value = true;
    }

    async function submit() {
        if (
            editForm.tags === props.box.tags
            && !isImageDeleted.value
            && !isImageChanged.value
        ) {
            emits('success');
            return;
        }

        isLoading.value = true;

        try {
            const { data: boxDetails } = await editForm.submit();

            UPDATE_BOX(formatBoxDetails(boxDetails));
        } catch (error) {
            const metaErrors = error.response.data.error.meta;

            if (!metaErrors) {
                return;
            }

            displayErrors.value = Object.values(metaErrors).flatMap(err => err);
        }

        isLoading.value = false;
    }
</script>
