export const isNumber = $event => {
    const keyCode = $event.keyCode ? $event.keyCode : $event.which;
    if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
    }
};

export const readableNumber = (value,
    maximumFractionDigits,
    minimumFractionDigits) => {
    if (!value || Number.isNaN(+value) || !isFinite(+value)) {
        return Number(0).toFixed(minimumFractionDigits);
    }

    return new Intl.NumberFormat('en', {
        minimumFractionDigits,
        maximumFractionDigits: maximumFractionDigits || 2
    }).format(+value);
};

export const decimalNumber = (num, decimals = -2) => num * 10 ** decimals;

export const readableDecimalNumber = (num,
    decimals = -2,
    maximumFractionDigits,
    minimumFractionDigits) => {
    const dn = decimalNumber(num, decimals);

    return readableNumber(dn, maximumFractionDigits, minimumFractionDigits);
};

export const readableBalance = num => -readableDecimalNumber(num);
