<template>
    <div class="px-6">
        <Input
            id="name"
            :value="props.name"
            placeholder="Box name"
            class="mb-4"
            :disabled="disabled"
            :tabindex="props.inputTabIndex"
            @update:value="(e) => $emit('update:name', e)"
        />

        <textarea
            id="description"
            :value="props.description"
            :disabled="disabled"
            class="
				bg-white
				py-[16px]
				px-[26px]
				rounded-[20px]
				w-full
				h-[353px]
				placeholder:text-bruised-ego
				text-[14px] text-navy
				outline-none
				resize-none
				transition
				duration-75
				ease-linear
				shadow-input
				focus:shadow-md
				md:w-[360px]
				disabled:bg-slate-50
				disabled:border-slate-300
				disabled:text-slate-300
				disabled:placeholder-slate-300
				disabled:shadow-none
			"
            placeholder="Description (e.g. kids clothes)"
            :tabindex="props.textAreaTabIndex"
            @input="(e) => $emit('update:description', e.target.value)"
        />
    </div>
</template>

<script setup>
    import Input from 'root-js/components/common/Input.vue';

    const emits = defineEmits(['update:name', 'update:description']);

    const props = defineProps({
        inputTabIndex: Number,
        textAreaTabIndex: Number,
        name: String,
        description: String,
        disabled: Boolean
    });
</script>
