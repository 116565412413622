<template>
    <div class="flex flex-col">
        <div class="w-full mb-4 relative shadow-ambient bg-white rounded-[26px]">
            <UploadBoxImage
                :box="props.box"
                :tags="props.tags"
                @update:tags="onUpdateTags"
                @loading="$emit('loading')"
                @loaded="$emit('loaded')"
            />

            <div class="p-[20px]">
                <p class="text-[22px] text-navy">
                    {{ props.box.title }}
                </p>

                <p class="text-[14px] text-navy mb-4">
                    {{ props.box.description }}
                </p>
                <div class="flex flex-wrap gap-2 group">
                    <button
                        v-for="tag in props.tags"
                        :key="tag"
                        class="
							flex
							items-center
							justify-center
							gap-1
							break-all
							bg-transparent
							text-[12px] text-navy
							border border-dusk-stalk
							rounded-full
							py-[5px]
							px-[11px]
							hover:bg-navy hover:text-white
						"
                        @click="onClickTagRemove(tag)"
                    >
                        <span
                            class="
								rounded-full
								text-white
								bg-flushing-pink
								w-[13px]
								h-[13px]
								flex
								items-center
								justify-center
							"
                        >
                            <i class="icon icon-cross" />
                        </span>
                        {{ tag }}
                    </button>
                </div>
            </div>
        </div>
        <div class="flex items-center justify-between space-x-4 w-full">
            <div class="flex items-center relative group w-[300px] xs:[w-328px] mb-3">
                <input
                    v-model="inputTag"
                    placeholder="Add a tag to continue"
                    type="text"
                    class="
						flex
						group
						bg-white
						w-[300px]
						py-[16px]
						px-[26px]
						rounded-[20px]
						space-x-2
						text-[14px] text-left text-navy
						transition
						duration-75
						ease-linear
						placeholder:text-bruised-ego
						shadow-input
						outline-none
						focus:shadow-md
						xs:[w-328px]
						disabled:bg-slate-50
						disabled:border-slate-300
						disabled:text-slate-300
						disabled:placeholder-slate-300
						disabled:shadow-none
					"
                    @keyup.enter="onAddTag"
                >
            </div>
            <button
                class="
					h-[25px]
					w-[25px]
					bg-flushing-pink
					rounded-full
					hover:bg-bloody-nose
					disabled:border
					disabled:border-navy
					disabled:bg-navy
					disabled:opacity-20
				"
                @click.prevent="onAddTag"
            >
                <i class="icon icon-plus text-white text-[14px]" />
            </button>
        </div>
    </div>
</template>

<script setup>
    import { ref } from '@vue/reactivity';

    import UploadBoxImage from 'root-js/components/core/UploadBoxImage.vue';


    const emits = defineEmits(['update:tags', 'update:image', 'loading', 'loaded']);

    const props = defineProps({
        box: Object,
        tags: Array
    });

    const tempImageUrl = ref(null);
    const inputTag = ref('');

    function onAddTag() {
        if (!inputTag.value) {
            return;
        }

        const tags = props.tags.map(tag => tag.toLowerCase());

        if (tags.includes(inputTag.value.toLowerCase())) {
            inputTag.value = '';
            return;
        }

        emits('update:tags', [...props.tags, inputTag.value]);
        inputTag.value = '';
    }

    function onClickTagRemove(str) {
        emits('update:tags',
              props.tags.filter(tag => tag !== str));
    }

    function onUpdateTags(tags) {
        emits('update:tags', tags);
    }
</script>
