/**
 * Initiate the Vuex instance and import each of the modules used by the store
 * throughout the application.
 */
import { createStore } from 'vuex';
import auth from './modules/auth';
import user from './modules/user';
import addresses from './modules/addresses';
import store from './modules/store';
import boxes from './modules/boxes';
import drawers from './modules/drawers';
import payments from './modules/payments';
import orders from './modules/orders';

export default createStore({
    modules: {
        auth,
        user,
        addresses,
        store,
        boxes,
        drawers,
        payments,
        orders
    },
    strict: process.env.NODE_ENV !== 'production'
});
