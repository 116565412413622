<template>
    <div class="bg-white-noise no-repeat h-screen w-screen overflow-auto">
        <TopNavbar
            class="fixed top-0 left-0 z-20 ]"
            :class="{
                'lg:pl-[100px] xl:pl-[320px]': isExpanded,
                'pl-[100px]': !isExpanded
            }"
        />
        <Sidebar
            class="fixed top-0 left-0 z-30"
            @toggle="onSidebarToggle"
        />
        <main
            class="flex h-screen pt-0 md:pt-[80px] z-1"
            :class="{
                'lg:pl-[100px] xl:pl-[320px]': isExpanded,
                'pl-[100px]': !isExpanded
            }"
        >
            <slot />
        </main>
        <BottomNavbar class="block fixed bottom-0 lg:hidden" />
        <AppDrawers />
    </div>
</template>

<script setup>
    import { ref } from '@vue/reactivity';
    import { mapGetters } from 'root-js/store/lib.js';
    import { onBeforeMount } from '@vue/runtime-core';
    import Sidebar from 'root-js/components/core/Sidebar.vue';
    import TopNavbar from 'root-js/components/core/TopNavbar.vue';
    import BottomNavbar from 'root-js/components/core/BottomNavbar.vue';
    import AppDrawers from 'root-js/components/core/AppDrawers.vue';
    import router from 'root-js/routes.js';

    const { isAuthenticated } = mapGetters('auth');

    const isExpanded = ref(true);

    function onSidebarToggle(bool) {
        isExpanded.value = bool;
    }

    onBeforeMount(() => {
        if (!isAuthenticated.value) {
            router.push({ name: 'Welcome' });
        }
    });
</script>
