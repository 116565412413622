import API from 'root-js/utils/API';
import EndpointCollection from 'root-js/utils/API/EndpointCollection';
import ServiceProvider from './ServiceProvider';
import bus from 'root-js/services/BusService';
import config from '~/config';

/**
 * Create the API singleton.
 *
 * Initiate the API instance to handle all calls to the REST API. Each endpoint
 * is defined in endpoints.json, and then imported into a collection which is
 * passed to the API class. Whilst you can call a custom endpoint using the API
 * class, it is recommended to define all endpoints in the endpoints.json file.
 *
 * @author      Ben Carey <bdmc@sinemacula.co.uk>
 * @copyright   2022 Sine Macula Limited.
 */
class APIService extends ServiceProvider {

    /**
     * Create the service instance.
     *
     * @return {Object}
     */
    createInstance() {
        const endpoints = new EndpointCollection(require('root-js/utils/API/endpoints.json'));
        const token = localStorage.getItem('token') || null;

        return new API(config.app.urls.api, endpoints, token, {

            // Unauthorized
            401: error => {
                bus.emit('refresh-token', error);
            }
        });
    }
}

const api = (new APIService).getInstance();

export default api;
