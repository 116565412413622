<template>
    <form
        class="w-full"
        autocomplete="off"
        novalidate
        @submit.prevent="submit"
        @keydown="
            form.resetValidation('general');
            form.resetValidation($event.target.name);
        "
        @keyup.enter.exact="submit"
    >
        <h2 class="text-[22px] sm:text-[28px] text-navy mb-2">
            Enter your mobile number
        </h2>

        <p
            v-if="form.errors.has('general')"
            class="text-[14px] text-flushing-pink font-medium mb-6"
        >
            {{ form.errors.get('general') }}
        </p>
        <p
            v-else
            class="text-[14px] text-navy mb-6"
        >
            We’ll send you a verification code
        </p>
        <div class="flex space-x-2 items-center mb-6">
            <CountryDropdown
                v-model:selected="selectedCountry"
                :options="countries"
                :disabled="isLoading"
            />
            <div class="relative mr-2">
                <input
                    id="phone"
                    ref="mobileNumberInput"
                    name="phone"
                    :value="phoneFormatted"
                    placeholder="Mobile number"
                    class="bg-transparent border max-w-full w-[192px] h-[44px] rounded-[25px] transition duration-75 ease-linear px-5 mr-2 outline-none placeholder:text-light-bite disabled:bg-unknown-grey-500 disabled:text-unknown-grey-600 xs:text-[18px] sm:w-[310px] sm:h-[50px]"
                    :disabled="isLoading"
                    :class="{
                        'text-navy border-unknown-grey-400 focus:border-navy':
                            !form.phone && !form.errors.has('phone'),
                        'text-navy border-navy focus:border-navy':
                            form.phone && !form.errors.has('phone'),
                        'border-flushing-pink text-flushing-pink':
                            form.errors.has('phone')
                    }"
                    inputmode="numeric"
                    @input="(e) => onPhoneInput(e.target.value)"
                    @keypress="isNumber($event)"
                >
                <span
                    v-if="form.errors.has('phone')"
                    class="absolute top-[3.25rem] left-0 text-flushing-pink text-[10px] px-5"
                    v-text="form.errors.get('phone')"
                />
            </div>
            <button
                type="submit"
                class="hidden justify-center items-center w-[55px] h-[55px] p-[10px] rounded-full bg-flushy-pink-gradient shadow-ambient transition duration-75 ease-linear hover:bg-gradient-to-r hover:from-bloody-nose hover:to-bloody-nose disabled:bg-flushy-pink-gradient disabled:opacity-50 sm:flex"
                :disabled="!form.phone || isLoading"
            >
                <i
                    v-if="!isLoading"
                    class="icon icon-arrow-right text-white text-[27px]"
                />
                <img
                    v-else
                    class="animate-spin"
                    :src="
                        require('static/assets/images/misc/icons-spinners-i-os.svg')
                    "
                    alt="Icon Spinner"
                >
            </button>
        </div>
        <button
            type="submit"
            class="btn-primary flex justify-center items-center max-w-full w-[300px] h-[55px] mb-8 sm:hidden"
            :disabled="!form.phone || isLoading"
        >
            <p v-if="!isLoading">
                Continue
            </p>
            <img
                v-else
                class="animate-spin"
                :src="
                    require('static/assets/images/misc/icons-spinners-i-os.svg')
                "
                alt="Icon Spinner"
            >
        </button>
        <Popper
            arrow
            hover
        >
            <template #content>
                <p class="text-[12px] text-navy w-[233px] text-center">
                    We use your mobile number to uniquely identify your account
                    and keep it secure.
                </p>
            </template>
            <div class="flex items-center gap-2">
                <i
                    class="icon icon-info-circle-outline text-navy text-[22px]"
                />
                <p class="text-navy text-[12px]">
                    Why do I need to enter this?
                </p>
            </div>
        </Popper>
    </form>
</template>

<script setup>
    import { reactive, ref } from '@vue/reactivity';
    import { onMounted, watch } from '@vue/runtime-core';
    import { mapMutations, mapGetters } from 'root-js/store/lib.js';
    import { AsYouType, parsePhoneNumberFromString } from 'libphonenumber-js';
    import { isNumber } from 'root-js/helpers/number.helper';

    import Form from 'root-js/utils/API/Form';
    import Popper from 'vue3-popper';
    import CountryDropdown from 'root-js/components/core/CountryDropdown.vue';
    import { WHITELISTED_COUNTRIES } from 'root-js/constants/authentication.const';
    import { useGtag } from 'vue-gtag-next';

    const emits = defineEmits([ 'success' ]);

    const { SET_PHONE_DETAILS } = mapMutations('auth');
    const { device, os } = mapGetters('auth');

    const mobileNumberInput = ref(null);
    const phoneFormatted = ref('');
    const isLoading = ref(false);
    const countries = ref(WHITELISTED_COUNTRIES);
    const selectedCountry = ref(countries.value[0]);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const source = urlParams.get('source');
    const code = urlParams.get('code');
    const sourceDetail = code ? JSON.stringify({ code }) : null;
    const { event } = useGtag();

    const form = reactive(new Form('send-otp',
                                   {
                                       phone: { value: '', rule: 'required' },
                                       country_code: { value: '', rule: 'required' },
                                       uid: { value: device, rule: 'required' },
                                       os: { value: os, rule: 'required' },
                                       source: { value: source },
                                       source_detail: { value: sourceDetail }
                                   },
                                   null,
                                   { success: () => emits('success') }));

    watch(() => selectedCountry.value,
          () => {
              form.country_code = selectedCountry.value.value;
          },
          { immediate: true });

    onMounted(() => {
        mobileNumberInput.value.focus();
    });

    async function submit() {
        isLoading.value = true;
        mobileNumberInput.value.blur();

        try {
            const { data: otpDetails } = await form.submit(true);

            SET_PHONE_DETAILS({
                id: otpDetails.id,
                number: form.phone,
                countryCode: form.country_code,
                completeNumber: otpDetails.phone.phone
            });

            // Send GA event for successful mobile number submission
            event('mobile_number_entered', { country_code: form.country_code });

        } catch (error) {
            setTimeout(() => {
                mobileNumberInput.value.focus();
            }, 0);
        }

        isLoading.value = false;
    }

    function onPhoneInput(phone) {
        phoneFormatted.value = new AsYouType(selectedCountry.value.value).input(phone);

        form.phone = parsePhoneNumberFromString(phone,
                                                selectedCountry.value.value)?.nationalNumber;
    }
</script>
