<template>
    <div class="transition duration-75 ease-linear group">
        <button
            class="
				transition
				duration-75
				ease-linear
				max-w-full
				w-[298px]
				h-[43px]
				gap-2
				border
				rounded-[25px]
				flex
				items-center
				cursor-pointer
				px-[22px]
				py-1
				mb-6
				disabled:cursor-not-allowed
				disabled:border-opacity-50
				disabled:bg-unknown-grey-500
				disabled:text-light-bite
				group-hover:border-navy
				xs:w-[310px] xs:h-[49px] xs:mb-0
			"
            :class="{
                'text-unknown-grey-400 border-unknown-grey-400': !isHasSelected,
                'text-navy border-navy': isHasSelected
            }"
            :disabled="props.disabled"
        >
            {{ displayedText }}
            <i class="icon icon-chevron-down text-navy text-[12px] ml-auto" />
        </button>

        <div
            v-if="!props.disabled && !isUserSelect"
            class="fixed z-10 hidden group-hover:block"
        >
            <div class="bg-white border border-navy text-navy rounded-[25px] mt-2">
                <div class="flex flex-col py-3">
                    <div
                        v-for="option in options"
                        :key="option"
                        class="
							cursor-pointer
							whitespace-nowrapdisplayErrors
							px-6
							py-2
							hover:bg-navy-5
						"
                        @click="onSelect(option)"
                    >
                        {{ option.display }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref } from '@vue/reactivity';
    import { computed } from '@vue/runtime-core';

    const emits = defineEmits([ 'update:selected' ]);

    const props = defineProps({
        selected: Object,
        options: Array,
        disabled: Boolean
    });

    const isUserSelect = ref(false);

    const displayedText = computed(() => props.selected ? props.selected.display : 'Select Unit');

    const isHasSelected = computed(() => !!props.selected);

    function onSelect(value) {
        isUserSelect.value = true;
        emits('update:selected', value);

        setTimeout(() => {
            isUserSelect.value = false;
        }, 300);
    }
</script>
