<template>
    <div class="relative group">
        <div
            class="relative"
            @click="onInputClicked"
        >
            <input
                ref="inputDropdown"
                :placeholder="props.placeholder"
                type="text"
                class="flex group bg-white py-[16px] pl-[26px] pr-[36px] rounded-[20px] space-x-2 text-[14px] text-left text-navy transition duration-75 ease-linear placeholder:text-bruised-ego shadow-input outline-none w-full focus:shadow-md disabled:bg-slate-50 disabled:border-slate-300 disabled:text-slate-300 disabled:placeholder-slate-300 disabled:shadow-none"
                :class="props.inputClass"
                :value="displayedInputValue?.displayText"
                :disabled="props.disabled"
                :readonly="props.isInputReadOnly"
                @input="onValueUpdate"
            >
            <i
                class="icon icon-chevron-down text-navy text-[12px] absolute top-[20px] right-[20px] duration-75 ease-linear cursor-pointer"
            />
        </div>

        <div
            v-if="isOptionsShown && shownOptions.length"
            v-click-outside="hideOptions"
            class="absolute w-full z-10"
        >
            <div
                class="bg-white text-[14px] text-left text-navy w-full min-w-max max-h-[150px] overflow-y-auto rounded-[20px] mt-2"
            >
                <div class="flex flex-col py-3">
                    <li
                        v-for="option in shownOptions"
                        :key="option"
                        class="hover:bg-gray-100 cursor-pointer list-none whitespace-nowrap py-3 px-[26px]"
                        @click="onOptionClicked(option)"
                    >
                        {{ option.displayText }}
                    </li>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref } from '@vue/reactivity';
    import { computed, watch } from '@vue/runtime-core';

    const emits = defineEmits([ 'update:value' ]);

    const props = defineProps({
        placeholder: String,
        readonly: Boolean,
        disabled: Boolean,
        inputClass: Object,
        isInputReadOnly: Boolean,
        options: {
            type: Array,
            default: () => []
        },
        value: {
            type: [Object, String],
            default: ''
        }
    });

    const inputDropdown = ref(null);
    const isOptionsShown = ref(false);
    const selectedOption = ref(null);

    const displayedInputValue = computed(() => selectedOption.value || { displayText: props.value });

    const isOptionsObject = computed(() => props.options.length && typeof props.options[0] === 'object');

    const formattedOptions = computed(() => {
        if (isOptionsObject.value) {
            return props.options;
        }

        return props.options.map(option => {
            return {
                displayText: option,
                value: option
            };
        });
    });

    const shownOptions = computed(() => {
        if (props.isInputReadOnly) {
            return formattedOptions.value;
        }

        return formattedOptions.value.filter(option => {
            const optionLowerCase = option.displayText.toLowerCase();
            const inputLowerCase
                = displayedInputValue.value.displayText.toLowerCase();

            return optionLowerCase.includes(inputLowerCase);
        });
    });

    watch(() => props.value,
          () => {
              selectedOption.value = { displayText: props.value };
          });

    function showOptions() {
        isOptionsShown.value = true;
    }

    function hideOptions() {
        isOptionsShown.value = false;
    }

    function onInputClicked() {
        showOptions();
        inputDropdown.value.focus();
    }

    function onValueUpdate($event) {
        const result = {
            displayText: $event.target.value,
            value: $event.target.value
        };

        if (!isOptionsObject.value) {
            emits('update:value', result.value);
        } else {
            emits('update:value', result);
        }

        selectedOption.value = result;
    }

    function onOptionClicked(option) {
        if (!isOptionsObject.value) {
            emits('update:value', option.value);
        } else {
            emits('update:value', option);
        }

        selectedOption.value = option;

        hideOptions();
    }
</script>
