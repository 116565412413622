<template>
    <svg
        width="50"
        height="50"
        viewBox="0 0 50 50"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g
            stroke="currentColor"
            fill="none"
            fill-rule="evenodd"
            stroke-linecap="round"
            stroke-linejoin="round"
        >
            <path
                d="M26.048 33.912c1.502-1.538 12.59-12.849 15.754-16.165 1.393-1.46 2.132-3.251 2.194-5.298.061-2.043-.54-3.859-1.829-5.441-1.333-1.637-3.073-2.626-5.15-2.917-2.152-.302-4.166.137-5.947 1.453-.474.35-.935.706-1.347 1.129C26.63 9.849 12.473 24.3 9.368 27.547c-2.756 2.882-3.155 7.027-.94 10.31.97 1.439 3.935 3.376 5.28 3.12"
                stroke-width="1.5"
            />
            <path
                d="M39 9.669c-.68-.714-1.43-1.314-2.413-1.545-1.199-.283-2.31-.081-3.342.595-.333.218-.608.499-.882.785C31.245 10.672 30.12 11.835 29 13"
            />
            <path
                d="m28 40-14-2M26.019 43.88a7 7 0 1 1-10.038-9.76 7 7 0 0 1 10.038 9.76z"
                stroke-width="1.5"
            />
        </g>
    </svg>
</template>
