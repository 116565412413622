<template>
    <div
        class="
			relative
			bg-blue-noise
			h-screen
			w-full
			flex flex-col
			justify-center
			items-center
		"
    >
        <slot />
    </div>
</template>

<script setup>
    import { onBeforeMount } from '@vue/runtime-core';
    import { mapGetters } from 'root-js/store/lib.js';
    import router from 'root-js/routes.js';

    const { isAuthenticated } = mapGetters('auth');

    onBeforeMount(() => {
        if (isAuthenticated.value) {
            router.push({ name: 'Your Space' });
        }
    });
</script>
