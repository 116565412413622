<template>
    <div
        id="start-packing"
        class="h-full flex flex-col"
    >
        <BoxIntro
            :box="props.box"
            :address="props.box.tracking?.destinationAddress"
            @changeImage="$emit('changeImage')"
        />
        <div class="px-[21px]">
            <div class="flex gap-2 items-center mb-4">
                <div>
                    <i class="icon icon-van scale-x-[-1] text-navy text-[15px]" />
                </div>
                <div class="w-10/12 bg-flushing-pink h-[6px] rounded-full" />
            </div>
        </div>
        <div class="flex flex-col items-center mt-auto p-[21px]">
            <button
                class="btn-primary h-[65px] mb-2"
                :tabindex="1"
                data-message="Start Packing Button"
                @click="onStartPackingClicked"
            >
                Start Packing
            </button>

            <div class="text-center">
                <span class="text-[12px] text-navy">
                    No longer need your box?
                    <button
                        class="text-flushing-pink font-medium hover:text-bloody-nose"
                        :tabindex="2"
                        data-message="Cancel Subscription Button"
                        @click="isCancelSubscriptionDrawerShown = true"
                    >
                        Cancel subscription
                    </button>
                </span>
            </div>
        </div>
        <Drawer
            :is-shown="isTermsAndConditionDrawerShown"
            bg-class="bg-white-noise bg-white"
            :is-back-shown="true"
            :is-close-shown="true"
            :close-tab-index="3"
            :back-tab-index="2"
            @close="SHOW_DRAWER(null)"
            @back="isTermsAndConditionDrawerShown = false"
        >
            <TermsAndCondition @agree="isPackDetailsDrawerShown = true" />
        </Drawer>
        <Drawer
            :is-shown="isCancelSubscriptionDrawerShown"
            bg-class="bg-white-noise bg-white"
            :is-back-shown="true"
            :is-close-shown="true"
            :back-tab-index="3"
            :close-tab-index="4"
            @close="SHOW_DRAWER(null)"
            @back="isCancelSubscriptionDrawerShown = false"
        >
            <CancelSubscription
                :box="props.box"
                @close="isCancelSubscriptionDrawerShown = false"
            />
        </Drawer>

        <Drawer
            :is-shown="isPackDetailsDrawerShown"
            bg-class="bg-white-noise bg-white"
            :is-back-shown="true"
            :is-close-shown="true"
            :close-tab-index="5"
            :back-tab-index="4"
            @close="SHOW_DRAWER(null)"
            @back="isPackDetailsDrawerShown = false"
        >
            <PackDetails :box="props.box" />
        </Drawer>
    </div>
</template>

<script setup>
    import { ref } from 'vue';
    import { mapActions, mapMutations, mapGetters } from 'root-js/store/lib.js';

    import Drawer from 'root-js/components/common/Drawer.vue';
    import BoxIntro from 'root-js/components/your-space/BoxIntro.vue';
    import CancelSubscription from 'root-js/components/your-space/CancelSubscription.vue';
    import TermsAndCondition from 'root-js/components/your-space/TermsAndCondition.vue';
    import PackDetails from 'root-js/components/your-space/PackDetails.vue';

    const props = defineProps({ box: Object });

    const { storeShipping } = mapGetters('boxes');
    const { SHOW_DRAWER } = mapMutations('drawers');
    const { UPDATE_BOX_BY_ID } = mapActions('boxes');

    const isCancelSubscriptionDrawerShown = ref(false);
    const isPackDetailsDrawerShown = ref(false);
    const isTermsAndConditionDrawerShown = ref(false);

    function onStartPackingClicked() {
        if (props.box.packingStatus !== 'packing') {
            UPDATE_BOX_BY_ID({
                id: props.box.id,
                packing_status: 'packing'
            });
        }

        isTermsAndConditionDrawerShown.value = true;
    }
</script>
