<template>
    <div class="p-3 grid place-items-center">
        <span
            class="flex items-center space-x-3 select-none text-white bg-navy rounded-3xl p-2.5"
        >
            <span class="text-center">{{ cMonth }} {{ year }}</span>
        </span>
    </div>
</template>

<script setup>
    import dayjs from 'dayjs';
    import { computed } from 'vue';
    import Year from './Year.vue';

    const props = defineProps({
        modelValue: {
            type: Number,
            default: dayjs().month()
        },
        year: { type: Number }
    });

    const emit = defineEmits([ 'update:modelValue' ]);

    const cMonth = computed(() => dayjs().month(props.modelValue)
        .format('MMMM'));
</script>
