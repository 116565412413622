<template>
    <div
        id="pickup-location"
        class="h-full flex flex-col"
    >
        <div class="flex flex-col justify-center px-6 py-8 md:py-8">
            <div class="flex justify-center items-center mb-14">
                <p class="text-navy text-[18px] items-center text-center">
                    Pickup Location
                </p>
            </div>
            <div class="flex relative items-center">
                <div>
                    <p class="text-navy text-[28px] w-[286px]">
                        What’s your pickup location
                    </p>
                    <p class="text-navy text-[14px]">
                        Choose an address for the pickup
                    </p>
                </div>

                <img
                    class="absolute -right-[50px] -top-[20px] w-[140px] md:-right-[60px]"
                    :src="require('/static/assets/images/misc/clouds-grey.svg')"
                    alt="Clouds Grey"
                >
            </div>
        </div>
        <div class="px-6 mb-16">
            <BoxDetailsCard
                :box="props.box"
                :is-editable="true"
                :edit-box-tab-index="2"
            />
            <SelectDropdown
                class="w-full"
                :value="selectedDeliveryAddress?.label"
                :options="addressOptions"
                placeholder="🏠 Choose an address"
                @update:value="(e) => onSelectAddress(e.value)"
            />
            <div
                v-if="pickupDetails.dates && selectedDeliveryAddress"
                class="mt-2"
            >
                <div
                    class="flex justify-between items-center group bg-white py-[16px] pl-[26px] pr-[36px] rounded-[20px] space-x-2 text-[14px] text-left text-navy transition duration-75 ease-linear placeholder:text-bruised-ego shadow-input outline-none w-full focus:shadow-md disabled:bg-slate-50 disabled:border-slate-300 disabled:text-slate-300 disabled:placeholder-slate-300 disabled:shadow-none"
                    @click="toggleCalendar"
                >
                    <span :class="{ 'text-bruised-ego': !selectedDateValue }">{{
                        dateDisplayText
                    }}</span>
                    <i
                        class="icon icon-calendar text-navy text-[14px] cursor-pointer"
                    />
                </div>
                <Calendar
                    v-show="isCalendarVisible"
                    :selected-date-value="
                        selectedDateValue
                    "
                    :selected-values="selectedValues"
                    :current-active-date-values="currentActiveDateValues"
                    @update:selectedDateValue="updateSelectedDate"
                    @update:selectedValues="updateSelectedValues"
                    @toggle:calendar="toggleCalendar"
                />
            </div>
        </div>

        <div class="flex mt-auto items-center justify-center px-4 md:px-0">
            <button
                class="btn-primary h-[65px] mb-8 md:w-[360px]"
                :tabindex="1"
                data-message="Continue Button"
                :disabled="!selectedDeliveryAddress || !selectedDateValue"
                @click="isPickUpReviewShown = true"
            >
                Continue
            </button>
        </div>
        <Drawer
            :is-shown="isPickUpReviewShown"
            bg-class="bg-white-noise bg-white"
            :is-back-shown="true"
            :is-close-shown="true"
            :close-tab-index="6"
            :back-tab-index="5"
            @close="SHOW_DRAWER(null)"
            @back="togglePickUpReview"
        >
            <PickupReview
                :box="selectedBox"
                :back="togglePickUpReview"
            />
        </Drawer>
    </div>

</template>

<script setup>
    import dayjs from 'dayjs';
    import { ref, computed, watch, onUnmounted } from 'vue';
    import { onMounted } from '@vue/runtime-core';
    import { mapGetters, mapActions, mapMutations } from 'root-js/store/lib.js';

    import BoxDetailsCard from 'root-js/components/core/BoxDetailsCard.vue';
    import SelectDropdown from 'root-js/components/common/SelectDropdown.vue';
    import PickupReview from 'root-js/components/your-space/PickupReview.vue';
    import Drawer from 'root-js/components/common/Drawer.vue';
    import Calendar from 'root-js/components/calendar/index.vue';

    const props = defineProps({ box: Object });

    const { selectedDeliveryAddress } = mapGetters('boxes');
    const { addresses } = mapGetters('addresses');
    const { selectedBox, pickupDetails, pickupDate } = mapGetters('boxes');
    const { SET_DELIVERY_ADDRESS, SET_PICKUP_DATE } = mapMutations('boxes');
    const { SHOW_DRAWER } = mapMutations('drawers');
    const { GET_ADDRESSES } = mapActions('addresses');
    const { GET_PICKUP_DETAILS_BY_ADDRESS_ID } = mapActions('boxes');

    const isPickUpReviewShown = ref(false);
    const isCalendarVisible = ref(false);

    onMounted(() => {
        GET_ADDRESSES();
    });

    const addressOptions = computed(() => addresses.value.map(address => {
        return {
            displayText: address.label,
            value: address
        };
    }));


    function onSelectAddress(address) {
        SET_DELIVERY_ADDRESS(address);
        GET_PICKUP_DETAILS_BY_ADDRESS_ID(address.id);
    }


    const selectedDateValue = ref(null);
    const selectedValues = ref({
        month: null,
        year: null
    });


    watch(() => pickupDetails, newVal => {
        if (newVal.value && newVal.value.dates) {
            const validDates = Object.keys(newVal.value.dates).map(date => dayjs(date));
            if (validDates.length > 0) {
                selectedDateValue.value = validDates[0].date(); // Set to the first valid date
                selectedValues.value.month = validDates[0].month(); // Set month from the first valid date
                selectedValues.value.year = validDates[0].year(); // Set year from the first valid date
                const formattedDate = dayjs(`${selectedValues.value.year}-${selectedValues.value.month + 1}-${selectedDateValue.value}`).format('YYYY-MM-DD');
                SET_PICKUP_DATE(formattedDate);
            }
        }
    }, { immediate: true, deep: true });


    const currentActiveDateValues = ref({
        month: selectedValues.value.month,
        year: selectedValues.value.year
    });

    watch(() => pickupDate, newVal => {
        const date = dayjs(newVal.value);
        currentActiveDateValues.value.month = date.month();
        currentActiveDateValues.value.year = date.year();

    }, { immediate: true, deep: true });

    const userSelectedDate = ref(false);

    const dateDisplayText = computed(() => {
        if (pickupDate.value && dayjs(pickupDate.value).isValid()) {
            const date = dayjs(pickupDate.value);

            // Get the full month name
            const month = date.format('MMMM');
            const year = date.format('YYYY');
            const dayOfMonth = date.date();

            // Function to get the ordinal suffix
            const getOrdinalSuffix = day => {
                if (day > 3 && day < 21) {
                    return 'th';
                }
                switch (day % 10) {
                    case 1: return 'st';
                    case 2: return 'nd';
                    case 3: return 'rd';
                    default: return 'th';
                }
            };


            return `${month} ${dayOfMonth}${getOrdinalSuffix(dayOfMonth)}, ${year}`;
        }
        return 'Select the date';
    });

    function updateSelectedDate(value) {
        selectedDateValue.value = value;
        userSelectedDate.value = true;
        isCalendarVisible.value = false; // Close the calendar when a date is selected
        currentActiveDateValues.value = {
            month: selectedValues.value.month,
            year: selectedValues.value.year
        };
        const formattedDate = dayjs(`${selectedValues.value.year}-${selectedValues.value.month + 1}-${value}`).format('YYYY-MM-DD');
        SET_PICKUP_DATE(formattedDate);
    }

    function updateSelectedValues(values) {
        selectedValues.value = values;
    }

    function toggleCalendar() {
        isCalendarVisible.value = !isCalendarVisible.value;
    }

    function togglePickUpReview() {
        isPickUpReviewShown.value = !isPickUpReviewShown.value;
    }
</script>
