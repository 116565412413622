export const DRAWERS = {
    HELP_CENTER: 'HELP_CENTER',
    PRODUCT_LIST: 'PRODUCT_LIST',
    PRODUCT_DETAIL: 'PRODUCT_DETAIL',
    PRODUCT_CART: 'PRODUCT_CART',
    ADD_DELIVERY_ADDRESS: 'ADD_DELIVERY_ADDRESS',
    SELECT_DELIVERY_ADDRESS: 'SELECT_DELIVERY_ADDRESS',
    REQUEST_BOX: 'REQUEST_BOX',
    TRACK_ARRIVING: 'TRACK_ARRIVING',
    TRACK_REQUEST: 'TRACK_REQUEST',
    TRACK_SENDING: 'TRACK_SENDING',
    SEND_TO_SPACE: 'SEND_TO_SPACE',
    CONTINUE_PACKING: 'CONTINUE_PACKING',
    START_PACKING: 'START_PACKING',
    ADD_PAYMENT_METHOD: 'ADD_PAYMENT_METHOD'
};
