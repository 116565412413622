import ServiceProvider from './ServiceProvider';
import Mitt from 'mitt';

/**
 * Create the Bus singleton.
 *
 * @author      Ben Carey <bdmc@sinemacula.co.uk>
 * @copyright   2022 Sine Macula Limited.
 */
class BusService extends ServiceProvider {

    /**
     * Create the service instance.
     *
     * @return {Object}
     */
    createInstance() {
        return new Mitt;
    }

}

const bus = (new BusService).getInstance();

export default bus;
