module.exports = {
    /*
     *|-------------------------------------------------------------------------
     *| Company Configuration
     *|-------------------------------------------------------------------------
     *|
     *| Define the information associated with the company, and the social
     *| channels of the company.
     *|
     */

    name: 'Space Storage, Inc.',

    app: {
        itunes: {
            id: '1552624570',
            url: 'https://apps.apple.com/us/app/space-storage-simplified/id1552624570',
        },
    },

    social: {
        facebook: { url: 'https://www.facebook.com/ReimagineSpace' },
        twitter: {
            url: 'https://twitter.com/ReimagineSpace',
            username: '@ReimagineSpace',
        },
        instagram: { url: 'https://www.instagram.com/ReimagineSpace' },
        pinterest: { url: 'https://www.pinterest.com/reimaginespace' },
        tiktok: { url: 'https://www.tiktok.com/@reimaginespace' },
    },
};
