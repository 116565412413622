<template>
    <svg
        width="50"
        height="50"
        viewBox="0 0 50 50"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g
            stroke="currentColor"
            stroke-width="1.44"
            fill="none"
            fill-rule="evenodd"
            stroke-linecap="round"
            stroke-linejoin="round"
        >
            <path
                d="M15.97 8.83c-.93-.08-1.919.71-1.94 1.747-.011.566-.028 1.133-.028 1.7C14 22.387 14 32.5 14.002 42.612c0 .465-.007.947.219 1.372.382.716 1.047 1.014 1.877 1.014 5.821 0 11.642.003 17.464-.002 1.533-.002 2.145-.862 2.144-2.06-.007-10.693-.004-21.384-.004-32.076 0-1.006-.467-1.709-1.33-1.934-.298-.078-18.105.042-18.402-.098"
            />
            <path
                d="M30.882 8.687V5.37c0-.95-.398-1.33-1.45-1.363-.425-.014-.852-.003-1.278-.003-2.345 0-4.69.003-7.034-.002-.514 0-.969.103-1.277.51-.193.257-.216.557-.216.858v3.454M28.47 17.667c-2.577 3.534-7.235 9.752-7.235 9.752.619.387 6.679-.24 7.236.062l-6.536 10.284"
            />
        </g>
    </svg>
</template>

