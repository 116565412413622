<template>
    <GuestLayout
        role="main"
        class="overflow-x-hidden w-screen min-h-screen h-full"
    >
        <button
            class="
				absolute
				top-6
				right-6
				bg-transparent
				rounded-full
				text-white
				font-medium
				border border-white
				text-[13.3px]
				w-[85px]
				h-[30px]
				transition
				duration-75
				ease-linear
				hover:bg-white hover:text-navy
				mb-2
			"
            @click="onSignInClick"
        >
            Sign In
        </button>

        <div class="flex flex-col items-center mt-28 pb-20 md:pb-10 md:my-auto">
            <img
                class="w-[200px]"
                :src="require('static/assets/images/brand/logo-white.svg')"
                title=""
                alt="Your Space Logo"
            >
            <p class="text-white">
                Your space, reimagined
            </p>
            <img
                class="w-[1500px] ml-[94px] max-w-none mb-6 md:mb-8"
                :src="require('/static/assets/images/misc/figures-boxes.png')"
                alt="Figures Boxes"
            >

            <p class="text-[18px] text-white mb-2">
                Start making space
            </p>

            <button
                class="btn-primary font-normal flex-shrink-0 w-[285px] h-[53px] mb-2"
                @click="onCreateAccountClick"
            >
                Get Started
            </button>

            <p class="text-[10px] text-dutty-grey">
                By creating an account, you are agreeing to our
            </p>

            <a
                href="https://spacereimagined.io/terms"
                target="_blank"
                rel="noopener noreferrer"
                class="text-[10px] text-dutty-grey font-medium mb-6 md:mb-8"
            >
                Terms of Service
            </a>

        </div>
        <div
            class="
				flex flex-col
				items-center
				absolute
				bottom-4
				left-1/2
				transform
				-translate-x-1/2
			"
        >
            <div class="flex space-x-6">
                <a
                    v-for="(social, index) in socials"
                    :key="index"
                    :href="social.url"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text-[14px] text-white hover:opacity-90"
                >
                    <i :class="[ social.icon ]" />
                </a>
            </div>

            <p class="text-[10px] text-dutty-grey text-center mt-4">
                © {{ new Date().getFullYear() }} Space Storage, Inc.
            </p>
        </div>


        <Modal
            v-model:isShown="isSignInShown"
            :title="signInTitle"
            :is-close-shown="steps.indexOf(currentStep[selectedMode]) === 0"
            :is-back-shown="isModalBackShown"
            :step-percentage="stepPercentage"
            @close="onSignInClose"
            @back="prevStep"
        >
            <transition name="fade">
                <EnterMobileNumberForm
                    v-if="currentStep[selectedMode] === 'enterMobileNumber'"
                    @success="nextStep"
                />
            </transition>
            <transition name="fade">
                <OtpForm
                    v-if="currentStep[selectedMode] === 'otp'"
                    :mode="selectedMode"
                    @completeProfile="nextStep"
                    @finish="onFinish"
                    @back="prevStep"
                />
            </transition>
            <transition name="fade">
                <ProfileForm
                    v-if="currentStep[selectedMode] === 'profileForm'"
                    @success="nextStep"
                />
            </transition>
        </Modal>
    </GuestLayout>
</template>

<script setup>
    import { computed, reactive, ref } from '@vue/reactivity';
    import { useMeta } from 'vue-meta';

    import router from 'root-js/routes.js';

    import usePreloadRoute from 'root-js/plugins/VuePreloader/composables/usePreloadRoute';

    import GuestLayout from 'root-js/layouts/GuestLayout.vue';

    import Modal from 'root-js/components/common/Modal.vue';
    import EnterMobileNumberForm from 'root-js/components/welcome/EnterMobileNumberForm.vue';
    import SelectUnitForm from 'root-js/components/welcome/SelectUnitForm.vue';
    import PartnerCodeForm from 'root-js/components/welcome/PartnerCodeForm.vue';
    import ProfileForm from 'root-js/components/welcome/ProfileForm.vue';
    import OtpForm from 'root-js/components/welcome/OtpForm.vue';
    import { SOCIALS } from 'root-js/constants/temp.const.js';
    import { useGtag } from 'vue-gtag-next';

    useMeta({ title: 'Space / Welcome' });

    // Preload the route
    usePreloadRoute();
    const { event } = useGtag();

    const stepsPerMode = {
        create: ['enterMobileNumber', 'otp', 'profileForm'],
        signin: ['enterMobileNumber', 'otp', 'profileForm']
    };

    const selectedMode = ref('create');
    const currentStep = reactive({
        create: 'enterMobileNumber',
        signin: 'enterMobileNumber'
    });

    const socials = ref(SOCIALS);

    const steps = computed(() => stepsPerMode[selectedMode.value]);

    const stepPercentage = computed(() => {
        if (selectedMode.value !== 'create') {
            return false;
        }

        const indexOfCurrentStep = steps.value.indexOf(currentStep[selectedMode.value]);
        return (indexOfCurrentStep + 1) / steps.value.length * 100;
    });

    const isModalBackShown = computed(() => steps.value.indexOf(currentStep[selectedMode.value]) > 0
        && currentStep[selectedMode.value] !== 'profileForm');

    const isModalShown = ref(false);
    const isPartnerCodeSkipped = ref(false);
    const isSignInShown = ref(false);
    const transition = ref('slide');

    const signInTitle = computed(() => selectedMode.value === 'create' ? 'Create Your Account' : 'Sign In');

    function onCreateAccountClick() {
        isSignInShown.value = true;
        selectedMode.value = 'create';

        // Send GA event for clicking "Get Started"
        event('get_started_button_clicked');
    }

    function onSignInClick() {
        selectedMode.value = 'signin';
        isSignInShown.value = true;

        // Send GA event for clicking "Sign In"
        event('sign_in_button_clicked');
    }

    function onSignInClose() {
        isSignInShown.value = false;
    }

    function onFinish() {
        isSignInShown.value = false;
        router.push({ name: 'Your Space' });
    }

    function onSkipPartnerCode() {
        transition.value = 'slide';

        isPartnerCodeSkipped.value = true;
        currentStep[selectedMode.value] = 'enterMobileNumber';
    }

    function nextStep() {
        transition.value = 'slide';

        const indexOfCurrentStep = steps.value.indexOf(currentStep[selectedMode.value]);

        if (indexOfCurrentStep + 1 >= steps.value.length) {
            isSignInShown.value = false;
            router.push({ name: 'Your Space' });
            return;
        }

        currentStep[selectedMode.value] = steps.value[indexOfCurrentStep + 1];
    }

    function prevStep() {
        transition.value = 'slide-back';

        const indexOfCurrentStep = steps.value.indexOf(currentStep[selectedMode.value]);

        if (
            isPartnerCodeSkipped.value
            && steps.value[indexOfCurrentStep - 1] === 'selectUnit'
        ) {
            currentStep[selectedMode.value] = 'partnerCode';
            return;
        }

        currentStep[selectedMode.value] = steps.value[indexOfCurrentStep - 1];
    }
</script>
