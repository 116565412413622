<template>
    <div
        ref="paymentElement"
        class="px-6 mb-6"
    />

    <div
        v-if="false"
        class="flex flex-col items-center justify-center mb-16 px-4"
    >
        <div
            class="
				flex
				bg-white
				rounded-[20px]
				w-full
				mb-4
				transition
				duration-75
				ease-linear
				shadow-input
				focus:shadow-md
				md:w-[360px]
			"
        >
            <div class="border-r border-dawn-stalk">
                <i
                    class="
						icon icon-credit-card-outline
						text-[20px] text-flushing-pink
						pl-[26px]
						p-[16px]
					"
                />
            </div>
            <div ref="cardNumber" />
        </div>

        <!-- <Input
            id="card_name"
            v-model:value="cardName"
            name="card_name"
            type="text"
            placeholder="Name on card"
            :disabled="isLoading"
            class="mb-4"
            /> -->

        <div class="flex space-x-4 w-full items-center justify-center mb-4">
            <div ref="cardExpiry" />
            <div ref="cardCvc" />
        </div>
        <Input
            id="card_zip_code"
            v-model:value="cardZipCode"
            name="card_zip_code"
            placeholder="Registered card zip code"
            :disabled="isLoading"
        />
    </div>
</template>

<script setup>
    import { ref } from '@vue/reactivity';
    import { stripe } from 'root-js/services/StripeService';
    import { onMounted } from 'vue';
    import { mapActions, mapGetters } from 'root-js/store/lib.js';

    import Input from 'root-js/components/common/Input.vue';

    const { GET_CLIENT_SECRET } = mapActions('payments');
    const { clientSecret } = mapGetters('payments');

    const isLoading = ref(false);
    const elements = ref(null);
    const cardNumber = ref(null);
    const cardCvc = ref(null);
    const cardExpiry = ref(null);
    const cardName = ref(null);
    const cardZipCode = ref(null);
    const paymentElement = ref(null);

    onMounted(async () => {
        await GET_CLIENT_SECRET();
        await stripe.createCardElements(clientSecret.value.client_secret);

        /*
         * Stripe.cardNumberElement.mount(cardNumber.value);
         * Stripe.cardCvcElement.mount(cardCvc.value);
         * Stripe.cardExpiryElement.mount(cardExpiry.value);
         */
        stripe.paymentElement.mount(paymentElement.value);
    });
</script>

