import { BOX_STATE, BOX_SUB_STATE } from 'root-js/constants/space.const.js';

export const PRODUCTS = [
    {
        title: 'Space Box',
        description:
			'The standard Space box, ideal for clothes, books and decorations. View it to scale or add to cart.',
        size: '24 x 18.5 x 14 inch',
        weight: '50 lbs max',
        price: 15,
        image: require('static/assets/images/misc/product-box.png')
    }
];

export const ADDRESSES = [
    {
        icon: '🏠',
        title: 'Home',
        address: '22 Gadsden St Charleston, SC 29401'
    },
    {
        icon: '🌴',
        title: 'Vacation',
        address: '12 Miller Street Hinesville, GA 31313'
    },
    {
        icon: '🧑‍💻',
        title: 'Work',
        address: '9 Morris Street Westerville, OH 43081'
    },
    {
        icon: '👥',
        title: 'Friend',
        address: '736 S. Oak Valley Lane Deer Park, NY 11729'
    }
];

export const CART_BOXES = [
    {
        title: 'Space Box',
        description:
			'The standard Space box, ideal for clothes, books and decorations. View it to scale or add to cart.',
        size: '24 x 18.5 x 14 inch',
        weight: '50 lbs max',
        price: 15,
        image: require('static/assets/images/misc/product-box.png'),
        quantity: 2
    },
    {
        title: 'Space Box',
        description:
			'The standard Space box, ideal for clothes, books and decorations. View it to scale or add to cart.',
        size: '24 x 18.5 x 14 inch',
        weight: '50 lbs max',
        price: 15,
        image: require('static/assets/images/misc/product-box.png'),
        quantity: 1
    }
];

export const BOXES = [
    {
        id: '41d1028e-c14b-4a7b-9a58-9ce097a5a991',
        badgeText: 'Coming to you',
        image: require('static/assets/images/misc/hero.png'),
        title: 'New Box',
        description: 'Imagine the possibilities…',
        state: BOX_STATE.TRACK,
        subState: BOX_SUB_STATE.ARRIVING_BOX,
        date: '4th December 2021 at 13:01',
        address: '1234 Main St, Los Angeles, CA, 90123',
        tags: []
    },
    {
        id: '41d1028e-c14b-4a7b-9a58-9ce097a5a993',
        badgeText: 'Coming to you',
        image: require('static/assets/images/misc/tees.png'),
        title: 'Tees',
        description: 'All my high school t-shirts',
        state: BOX_STATE.TRACK,
        subState: BOX_SUB_STATE.ARRIVING_REQUEST,
        date: '4th December 2021',
        address: '1234 Main St, Los Angeles, CA, 90123 at 13:01',
        tags: ['T-shirts', 'H&M', 'Ralph Lauren', 'Colours']
    },
    {
        id: '41d1028e-c14b-4a7b-9a58-9ce097a5a996',
        badgeText: 'Arrived',
        image: require('static/assets/images/misc/hero.png'),
        title: 'New Box',
        description: 'Make a start by packing your box',
        state: BOX_STATE.START_PACKING,
        date: '4th December 2021 at 13:01',
        address: '1234 Main St, Los Angeles, CA, 90123',
        tags: []
    },
    {
        id: '41d1028e-c14b-4a7b-9a58-9ce097a5a995',
        badgeText: 'Arrived',
        image: require('static/assets/images/misc/seasonal-clothes.png'),
        title: 'Seasonal clothes',
        description: 'Jeans and t-shirts for summertime ',
        state: BOX_STATE.SEND_TO_SPACE,
        date: '4th December 2021 at 13:01',
        address: '1234 Main St, Los Angeles, CA, 90123',
        tags: [
            'T-shirts',
            'Jeans',
            'Leggings',
            'Boots',
            'Crop-tops',
            'Pants',
            'Tops'
        ]
    },
    {
        id: '41d1028e-c14b-4a7b-9a58-9ce097a5a997',
        badgeText: 'Headed to Space',
        image: require('static/assets/images/misc/shoes.png'),
        title: 'Shoes',
        description:
			'My trainer collection and formal shoes. I have an unrivalled collection of shoes, with around 2000 pairs spanning over two decades. ',
        state: BOX_STATE.TRACK,
        subState: BOX_SUB_STATE.SENDING,
        date: '4th December 2021 at 13:01',
        address: 'In transit to Space',
        tags: [
            'Trainers',
            'Adidas',
            'Heels',
            'Crocs',
            'Tennis shoes',
            'Ballet slippers',
            'Reebok'
        ]
    },
    {
        id: '41d1028e-c14b-4a7b-9a58-9ce097a5a998',
        badgeText: 'In Space',
        image: require('static/assets/images/misc/holiday-decorations.png'),
        title: 'Holiday Decorations',
        description:
			'Holiday decorations from the living room. All decorations for the tree, and the mantlepiece.',
        state: BOX_STATE.REQUEST,
        date: '4th December 2021 at 13:01',
        address: 'In Space',
        tags: [
            'Decorations',
            'Christmas',
            'Festive',
            'Baubles',
            'Fairy lights',
            'Tree stand'
        ]
    }
];
export const SOCIALS = [
    {
        icon: 'icon icon-instagram',
        url: 'https://www.instagram.com/ReimagineSpace'
    },
    {
        icon: 'icon icon-facebook',
        url: 'https://www.facebook.com/ReimagineSpace'
    },
    {
        icon: 'icon icon-pinterest',
        url: 'https://www.pinterest.com/reimaginespace'
    },
    {
        icon: 'icon icon-twitter',
        url: 'https://twitter.com/ReimagineSpace'
    },
    {
        icon: 'icon icon-tiktok',
        url: 'https://www.tiktok.com/@reimaginespace'
    }
];
