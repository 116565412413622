<template>
    <div
        id="track-sending"
        class="h-full flex flex-col"
    >
        <BoxIntro
            :box="props.box"
            :address="props.box.tracking?.destinationAddress"
            :is-editable="true"
            @editDetails="$emit('editDetails')"
            @changeImage="$emit('changeImage')"
        />

        <div class="px-[21px]">
            <p class="text-navy text-[14px] mb-4">
                {{ props.box.description }}
            </p>
            <div class="flex flex-wrap gap-2">
                <p
                    v-for="(tag, index) in props.box.tags"
                    :key="index"
                    class="
						bg-transparent
						text-[12px] text-navy
						border border-dusk-stalk
						rounded-full
						break-all
						py-[5px]
						px-[11px]
					"
                >
                    {{ tag }}
                </p>
            </div>
        </div>


        <div class="flex flex-col justify-between items-center mt-auto p-[21px]">
            <button
                v-if="props?.box?.tracking?.tracking_url"
                class="btn-secondary h-[65px] mb-2"
                tabindex="1"
                data-message="Track"
                @click="handleTrack(props.box)"
            >
                Track
            </button>
            <div class="flex justify-between items-center w-full">
                <p class="text-[12px] text-navy">
                    Last updated: {{ props.box.dateUpdated }}
                </p>
                <i class="icon icon-info-circle-outline text-navy text-[21px]" />
            </div>

        </div>
    </div>
</template>

<script setup>
    import BoxIntro from 'root-js/components/your-space/BoxIntro.vue';

    const props = defineProps({ box: Object });

    function handleTrack(box) {
        window.open(box?.tracking?.tracking_url, '_blank');

    }
</script>
