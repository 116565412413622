<template>
    <AuthenticatedLayout>
        <div class="p-4 xs:p-8 lg:p-12">
            <div class="relative flex flex-wrap sm:justify-start justify-center gap-8 md:mt-0 mt-[80px] pb-[100px]">
                <button
                    class="flex flex-col pt-[47px] px-[30px] w-[270px] h-[304px] rounded-[20px] items-center border-2 border-dashed border-flushing-pink hover:bg-duller-than-dull"
                    @click="onAddBoxClick"
                >
                    <i class="icon icon-plus-circle-outline text-flushing-pink text-[98px] mb-4" />
                    <h2 class="text-navy text-[32px] text-center">
                        Add Box
                    </h2>
                    <p class="text-navy text-[18px] text-center">
                        Order a new box from Space
                    </p>
                </button>
                <BoxCard
                    v-for="(box, index) in boxes"
                    :key="index"
                    :box="box"
                    @box-click="onClickBoxButton"
                />
            </div>
            <button
                class="fixed right-4 bottom-[80px] h-[38px] w-[38px] bg-flushy-pink-gradient rounded-full shadow-ambient flex justify-center items-center hover:bg-bloody-nose-gradient lg:bottom-8 lg:right-10"
                :tabindex="0"
                data-message="Add Box Button"
                @click="onAddBoxClick"
            >
                <i class="icon icon-plus text-white text-[19px]" />
            </button>
        </div>

        <Dialog
            v-model:isShown="isWelcomeDialogShown"
            spacing-class="py-[25px] px-[35px] sm:py-[42px] sm:px-[45px]"
        >
            <div class="flex gap-8 items-center">
                <div class="flex flex-col mb-4">
                    <h2 class="text-navy text-[28px] mb-2 hidden xs:block">
                        Welcome to Space!
                    </h2>
                    <h2 class="text-navy text-[28px] mb-2 block xs:hidden">
                        Welcome
                    </h2>

                    <p class="text-navy text-[14px]">
                        Get started by ordering your first box
                    </p>
                </div>
                <img
                    class="absolute top-[40px] -right-[390px] max-w-none hidden xs:top-[35px] xs:h-[210px] xs:-right-[315px] sm:-top-[7px] sm:h-[300px] sm:-right-[480px] xs:block"
                    :src="require('/static/assets/images/misc/figures-box.png')"
                    alt="Figures Box"
                >
            </div>
            <div class="flex flex-col w-[168px] xs:w-[220px] sm:w-[344px]">
                <button
                    class="btn-primary h-[45px] text-[16px] mb-2 xs:h-[55px] sm:h-[65px]"
                    @click="onClickGetStarted"
                >
                    Get Started
                </button>

                <button
                    class="text-navy text-[12px] text-center hover:opacity-90"
                    @click="onClickDialogCancel"
                >
                    I’ll do this later.
                    <span class="font-medium">Skip</span>
                </button>
            </div>
        </Dialog>
    </AuthenticatedLayout>
</template>

<script setup>
    import { onMounted, ref } from 'vue';
    import { useMeta } from 'vue-meta';
    import { mapActions, mapMutations, mapGetters } from 'root-js/store/lib.js';
    import { BOX_STATE, BOX_SUB_STATE } from 'root-js/constants/space.const.js';
    import { DRAWERS } from 'root-js/constants/drawers.const.js';


    import AuthenticatedLayout from 'root-js/layouts/AuthenticatedLayout';
    import Dialog from 'root-js/components/common/Dialog.vue';
    import BoxCard from 'root-js/components/your-space/BoxCard.vue';
    import { useGtag } from 'vue-gtag-next';


    useMeta({ title: 'Space / Your Space' });

    const { isAuthenticated } = mapGetters('auth');
    const { boxes } = mapGetters('boxes');
    const { isGetStartedShown } = mapGetters('store');
    const { SHOW_DRAWER } = mapMutations('drawers');
    const { SET_SELECTED_BOX, CLEAR_DELIVERY_ADDRESS } = mapMutations('boxes');
    const { SET_IS_GET_STARTED_SHOWN } = mapMutations('store');
    const { UPDATE_USER } = mapMutations('user');
    const { GET_PRODUCTS } = mapActions('store');
    const { GET_BOXES, GET_BOX_TRACKING_BY_ID } = mapActions('boxes');
    const { GET_ADDRESSES } = mapActions('addresses');
    const { GET_USER_PAYMENT_METHODS } = mapActions('payments');
    const isWelcomeDialogShown = ref(false);
    const { products } = mapGetters('store');

    const { event } = useGtag();

    onMounted(async () => {
        if (!isAuthenticated.value) {
            return;
        }

        try {
            await GET_BOXES();
            GET_USER_PAYMENT_METHODS();
        } catch (error) {
            console.log('error', error);
        }

        GET_ADDRESSES();

        checkShowWelcomeDialog();
    });

    function checkShowWelcomeDialog() {
        if (boxes.value.length || isGetStartedShown.value) {
            return;
        }

        SET_IS_GET_STARTED_SHOWN(true);
        isWelcomeDialogShown.value = true;
    }
    function onClickBoxButton(box) {
        SET_SELECTED_BOX(box.id);
        GET_BOX_TRACKING_BY_ID(box.id);

        if (
            box.state === BOX_STATE.TRACK
            && box.subState === BOX_SUB_STATE.ARRIVING_BOX
        ) {
            SHOW_DRAWER(DRAWERS.TRACK_ARRIVING);
        } else if (
            box.state === BOX_STATE.TRACK
            && box.subState === BOX_SUB_STATE.ARRIVING_REQUEST
        ) {
            SHOW_DRAWER(DRAWERS.TRACK_REQUEST);
        } else if (
            box.state === BOX_STATE.TRACK
            && box.subState === BOX_SUB_STATE.SENDING
        ) {
            SHOW_DRAWER(DRAWERS.TRACK_SENDING);
        } else if (box.state === BOX_STATE.SEND_TO_SPACE) {
            SHOW_DRAWER(DRAWERS.SEND_TO_SPACE);
        } else if (box.state === BOX_STATE.START_PACKING) {
            SHOW_DRAWER(DRAWERS.START_PACKING);
        } else if (box.state === BOX_STATE.CONTINUE_PACKING) {
            SHOW_DRAWER(DRAWERS.CONTINUE_PACKING);
        } else if (box.state === BOX_STATE.REQUEST) {
            SHOW_DRAWER(DRAWERS.REQUEST);
        }

        CLEAR_DELIVERY_ADDRESS();
    }

    function onClickDialogCancel() {
        isWelcomeDialogShown.value = false;
    }

    async function onAddBoxClick() {

        await GET_PRODUCTS();

        // Send GA event for clicking Add Box button
        event('view_item_list', {
            item_list_id: 'add_box',
            item_list_name: 'add_box',
            items: [ ...products.value ]
        });


        if (products.value.length > 1) {
            SHOW_DRAWER(DRAWERS.PRODUCT_LIST);
        } else {
            SHOW_DRAWER(DRAWERS.PRODUCT_DETAIL);
        }
    }

    async function onClickGetStarted() {
        await GET_PRODUCTS();

        // Send GA event for clicking view products
        event('view_item_list', {
            item_list_id: 'add_box',
            item_list_name: 'add_box',
            items: [ ...products.value ]
        });

        if (products.value.length > 1) {
            SHOW_DRAWER(DRAWERS.PRODUCT_LIST);
        } else {
            SHOW_DRAWER(DRAWERS.PRODUCT_DETAIL);
        }

        UPDATE_USER(['isNewUser', false]);

        isWelcomeDialogShown.value = false;
    }
</script>
