module.exports = {
    /*
     *|-------------------------------------------------------------------------
     *| App Configuration
     *|-------------------------------------------------------------------------
     *|
     *| Define the general information to be used throughout the application.
     *|
     */

    environment: process.env.NODE_ENV,

    name: 'Space',

    version: process.env.APP_VERSION,

    urls: {
        app: process.env.APP_URL,
        api: process.env.API_URL,
        static: process.env.STATIC_URL
            ? process.env.STATIC_URL
            : process.env.APP_URL,
    },

    theme: '#000f51',

    meta: {
        title: 'Space Reimagined / Open the door to simplicity',
        description: `Forget what you know about storing things. We're the app that puts the power back in your hands with the tap of a button. This is your space, reimagined.`,
    },
};
