<template>
    <div
        id="scan-get-started"
        class="h-full flex flex-col"
    >
        <div class="flex flex-col justify-center px-6 py-8 md:py-8">
            <div class="flex justify-center items-center mb-14" />
            <div class="flex items-center">
                <div>
                    <p class="text-navy text-[32px] mb-2">
                        Scan it
                    </p>
                    <p class="text-navy text-[16px] w-[320px]">
                        Scan the Space Box ID on the label to register your box
                    </p>
                </div>
            </div>
        </div>
        <div class="flex flex-col items-center px-6">
            <button
                class="btn-primary h-[65px] mb-2 xs:w-[360px] xs:mx-4"
                :tabindex="1"
                data-message="Get Started Button"
                @click="isBoxIdentifierDrawerShown = true"
            >
                Get Started
            </button>
            <span class="text-center text-navy text-[14px] mb-4 hover:opacity-90">
                No label?
                <button
                    class="font-medium"
                    :tabindex="2"
                    data-message="Speak To Our Support Team Button"
                    @click="isHelpCenterDrawerShown = true"
                >
                    Speak to our support team
                </button>
            </span>
        </div>
        <div class="relative flex flex-col items-center justify-center mt-auto">
            <img
                :src="require('/static/assets/images/misc/scan-spaceboxes.png')"
                alt="Scan It"
                aria-labelledby="Scan It"
            >
        </div>
        <Drawer
            :is-shown="isBoxIdentifierDrawerShown"
            bg-class="bg-white-noise bg-white"
            :is-back-shown="true"
            :is-close-shown="true"
            :back-tab-index="6"
            :close-tab-index="7"
            @close="SHOW_DRAWER(null)"
            @back="isBoxIdentifierDrawerShown = false"
        >
            <ScanBoxIdentifier @close="SHOW_DRAWER(null)" />
        </Drawer>
        <Drawer
            :is-shown="isHelpCenterDrawerShown"
            bg-class="bg-white-noise bg-white"
            :is-back-shown="true"
            :is-close-shown="true"
            :close-tab-index="4"
            @close="SHOW_DRAWER(null)"
            @back="isHelpCenterDrawerShown = false"
        >
            <HelpCenter />
        </Drawer>
    </div>
</template>

<script setup>
    import { ref } from 'vue';
    import { mapMutations } from 'root-js/store/lib.js';
    import { DRAWERS } from 'root-js/constants/drawers.const.js';

    import Drawer from 'root-js/components/common/Drawer.vue';
    import ScanBoxIdentifier from 'root-js/components/your-space/ScanBoxIdentifier.vue';
    import HelpCenter from 'root-js/components/core/HelpCenter.vue';

    const { SHOW_DRAWER } = mapMutations('drawers');

    const isHelpCenterDrawerShown = ref(false);
    const isBoxIdentifierDrawerShown = ref(false);
</script>
