import { getCurrentInstance } from 'vue';

/**
 * Preloader composable.
 *
 * Provide the preloader class to a component.
 *
 * @author      Ben Carey <bdmc@sinemacula.co.uk>
 * @copyright   2022 Sine Macula Limited.
 */
export default () => getCurrentInstance().appContext.config.globalProperties.$preloader;
