<template>
    <div
        id="drop-off-complete"
        class="h-full flex flex-col"
    >
        <div class="flex flex-col justify-center px-6 py-8 md:py-8">
            <div class="flex justify-center items-center mb-14">
                <p class="text-navy text-[18px] items-center text-center">
                    Drop off
                </p>
            </div>
            <div class="flex relative items-center mb-6">
                <div>
                    <p class="text-navy text-[28px] w-[286px]">
                        Drop off
                    </p>
                    <p class="text-navy text-[14px]">
                        Take your box to any UPS Access Point
                    </p>
                </div>
            </div>
            <div class="flex items-center">
                <p class="text-navy text-[14px] w-[90px]">
                    We Ship & Store with
                </p>
                <img
                    class="h-[91px]"
                    :src="require('/static/assets/images/misc/ups.png')"
                    alt="Ups"
                    aria-labelledby="Ups"
                >
            </div>
        </div>
        <div class="mb-16">
            <img
                class="
					relative
					-right-[1px]
					md:-right-[80px] md:h-[480px]
					w-full
					md:w-[320px]
				"
                :src="require('/static/assets/images/misc/spaceboxes-dropoff.png')"
                alt="Spaceboxes Dropoff"
                aria-labelledby="Spaceboxes Dropoff"
            >
        </div>

        <div class="flex mt-auto items-center justify-center px-4 md:px-0">
            <button
                class="btn-primary h-[65px] mb-8 md:w-[360px]"
                :tabindex="1"
                data-message="Done Button"
                @click="onClickDropOffCompleteClosed"
            >
                Done
            </button>
        </div>
    </div>
</template>

<script setup>
    import { mapMutations } from 'root-js/store/lib.js';

    const { SHOW_DRAWER } = mapMutations('drawers');

    function onClickDropOffCompleteClosed() {
        SHOW_DRAWER(null);
    }
</script>
