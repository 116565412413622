<template>
    <svg
        width="50"
        height="50"
        viewBox="0 0 50 50"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g
            stroke="currentColor"
            stroke-width="1.5"
            fill="none"
            fill-rule="evenodd"
            stroke-linecap="round"
            stroke-linejoin="round"
        >
            <path
                d="M4.854 20.121c3.61.01 35.975-.054 39.943-.05.835.002.93-.083.92-.898-.007-.664.003-4.865-.002-5.28-.005-.407.206-.597.614-.598.579 0 .75.004 1.328 0 .73-.006 1.183-.393 1.276-1.096a8.392 8.392 0 0 0-.003-2.14c-.079-.633-.56-1.04-1.222-1.045C46.584 9.006 16.057 9.022 5.11 9c-1.486-.003-2.937 1.175-3.059 2.802-.022.296.006 6.658-.05 7.72-.022.409.264.547.657.551.732.007 1.46.051 2.196.048 1.226-.004 1.956 2.131 2.033 2.787.077.656-.106 1.903-.297 2.84-.405 1.987-1.232 3.839-1.941 5.728-1.694 3.242-2.21 9.431-2.103 10.113.208 1.322 1.302 2.358 2.768 2.382 1.838.031 3.679.048 5.516-.006 1.354-.039 2.588-1.103 2.704-2.776.127-1.828.396-3.638.783-5.426.369-1.703.863-3.379 1.572-4.983.698-1.58 1.459-3.129 2.712-4.378.288-.287.214-.575.206-.903-.023-1.03-.009-2.059-.009-3.088v-2.092M2 16h43"
            />
            <path
                d="M31 20c0 3.706-2.338 5.58-4.74 5.959a3.153 3.153 0 0 1-.483.035c-1.85.006-3.7.002-5.55.006-.413 0-.824 0-1.227-.104M43.996 9c-.024-.702.044-1.404-.04-2.106-.052-.44-.37-.897-1-.894-.666.004-1.332-.004-1.997.003a.975.975 0 0 0-.955.977c-.007.673-.002 1.347-.002 2.02M10 13.013c-.28-.009-.561-.024-.842-.024-2.386-.002-4.772-.001-7.158-.001M10 23h4.21c.812.001.797.283.788 1.041-.007.653-.001 1.306-.001 1.959M21.987 20l.026 3"
            />
        </g>
    </svg>
</template>
