<template>
    <form
        id="add-address"
        class="h-full flex flex-col"
        autocomplete="off"
        novalidate
        @submit.prevent="submit"
        @keydown="
            form.resetValidation('general');
            form.resetValidation($event.target.name);
        "
        @keyup.enter.exact="submit"
    >
        <div class="flex flex-col justify-center px-6 py-8 md:py-8">
            <div class="flex justify-center items-center mb-14">
                <p class="text-navy text-[18px] items-center text-center">
                    {{ props.headerTitle }}
                </p>
            </div>
            <div class="flex relative items-center">
                <div>
                    <p class="text-navy text-[28px]">
                        {{ props.title }}
                    </p>
                    <p
                        v-if="form.errors.has('general')"
                        class="text-flushing-pink text-[14px] w-[210px] md:w-[253px]"
                    >
                        {{ form.errors.get('general') }}
                    </p>
                    <p
                        v-else
                        class="text-navy text-[14px] w-[210px] md:w-[253px]"
                    >
                        {{ props.description }}
                    </p>
                </div>

                <img
                    class="absolute -right-[50px] -top-[20px] w-[140px] md:-right-[60px]"
                    :src="require('/static/assets/images/misc/clouds-grey.svg')"
                    alt="Clouds Grey"
                >
            </div>
        </div>
        <div class="flex flex-col items-center justify-center mb-4 px-4 md:px-0">
            <SearchInput
                placeholder="Start typing address…"
                :options="suggestedAddressOptions"
                type="text"
                @search="getSuggestedAddresses"
                @update:value="onSelectAddress"
            />
        </div>

        <div class="flex gap-2 items-center mb-6 px-[20px]">
            <div class="w-2/4 bg-dusk-stalk h-[1px] opacity-60" />
            <div>
                <p class="text-dusk-stalk text-[12px] opacity-60">
                    OR
                </p>
            </div>
            <div class="w-2/4 bg-dusk-stalk h-[1px] opacity-60" />
        </div>

        <div class="px-8 mb-4">
            <p class="text-navy text-[14px]">
                Enter your address manually
            </p>
        </div>
        <div class="flex flex-col items-center justify-center px-4">
            <SelectDropdown
                class="w-full mb-4"
                :options="DEFAULT_ADDRESS_LABELS"
                :disabled="isLoading"
                :input-class="{
                    'border border-flushing-pink text-flushing-pink':
                        form.errors.has('label')
                }"
                placeholder="🏠  Type of address (e.g Home)"
                @update:value="(e) => form.label = e"
            />

            <Input
                id="address_line_1"
                v-model:value="form.address_line_1"
                name="address_line_1"
                placeholder="Address"
                :class="{
                    'border border-flushing-pink text-flushing-pink':
                        form.errors.has('address_line_1')
                }"
                class="mb-4"
                :disabled="isLoading"
            />
            <Input
                id="address_line_2"
                v-model:value="form.address_line_2"
                name="address_line_2"
                placeholder="Apartment, suite, etc (optional)"
                class="mb-4"
                :disabled="isLoading"
            />
            <Input
                id="city"
                v-model:value="form.city"
                name="city"
                placeholder="City"
                :class="{
                    'border border-flushing-pink text-flushing-pink':
                        form.errors.has('city')
                }"
                class="mb-4"
                :disabled="isLoading"
            />
            <div class="flex space-x-4 w-full items-center justify-center">
                <SelectDropdown
                    v-model:value="form.state"
                    class="w-full md:w-[172px]"
                    :options="US_STATES.map((state) => state.value)"
                    :disabled="isLoading"
                    :input-class="{
                        'cursor-pointer': true,
                        'border border-flushing-pink text-flushing-pink':
                            form.errors.has('state')
                    }"
                    :is-input-read-only="true"
                    placeholder="State"
                />
                <Input
                    id="postal_code"
                    v-model:value="form.postal_code"
                    name="postal_code"
                    placeholder="Zip Code"
                    size-class="w-full md:w-[172px]"
                    :class="{
                        'border border-flushing-pink text-flushing-pink':
                            form.errors.has('postal_code')
                    }"
                    :disabled="isLoading"
                />
            </div>
            <div class="flex flex-col pt-2 mb-5 self-start px-5">
                <span
                    v-if="form.errors.has('label')"
                    class="text-flushing-pink text-[10px]"
                    v-text="form.errors.get('label')"
                />

                <span
                    v-if="form.errors.has('address_line_1')"
                    class="text-flushing-pink text-[10px]"
                    v-text="form.errors.get('address_line_1')"
                />
                <span
                    v-if="form.errors.has('city')"
                    class="text-flushing-pink text-[10px]"
                    v-text="form.errors.get('city')"
                />
                <span
                    v-if="form.errors.has('state')"
                    class="text-flushing-pink text-[10px]"
                    v-text="form.errors.get('state')"
                />
                <span
                    v-if="form.errors.has('postal_code')"
                    class="text-flushing-pink text-[10px]"
                    v-text="form.errors.get('postal_code')"
                />
            </div>
        </div>

        <div class="flex mt-auto items-center justify-center px-4 md:px-0">
            <div class="flex w-full h-[65px] md:w-[360px] mb-8">
                <button
                    v-if="!isLoading"
                    class="btn-primary h-full"
                    type="submit"
                    :disabled="isAddButtonDisabled"
                >
                    {{ submitButtonText }}
                </button>
                <img
                    v-else
                    class="animate-spin mx-auto my-auto w-[30px] h-[30px]"
                    :src="require('static/assets/images/misc/icons-spinners-i-os--black.svg')
                    "
                    alt="Icon Spinner"
                >
            </div>
        </div>
    </form>
</template>

<script setup>
    import { reactive, ref } from '@vue/reactivity';
    import { computed, onMounted } from '@vue/runtime-core';
    import { mapMutations } from 'root-js/store/lib.js';
    import {
        DEFAULT_ADDRESS_LABELS,
        US_STATES
    } from 'root-js/constants/address.const';

    import Input from 'root-js/components/common/Input.vue';
    import SelectDropdown from 'root-js/components/common/SelectDropdown.vue';
    import Form from 'root-js/utils/API/Form';
    import SearchInput from 'root-js/components/common/SearchInput.vue';
    import { useGtag } from 'vue-gtag-next';

    const { event } = useGtag();
    const emits = defineEmits([ 'success' ]);
    const props = defineProps({
        isSelectedAddress: Boolean,
        headerTitle: {
            type: String,
            default: 'Add Address'
        },
        title: {
            type: String,
            default: 'Add Address'
        },
        description: {
            type: String,
            default: 'Add a new location to send your boxes'
        },
        submitButtonText: {
            type: String,
            default: 'Add address'
        }
    });

    const { ADD_ADDRESS } = mapMutations('addresses');
    const { SET_DELIVERY_ADDRESS } = mapMutations('boxes');

    const isLoading = ref(false);
    const searchValue = ref('');
    const suggestedAddressOptions = ref([]);
    const form = reactive(new Form('create-address',
                                   {
                                       label: { value: '', rule: 'required', max: 20 },
                                       address_line_1: { value: '', rule: 'required', max: 50 },
                                       address_line_2: { value: '', max: 50 },
                                       state: { value: '', rule: 'required', max: 50 },
                                       city: { value: '', rule: 'required', max: 50 },
                                       country: { value: 'US', rule: 'required' },
                                       postal_code: { value: '', rule: 'required' }
                                   },
                                   null,
                                   { success: () => emits('success') }));

    const isAddButtonDisabled = computed(() => {
        const requiredFields = [
            'label',
            'address_line_1',
            'state',
            'city',
            'postal_code'
        ];

        return !requiredFields.every(field => form[field]);
    });

    async function getSuggestedAddresses(value, selected) {
        searchValue.value = value;

        try {
            const response = await window.api.call('get-addresses-suggestions', {
                search: value,
                selected
            });
            suggestedAddressOptions.value = response.data.data.map(address => {
                const displayText = [
                    address.address_line_1,
                    address.address_line_2,
                    address.city,
                    address.state,
                    address.postal_code
                ]
                    .filter(text => text)
                    .join(' ');

                return {
                    displayText,
                    value: address
                };
            });
        } catch (error) {
            console.error('Error fetching suggested addresses:', error);

        }
    }
    function onSelectAddress(option) {
        if (option.value.entries > 1) {
            const selected = [
                option.value.address_line_1,
                option.value.address_line_2,
                `(${option.value.entries})`,
                option.value.city,
                option.value.state,
                option.value.postal_code
            ]
                .filter(text => text)
                .join(' ');
            getSuggestedAddresses(searchValue.value, selected);
            return;
        }
        form.address_line_1 = option.value.address_line_1;
        form.address_line_2 = option.value.address_line_2;
        form.state = option.value.state;
        form.city = option.value.city;
        form.postal_code = option.value.postal_code;

        // Send GA event for selecting an address from suggestions
        event('add_shipping_info', {
            currency: 'USD',
            shipping_tier: 'Ground'
        });
    }

    async function submit() {
        isLoading.value = true;


        try {
            const { data: address } = await form.submit(true);
            ADD_ADDRESS(address);
            if (props.isSelectedAddress) {
                SET_DELIVERY_ADDRESS(address);
            }

            // Send GA event for successfully adding an address
            event('add_shipping_info', {
                currency: 'USD',
                shipping_tier: 'Ground'
            });

        } catch (error) {
            console.log('error', error);
        }

        isLoading.value = false;
    }
</script>
