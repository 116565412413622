<template>
    <form
        id="cancel-subscription"
        class="h-full flex flex-col"
        autocomplete="off"
        novalidate
        @submit.prevent="submit"
        @keydown="
            editForm.resetValidation('general');
            editForm.resetValidation($event.target.name);
        "
        @keyup.enter.exact="submit"
    >
        <div class="flex flex-col justify-center px-6 py-8 md:py-8">
            <div class="flex justify-center items-center mb-14" />
            <div class="flex relative items-center mb-4">
                <div>
                    <p class="text-navy text-[48px]">
                        Are you sure?
                    </p>
                    <p
                        v-if="editForm.errors.has('general')"
                        class="text-flushing-pink text-[18px] h-[81px]"
                        v-text="editForm.errors.get('general')"
                    />
                    <p
                        v-else
                        class="text-navy text-[18px] w-[310px]"
                    >
                        Once you cancel your subscription, you won’t be able to
                        send this box back to Space
                    </p>
                </div>
            </div>
        </div>

        <img
            class="mt-auto max-w-none w-[931px] h-[493px] ml-[30px] flex-shrink-0"
            :src="
                require('/static/assets/images/misc/figures-boxes-subscription.png')
            "
            alt="Figures Boxes Subscription"
        >

        <div class="flex flex-col items-center justify-center p-[21px]">
            <div class="flex w-full h-[65px] md:w-[360px] mb-2">
                <button
                    v-if="!isLoading"
                    class="btn-secondary"
                    type="submit"
                    :tabindex="1"
                    data-message="Cancel Subscription Button"
                    @click="$emit('submit')"
                >
                    Cancel Subscription
                </button>
                <img
                    v-else
                    class="animate-spin mx-auto my-auto w-[30px] h-[30px]"
                    :src="
                        require('static/assets/images/misc/icons-spinners-i-os--black.svg')
                    "
                    alt="Icon Spinner"
                >
            </div>
            <button
                class="btn-primary h-[65px] mb-2"
                :tabindex="2"
                data-message="Cancel Button"
                :class="{ 'opacity-50 pointer-events-none': isLoading }"
                @click.prevent="$emit('close')"
            >
                Cancel
            </button>
        </div>
    </form>
</template>

<script setup>
    import { ref, reactive } from '@vue/reactivity';
    import { mapMutations } from 'root-js/store/lib.js';

    import Form from 'root-js/utils/API/Form';

    const props = defineProps({ box: Object });

    const { SHOW_DRAWER } = mapMutations('drawers');
    const { REMOVE_BOX_BY_ID } = mapMutations('boxes');

    const isLoading = ref(false);

    const editForm = reactive(new Form('delete-box', { id: { value: props.box.id }}, null, { success: () => SHOW_DRAWER(null) }));

    async function submit() {
        isLoading.value = true;
        try {
            await editForm.submit(true);
            REMOVE_BOX_BY_ID(props.box.id);
        } catch (error) {

        // Error intentionally ignored or handled elsewhere
        }
        isLoading.value = false;
    }
</script>
