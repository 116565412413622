<template>
    <button
        id="cart-notification"
        class="
			relative
			cursor-pointer
			transition
			duration-75
			ease-linear
			hover:opacity-80
		"
        :tabindex="props.cartTabIndex"
        data-message="Cart Notification Button"
        @click="$emit('view')"
    >
        <i class="icon icon-bag" />
        <span
            v-if="totalCartBoxesQuantity"
            class="
				absolute
				-top-2
				-right-2
				h-[18px]
				w-[18px]
				bg-flushing-pink
				rounded-full
				text-white text-[10px]
				flex
				items-center
				justify-center
			"
        >
            {{ totalCartBoxesQuantity }}
        </span>
    </button>
</template>

<script setup>
    import { mapGetters } from 'root-js/store/lib.js';

    const { totalCartBoxesQuantity } = mapGetters('store');

    const props = defineProps({
        textClass: {
            type: String,
            default: 'text-[20px] text-navy'
        },
        cartTabIndex: Number
    });
</script>
