const defaultTheme = require('tailwindcss/defaultTheme');

module.exports = {
    mode: 'jit',
    content: ['./src/**/*.{js,jsx,ts,tsx,vue}', './static/*.ejs'],
    theme: {
        screens: {
            xs: '475px',
            ...defaultTheme.screens,
        },
        extend: {
            fontFamily: {
                sans: ['Gotham', 'sans-serif'],
                'icon-font': ['icon-font', 'sans-serif'],
            },
            colors: {
                transparent: 'transparent',
                current: 'currentColor',
                white: '#ffffff',
                navy: {
                    DEFAULT: '#000f51',
                    5: '#000f510D',
                    20: '#000f5133',
                    25: '#000f5140',
                    60: '#000f5199',
                },
                indigo: '#070d25',
                orange: '#fd9c3d',
                rouge: '#c1151b',
                'wannabe-dutty': '#0000000D',
                'dusk-stalk': '#000f5180',
                'dawn-stalk': '#000f5133',
                'pale-orange': '#ffbd59',
                'light-gold': '#ffdb5a',
                'aqua-green': '#16d496',
                'bruised-ego': '#7f85ad',
                'rusty-red': '#d41616',
                'flushing-pink': '#dd0473',
                'dutty-grey': '#c1c1c1',
                'lighter-grey': '#d8d8d8',
                'light-bite': '#b2b7ca',
                'the-abbys': '#0000001A;',
                'duller-than-dull': '#d8d8d866',
                'bloody-nose': '#a90057',
                'unknown-grey': {
                    DEFAULT: '#bfc3d3',
                    100: '#c1c1c11A',
                    200: '#e2e2e2',
                    300: '#f1f1f1',
                    400: '#7f87a8',
                    500: '#e1e2ea',
                    600: '#7880a3',
                    700: '#eeeeee',
                    800: '#f6f6f6',
                },
                'strangle-hold-blue': '#4743f7',
                'blue-in-the-face': '#5dafe7',
                'mountain-meadow': '#16D496',
                'carnation-pink': '#fe95cb',
                mustard: '#fedb59',
                terracotta: '#e7675e',
            },
            backgroundImage: {
                'space-box': "url('/static/assets/images/misc/spacebox.png')",
                'serendipity-labs':
                    "url('/static/assets/images/misc/serendipity-labs.png')",
                'grey-star': "url('/static/assets/images/misc/greystar.png')",

                'blue-noise':
                    "linear-gradient(to bottom, theme('colors.navy.DEFAULT') 30%, transparent 120%), url('/static/assets/images/misc/noise-blue.png')",
                'blue-noise-1':
                    "linear-gradient(to bottom, theme('colors.navy.DEFAULT') 0%, theme('colors.navy.60') 30%), url('/static/assets/images/misc/noise-blue.png')",
                'blue-noise-2':
                    "linear-gradient(to bottom, theme('colors.navy.DEFAULT') 0%, theme('colors.navy.60') 60%), url('/static/assets/images/misc/noise-blue.png')",
                'white-noise':
                    "url('/static/assets/images/misc/noise-white.png')",

                'flushy-pink-gradient':
                    "linear-gradient(130deg, theme('colors.flushing-pink') 38%, theme('colors.navy.DEFAULT') 238%)",
                'bloody-nose-gradient':
                    "linear-gradient(130deg, theme('colors.bloody-nose') 0%, theme('colors.flushing-pink') 100%)",
            },
            boxShadow: {
                thin: "0 1px 0 0 theme('colors.lighter-grey')",
                ambient: '0 13px 13px 0 rgba(0, 0, 0, 0.1)',
                input: '0 7px 7px 0 rgba(0, 0, 0, 0.05)',
                price: '0 2px 13px 0 rgba(0, 0, 0, 0.32)',
                view: '-13px 0 13px 0 rgba(0, 0, 0, 0.5)',
            },
        },
    },
    variants: {
        extend: {
            visibility: ['group-hover'],
            display: ['group-hover'],
            colors: ['disabled'],
        },
    },
};
