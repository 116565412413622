<template>
    <authenticated-layout> Notifications </authenticated-layout>
</template>

<script>
    import AuthenticatedLayout from 'root-js/layouts/AuthenticatedLayout';

    export default { components: { AuthenticatedLayout }};
</script>

<style></style>
