import User from 'root-js/models/User';

const getNavigatorLanguage = () => {
    if (navigator.languages && navigator.languages.length) {
        return navigator.languages[0];
    }
    return (
        navigator.userLanguage
        || navigator.language
        || navigator.browserLanguage
        || 'en'
    );
};

// Ensure the module is namespaced
const namespaced = true;

/*
 *|-----------------------------------------------------------------------------
 *| State
 *|-----------------------------------------------------------------------------
 */
const state = {
    user: localStorage.getItem('user')
        ? new User(JSON.parse(localStorage.getItem('user')))
        : null,
    locale: getNavigatorLanguage().slice(0, 2)
};

/*
 *|-----------------------------------------------------------------------------
 *| Getters
 *|-----------------------------------------------------------------------------
 */
const getters = {

    /**
     * Determine if the user is authenticated.
     *
     * @param {object} state
     * @returns {boolean}
     */
    user: state => state.user,
    locale: state => state.locale
};

/*
 *|-----------------------------------------------------------------------------
 *| Mutations
 *|-----------------------------------------------------------------------------
 */
const mutations = {
    SET_USER: (state, user) => {
        localStorage.setItem('user', JSON.stringify(user));
        state.user = new User(user);
    },

    UNSET_USER: state => {
        localStorage.removeItem('user');
        state.user = null;
    },

    UPDATE_USER: (state, [key, value]) => {
        const updatedUser = { ...state.user, [key]: value };

        localStorage.setItem('user', JSON.stringify(updatedUser));
        state.user = new User(updatedUser);
    }
};

/*
 *|-----------------------------------------------------------------------------
 *| Actions
 *|-----------------------------------------------------------------------------
 */
const actions = {
    GET_USER: async context => {
        try {
            const response = await window.api.call('get-self');

            context.commit('SET_USER', response.data.data);

            return response.data.data;
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    },
    UPDATE_USER: async context => {
        const response = await window.api.call('update-user');
        context.commit('SET_USER', response.data.data);
        return response.data.data;
    },
    CREATE_USER: async context => {
        const response = await window.api.call('create-user');
        context.commit('SET_USER', response.data.data);
        return response.data.data;
    }
};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
};
