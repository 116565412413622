import { CARD_BRAND, CARD_BRAND_DETAILS } from '../constants/space.const';

/**
 * PaymentMethod Model.
 *
 * @author      Ben Carey <bdmc@sinemacula.co.uk>
 * @copyright   2022 Sine Macula Limited.
 */
export default class PaymentMethod {

    /**
     * Create a new PaymentMethod instance.
     *
     * @param {object}  data
     */
    constructor(data) {
        Object.assign(this, data);

        // Define the required fields for the model
        this._required = [];
    }

    /**
     * Determine whether the model is valid.
     *
     * @returns {boolean}
     */
    isValidModel() {
        return this._required.every(key => Object.keys(this).includes(key));
    }

    get cardImage() {
        return !CARD_BRAND_DETAILS[this.card_brand]
            ? CARD_BRAND_DETAILS[CARD_BRAND.SPACE].image
            : CARD_BRAND_DETAILS[this.card_brand].image;
    }

    get cardClass() {
        return !CARD_BRAND_DETAILS[this.card_brand]
            ? CARD_BRAND_DETAILS[CARD_BRAND.SPACE].class
            : CARD_BRAND_DETAILS[this.card_brand].class;
    }
}
