<template>
    <div class="bg-white h-[80px] w-full shadow-thin">
        <div class="flex items-center w-full h-full px-8">
            <h1 class="text-[28px] text-navy hidden md:block">
                {{ $route.matched[0].name }}
            </h1>

            <img
                class="w-[104.4px] h-[32px] block md:hidden"
                :src="require('static/assets/images/brand/logo-blue.svg')"
                alt="Space Logo"
            >

            <div class="ml-auto flex items-center">
                <UserDropdown class="mr-4 hidden md:block" />
                <div
                    class="h-[25px] w-[25px] rounded-full text-white bg-flushing-pink flex items-center justify-center cursor-pointer hover:bg-bloody-nose"
                    @click="SHOW_DRAWER(DRAWERS.HELP_CENTER)"
                >
                    ?
                </div>
                <span class="h-[21px] w-[1px] bg-lighter-grey mx-4" />
                <CartNotification @click="onClickAddToCart" />
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref } from 'vue';
    import { mapMutations, mapGetters, mapActions } from 'root-js/store/lib.js';
    import { DRAWERS } from 'root-js/constants/drawers.const.js';

    import CartNotification from 'root-js/components/core/CartNotification.vue';
    import UserDropdown from 'root-js/components/core/UserDropdown.vue';
    import Drawer from 'root-js/components/common/Drawer.vue';
    import ProductList from 'root-js/components/your-space/ProductList.vue';
    import YourCart from 'root-js/components/your-space/YourCart.vue';

    const { cartBoxes } = mapGetters('store');
    const { SHOW_DRAWER } = mapMutations('drawers');
    const { products } = mapGetters('store');
    const { GET_PRODUCTS } = mapActions('store');

    const isProductListDrawerShown = ref(false);
    const isYourCartDrawerShown = ref(false);

    async function onClickAddToCart() {
        if (!cartBoxes.value.filter(cartBox => cartBox.quantity).length) {
            await GET_PRODUCTS();
            products.value.length > 1
                ? SHOW_DRAWER(DRAWERS.PRODUCT_LIST)
                : SHOW_DRAWER(DRAWERS.PRODUCT_DETAIL);
            return;
        }
        SHOW_DRAWER(DRAWERS.PRODUCT_CART);
    }
</script>
