<template>
    <div
        class="relative w-[270px] h-[305px] bg-white rounded-[20px] shadow-product"
    >
        <AppBoxImage
            class="h-[157px] w-full object-cover object-center rounded-t-[20px]"
            :box="props.box"
        />
        <div class="p-[19px]">
            <h2 class="text-navy text-[22px] truncate">
                {{ props.box.title }}
            </h2>
            <p class="text-navy text-[14px] truncate mb-2">
                {{ props.box.description }}
            </p>
            <button
                class="h-[49px]"
                :class="{
                    'btn-secondary': props.box.state !== boxState.REQUEST,
                    'btn-primary': props.box.state === boxState.REQUEST
                }"
                :tabindex="props.index + 1"
                data-message="${props.box.state} Button"
                @click="$emit('box-click', props.box)"
            >
                {{ props.box.state }}
            </button>
        </div>
        <p
            class="
				absolute
				top-4
				right-4
				text-[10px]
				py-[3px]
				px-[16px]
				bg-white
				rounded-[17px]
				flex
				items-center
				justify-center
				text-navy
				shadow-badge
			"
        >
            {{ props.box.badgeText }}
        </p>
    </div>
</template>

<script setup>
    import { ref } from '@vue/reactivity';
    import { BOX_STATE } from 'root-js/constants/space.const.js';

    import AppBoxImage from 'root-js/components/core/AppBoxImage.vue';

    const props = defineProps({
        index: Number,
        box: Object
    });

    const boxState = ref(BOX_STATE);
</script>
