<template>
    <div
        id="space-protection"
        class="h-full flex flex-col"
    >
        <div class="flex flex-col justify-center px-6 py-8 md:py-8">
            <div class="flex justify-center items-center mb-14" />
            <div class="flex items-center">
                <div>
                    <p class="text-navy text-[32px] mb-2">
                        Protection
                    </p>
                    <p class="text-navy text-[16px] w-[320px] mb-6">
                        You’re automatically covered by our protection plan free of charge
                    </p>
                </div>
            </div>
            <div class="flex items-center">
                <p class="text-navy text-[14px] w-[90px]">
                    We Ship & Store with
                </p>
                <img
                    class="h-[91px]"
                    :src="require('/static/assets/images/misc/ups.png')"
                    alt="Ups"
                    aria-labelledby="Ups"
                >
            </div>
        </div>
        <div class="relative flex flex-col items-center justify-center mt-auto">
            <img
                :src="require('/static/assets/images/misc/protection.png')"
                alt="Protection"
                aria-labelledby="Protection"
            >

            <div
                class="
					absolute
					bottom-0
					left-1/2
					transform
					-translate-x-1/2
					flex flex-col
					items-center
					w-full
					px-4
				"
            >
                <button
                    class="btn-primary h-[65px] mb-2 xs:w-[360px] xs:mx-4"
                    :tabindex="1"
                    data-message="Continue Button"
                    @click="$emit('view')"
                >
                    Continue
                </button>
                <span
                    class="
						text-center text-navy text-[14px]
						whitespace-nowrap
						mb-4
						hover:opacity-90
					"
                >
                    Learn more in our
                    <a
                        href="https://spacereimagined.io/terms"
                        target="_blank"
                        rel="noopener noreferrer"
                        :tabindex="2"
                        data-message="Terms & Conditions Button"
                        class="font-medium text-flushing-pink hover:text-bloody-nose"
                    >
                        Terms & Conditions
                    </a>
                </span>
            </div>
        </div>
    </div>
</template>

<script setup>
</script>
