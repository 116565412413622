<template>
    <div class="w-full p-3 rounded-b-md">
        <div class="grid grid-cols-7 place-items-center gap-x-2 gap-y-4">
            <div
                v-for="day in days"
                :key="day"
            >
                <span class="text-navy text-base font-medium">{{ day.substr(0, 1) }}</span>
            </div>

            <template
                v-for="(d, index) in dates"
                :key="d.date"
            >
                <template v-if="index === 0">
                    <div
                        v-for="i in d.day"
                        :key="i"
                    />
                </template>

                <button
                    class="w-9 h-9 rounded-full flex items-center justify-center text-navy font-normal"
                    :class="{
                        'bg-flushing-pink text-gray-50': isSelectedDate(d.date),
                        'cursor-not-allowed line-through text-lighter-grey': isDateDisabled(d) && !isFutureDate(d),
                        'cursor-not-allowed text-lighter-grey': d.day === 6 || d.day === 0 || isFutureDate(d) || isHoliday(d)
                    }"
                    :disabled="isDateDisabled(d) || d.day === 6 || d.day === 0 || isHoliday(d)"
                    @click="() => selected(d.date)"
                >
                    <span>{{ d.date }}</span>
                </button>
            </template>
        </div>
    </div>
</template>

<script setup>
    import dayjs from 'dayjs';
    import { ref, watch,  computed } from 'vue';
    import { mapGetters } from 'root-js/store/lib.js';

    const { pickupDetails } = mapGetters('boxes');

    const dateProps = defineProps({
        selectedValues: { type: Object },
        selectedDate: {
            type: Number,
            default: null
        },
        validEndDate: {
            type: Object,
            required: true
        },

        currentActiveMonthYear: {
            type: Object,
            required: true
        }
    });

    const dateEmit = defineEmits([ 'selected' ]);

    const dates = ref([]);

    watch(() => dateProps.selectedValues, newValue => {
        generateDatesForThatMonth(newValue.month, newValue.year);
    }, { immediate: true, deep: true });

    const days = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
    ];


    const lastValidDate = computed(() => {
        const validDates = Object.keys(pickupDetails.value.dates).map(date => dayjs(date))
            .slice(0, 30);
        return validDates.length > 0 ? validDates[validDates.length - 1] : null; // Get the last valid date
    });

    function generateDatesForThatMonth(m = dayjs().month(), y = dayjs().year()) {
        dates.value = [];
        const d = dayjs().month(m)
            .year(y);
        const daysInMonth = d.daysInMonth();
        for (let i = 1;i <= daysInMonth;i++) {
            dates.value.push({
                date: i,
                day: d.date(i).day()
            });
        }
    }

    function selected(d) {
        dateEmit('selected', d);
    }

    const isDateDisabled = computed(() => d => {


        const currentDate = dayjs(new Date(dateProps.selectedValues.year,
                                           dateProps.selectedValues.month,
                                           d.date));


        const validDates = Object.keys(pickupDetails.value.dates).map(date => dayjs(date))
            .slice(0, 30);

        const firstValidDate = validDates[0];
        const isBeforeToday = currentDate.isBefore(firstValidDate);
        const isAfterValidDates = validDates.every(validDate => currentDate.isAfter(validDate));

        return isBeforeToday || isAfterValidDates;
    });

    const isHoliday = d => {
        const currentDate = dayjs(new Date(dateProps.selectedValues.year,
                                           dateProps.selectedValues.month,
                                           d.date));
        const validDates = Object.keys(pickupDetails.value.dates).map(date => dayjs(date))
            .slice(0, 30);
        const isInValidDates = validDates.some(validDate => validDate.isSame(currentDate, 'day'));

        return !isInValidDates;
    };

    const isFutureDate = d => {

        const currentDate = dayjs(new Date(dateProps.selectedValues.year,
                                           dateProps.selectedValues.month,
                                           d.date));
        return currentDate.isAfter(lastValidDate.value);
    };

    const isSelectedDate = computed(() => date => dateProps.selectedDate === date
        && dateProps.selectedValues.month === dateProps.currentActiveMonthYear.month
        && dateProps.selectedValues.year === dateProps.currentActiveMonthYear.year);
</script>
