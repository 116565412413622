/**
 * Preloader.
 *
 * Use the preloader plugin to preload the supplied images into the cache.
 *
 * @author      Ben Carey <bdmc@sinemacula.co.uk>
 * @copyright   2022 Sine Macula Limited.
 */
export default class Preloader {

    /**
     * Preload constructor.
     *
     * @return {void}
     */
    constructor() {

        // Create the cached image storage
        this.sources = [];
        this.images = [];

    }

    /**
     * Resolve the supplied image source.
     *
     * @param {object} image
     * @return string
     */
    static resolveImageSource(image) {

        if (image instanceof Element) {
            return this.resolveImageSourceFromNode(image);
        }

        return this.resolveImageSourceFromObject(image);

    }

    /**
     * Resolve the image source from an object.
     *
     * @param {object} image
     * @return string
     */
    static resolveImageSourceFromObject(image) {
        return this.resolveSrcSet(image.source, image.srcset, image.sizes);
    }

    /**
     * Resolve the image source from a DOM node.
     *
     * @param {Element} image
     * @return string
     */
    static resolveImageSourceFromNode(image) {
        return this.resolveSrcSet(image.src, image.getAttribute('srcset'), image.getAttribute('sizes'));
    }

    /**
     * Resolve the image source from the supplied source
     * set list and optional sizes.
     *
     * @param {string} source
     * @param {string|null} srcset
     * @param {string|null} sizes
     * @return string
     */
    static resolveSrcSet(source, srcset = null, sizes = null) {

        if (srcset) {

            // Assume that the srcset images are not pixel ratio references
            let ratio = false;

            const sourcesUnsorted = {};

            /*
             * Split the srcset into separate sources with their corresponding
             * sizes
             */
            srcset.split(',').forEach(source => {

                const parts = source.trim().split(' ');

                /*
                 * 1x images do not require a size so we need to default them to
                 * 1x
                 */
                if (typeof parts[1] === 'undefined') {
                    parts[1] = '1x';
                }

                // Determine if the size is pixel ratio reference
                if (parts[1].includes('x')) {
                    ratio = true;
                }

                [ sourcesUnsorted[parseInt(parts[1])] ] = parts;

            });

            /*
             * Order the sources by key in order to ensure the correct key is
             * matched later
             */
            const sources = {};
            Object.keys(sourcesUnsorted).sort()
                .forEach(key => sources[key] = sourcesUnsorted[key]);

            // Set the source key
            let sourceKey;

            /*
             * If the supplied images are just pixel ratio references then we
             * don't need to continue to process the sizes as we can just used
             * the closest pixel ratio reference
             */
            if (ratio) {

                /*
                 * If the default pixel ratio has not been assigned in srcset
                 * then we add this to the sources collection
                 */
                if (!Object.prototype.hasOwnProperty.call(sources, 1)) {
                    sources[1] = source;
                }

                const pixelRatio = window.devicePixelRatio;

                sourceKey = Object.keys(sources).find(key => pixelRatio <= key);

            } else {

                // Default the size to full width
                let size = '100vw';

                if (sizes) {

                    const mediaQueries = {};

                    /*
                     * Split the sizes into separate media queries with their
                     * corresponding size
                     */
                    sizes.split(',').forEach(size => {

                        const parts = size.trim().match(/(\(.+\))?\s*([0-9a-z]+)/iu);

                        if (typeof parts[1] === 'undefined') {
                            [,, mediaQueries.default] = parts;
                        } else {

                            /*
                             * Strip the parentheses out and replace to make
                             * compatible with js
                             */
                            [,, mediaQueries[parts[1]]] = parts;

                        }

                    });

                    // Determine the currently matched media query
                    const matched = Object.keys(mediaQueries).find(query => window.matchMedia(query).matches);

                    // Determine the size based on the matched media query
                    size = mediaQueries[matched] || mediaQueries.default || size;

                }

                // Calculate the expected size of the image if it is in vw
                if (size.includes('vw')) {
                    size = parseInt(size) / 100 * document.documentElement.clientWidth;
                }

                /*
                 * Now size is in pixels, we can convert it to an integer and
                 * multiply it by the device pixel ratio
                 */
                size = parseInt(size) * window.devicePixelRatio;

                sourceKey = Object.keys(sources).find(key => size <= parseInt(key) + 1);

            }

            if (typeof sourceKey === 'undefined') {
                sourceKey = Math.max(...Object.keys(sources));
            }

            // Get the relevant source based on the size
            return sources[sourceKey];

        }

        return source;

    }

    /**
     * Resolve the passed image sources.
     *
     * @param {array} images
     * @return array
     */
    static resolveImageSources(images) {

        const sources = [];

        images.forEach(item => {

            // If the item is a plain string then add it
            if (typeof item === 'string') {
                sources.push(item);
                return;
            }

            // If the item is an object then process it
            if (typeof item === 'object') {
                sources.push(this.resolveImageSource(item));
            }

        });

        return sources;

    }

    /**
     * Run the preloader.
     *
     * @param {array} paths
     * @param {object} options
     * @return {void}
     */
    run(paths, options = {}) {

        // Resolve the image sources
        let sources = Preloader.resolveImageSources(paths);
        const images = [];

        /*
         * Remove any images already cached by the preloader and store them in
         * the uncached sources
         */
        sources = sources.filter(source => !this.sources.includes(source));

        // Loop through each of the sources and initiate the preloader
        sources.forEach(source => {

            // Create the image object
            const image = new Image;

            // Set the image source
            image.src = source;

            // Add the image to the uncached collection
            images.push(image);

        });

        // Track the progress of the image loading
        this._loaded(images, options, images.length);

    }

    /**
     * Track the progress of each image preload.
     *
     * @param {array} images
     * @param {object} options
     * @param {int} count
     * @param {int} progress
     * @return {void}
     */
    _loaded(images, options, count, progress = 0) {

        // Loop through the images and determine if they have completed loading
        images.forEach((image, index) => {

            if (image.complete) {

                // Add the image to cache
                this.images.push(image);
                this.sources.push(image.src);

                // Remove the image from the preloader
                images.splice(index, 1);

                // Update the progress
                progress += 1 / count * 100;

                /*
                 * If a progress callback has been provided then return the
                 * current progress update
                 */
                if (typeof options.progress === 'function') {
                    options.progress(progress);
                }

            }

        });

        /*
         * Run this function again after a delay to re-test the remaining images
         * if there are any, otherwise call the complete callback if it exists
         */
        if (images.length) {
            setTimeout(() => {
                this._loaded(images, options, count, progress);
            }, 100);
        } else if (typeof options.complete === 'function') {
            options.complete();
        }

    }

}
