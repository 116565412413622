import Pickup from '../../models/Pickup';
import Tracking from '../../models/Tracking';
import dayjs from 'root-js/plugins/Dayjs';
import { formatBoxDetails } from '../../helpers/box.helper';
import { FUTURE_DAYS_COUNT } from '../../constants/calendar.const';

const namespaced = true;

const getInitialState = () => {
    return {
        boxes: [],
        boxTrackingById: {},
        storeShipping: [],
        selectedBox: null,
        boxRequest: {
            signature_required: 0,
            saturday_delivery: 0,
            carrier_service: 'ground',
            _simulate: 1
        },
        pickupDetails: new Pickup,
        pickupDate: null,
        selectedDeliveryAddress: null
    };
};

const state = getInitialState();

const getters = {
    boxes: (state, getters) => state.boxes.map(box => {
        return {
            ...box,
            tracking: getters.boxTrackingById[box.id]
        };
    }),
    boxRequest: state => {
        return {
            ...state.boxRequest,
            shipping_address: state.selectedDeliveryAddress?.full_address || ''
        };
    },
    boxTrackingById: state => state.boxTrackingById,
    selectedBox: (state, getters) => getters.boxes.find(box => state.selectedBox === box.id),
    selectedDeliveryAddress: state => state.selectedDeliveryAddress,
    storeShipping: state => state.storeShipping,
    pickupDetails: state => state.pickupDetails,
    pickupDate: state => state.pickupDate
};

const mutations = {
    SET_BOXES: (state, payload) => {
        state.boxes = payload.map(box => formatBoxDetails(box));
    },
    ADD_BOX: (state, payload) => {
        state.boxes = [...state.boxes, formatBoxDetails(payload)];
    },
    UPDATE_BOX: (state, payload) => {
        const boxIndex = state.boxes.findIndex(box => box.id === payload.id);

        state.boxes[boxIndex] = payload;

        state.boxes = [ ...state.boxes ];
    },
    SET_SELECTED_BOX: (state, payload) => {
        state.selectedBox = payload;
    },
    SET_DELIVERY_ADDRESS: (state, payload) => {
        state.selectedDeliveryAddress = payload;
    },
    SET_STORE_SHIPPING: (state, payload) => {
        state.storeShipping = payload;
    },
    SET_BOX_TRACKING: (state, { boxId, tracking }) => {
        state.boxTrackingById = {
            ...state.boxTrackingById,
            [boxId]: new Tracking(tracking)
        };
    },
    CLEAR_DELIVERY_ADDRESS: (state, payload) => {
        state.selectedDeliveryAddress = null;
    },
    REMOVE_BOX_BY_ID: (state, payload) => {
        state.boxes = state.boxes.filter(box => box.id !== payload);
    },
    SET_PICKUP_DETAILS: (state, payload) => {
        state.pickupDetails = new Pickup(payload);
    },
    SET_PICKUP_DATE: (state, payload) => {
        state.pickupDate = payload;
    },
    RESET_STATE: state => {
        Object.assign(state, getInitialState());
    }
};

const actions = {
    GET_BOXES: async context => {
        try {
            const response = await window.api.call('get-boxes');

            const boxes = response.data.data.sort((a, b) => new Date(a.created_at) < new Date(b.created_at) ? 1 : -1);

            context.commit('SET_BOXES', boxes);

            return boxes;
        } catch (error) {
            console.error('Error fetching boxes:', error);
        }
    },
    DELETE_BOX_BY_ID: async (context, payload) => {
        const response = await window.api.call('delete-box', { id: payload });

        context.commit('REMOVE_BOX_BY_ID', payload);

        return response.data.data;
    },
    GET_STORE_SHIPPING: async (context, payload) => {
        try {
            const response = await window.api.call('get-shipping', {
                shipment_type: payload.shipment_type,
                item_id: payload.item_id
            });

            context.commit('SET_STORE_SHIPPING', response.data.data.GROUND);

            return response.data.data;
        } catch (error) {
            console.error('Error fetching store shipping:', error);
        }
    },
    GET_BOX_TRACKING_BY_ID: async (context, payload) => {
        try {
            const response = await window.api.call('get-box-tracking', { id: payload });

            const tracking = response.data.data;

            context.commit('SET_BOX_TRACKING', {
                boxId: payload,
                tracking: tracking[tracking.length - 1] || {}
            });

            return tracking;
        } catch (error) {
            console.error('Error fetching box tracking:', error);
        }
    },
    UPDATE_BOX_BY_ID: async (context, payload) => {
        const response = await window.api.call('update-box', {
            id: payload.id,
            ...payload
        });

        context.commit('UPDATE_BOX', formatBoxDetails(response.data.data));

        return response.data.data;
    },
    GET_PICKUP_DETAILS_BY_ADDRESS_ID: async (context, payload) => {
        try {
            const response = await window.api.call('get-box-pickup', {
                start_date: dayjs().format('YYYY-MM-DD'),
                end_date: dayjs()
                    .add(FUTURE_DAYS_COUNT, 'day')
                    .format('YYYY-MM-DD'),
                carrier: 'ups',
                origin_address: payload
            });

            const pickupDetails = response.data.data[0];

            context.commit('SET_PICKUP_DETAILS', pickupDetails);

            return pickupDetails;
        } catch (error) {
            console.error('Error fetching pickup details:', error);
            context.commit('SET_DELIVERY_ADDRESS', null);
        }
    }
};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
};
