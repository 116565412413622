<template>
    <Drawer
        :is-shown="drawerShown === DRAWERS.HELP_CENTER"
        bg-class="bg-white-noise bg-white"
        :is-back-shown="false"
        :is-close-shown="true"
        :close-tab-index="4"
        @close="SHOW_DRAWER(null)"
    >
        <HelpCenter />
    </Drawer>
    <Drawer
        :is-shown="drawerShown === DRAWERS.PRODUCT_LIST"
        bg-class="bg-blue-noise"
        :is-close-shown="false"
        @close="SHOW_DRAWER(null)"
    >
        <ProductList
            :box="selectedBox"
            @close="SHOW_DRAWER(null)"
        />
    </Drawer>
    <Drawer
        :is-shown="drawerShown === DRAWERS.PRODUCT_DETAIL"
        bg-class="bg-white-noise bg-white"
        :is-close-shown="false"
    >
        <ProductDetails :product="products[0]" />
    </Drawer>

    <Drawer
        :is-shown="drawerShown === DRAWERS.PRODUCT_CART"
        bg-class="bg-white-noise bg-white"
        :is-close-shown="true"
        @close="SHOW_DRAWER(null)"
    >
        <YourCart
            :box="selectedBox"
            @close="SHOW_DRAWER(null)"
        />
    </Drawer>
    <Drawer
        :is-shown="drawerShown === DRAWERS.TRACK_ARRIVING"
        :is-back-shown="false"
        :is-close-shown="true"
        @close="SHOW_DRAWER(null)"
    >
        <TrackArriving :box="selectedBox" />
    </Drawer>

    <Drawer
        :is-shown="drawerShown === DRAWERS.TRACK_REQUEST"
        :is-back-shown="false"
        :is-close-shown="true"
        @close="SHOW_DRAWER(null)"
    >
        <TrackRequest :box="selectedBox" />
    </Drawer>

    <Drawer
        :is-shown="drawerShown === DRAWERS.TRACK_SENDING"
        :is-back-shown="false"
        :is-close-shown="true"
        @close="SHOW_DRAWER(null)"
    >
        <TrackSending :box="selectedBox" />
    </Drawer>

    <Drawer
        :is-shown="drawerShown === DRAWERS.START_PACKING"
        :is-back-shown="false"
        :is-close-shown="true"
        :close-tab-index="3"
        @close="SHOW_DRAWER(null)"
    >
        <StartPacking :box="selectedBox" />
    </Drawer>

    <Drawer
        :is-shown="drawerShown === DRAWERS.CONTINUE_PACKING"
        :is-back-shown="false"
        :is-close-shown="true"
        :close-tab-index="3"
        @close="SHOW_DRAWER(null)"
    >
        <ContinuePacking :box="selectedBox" />
    </Drawer>

    <Drawer
        :is-shown="drawerShown === DRAWERS.SEND_TO_SPACE"
        :is-back-shown="false"
        :is-close-shown="true"
        :close-tab-index="5"
        @close="SHOW_DRAWER(null)"
    >
        <SendToSpace :box="selectedBox" />
    </Drawer>

    <Drawer
        :is-shown="drawerShown === DRAWERS.REQUEST"
        :is-back-shown="false"
        :is-close-shown="true"
        :close-tab-index="3"
        @close="SHOW_DRAWER(null)"
    >
        <RequestBox :box="selectedBox" />
    </Drawer>

    <Drawer
        :is-shown="drawerShown === DRAWERS.ADD_DELIVERY_ADDRESS"
        bg-class="bg-white-noise bg-white"
        :is-close-shown="true"
        @close="SHOW_DRAWER(null)"
    >
        <AddAddress @success="SHOW_DRAWER(null)" />
    </Drawer>

    <Drawer
        :is-shown="drawerShown === DRAWERS.SELECT_DELIVERY_ADDRESS"
        bg-class="bg-white-noise bg-white"
        :is-close-shown="true"
        @close="SHOW_DRAWER(null)"
    >
        <EditAddress @success="SHOW_DRAWER(null)" />
    </Drawer>

    <Drawer
        :is-shown="drawerShown === DRAWERS.ADD_PAYMENT_METHOD"
        bg-class="bg-white-noise bg-white"
        :is-close-shown="true"
        @close="SHOW_DRAWER(null)"
    >
        <AddPaymentMethod @success="SHOW_DRAWER(null)" />
    </Drawer>
</template>

<script setup>
    import { ref } from 'vue';
    import { mapMutations, mapGetters } from 'root-js/store/lib.js';
    import { BOX_STATE, BOX_SUB_STATE } from 'root-js/constants/space.const.js';
    import { BOXES } from 'root-js/constants/temp.const.js';
    import { DRAWERS } from 'root-js/constants/drawers.const.js';

    import Drawer from 'root-js/components/common/Drawer.vue';
    import TrackArriving from 'root-js/components/your-space/TrackArriving.vue';
    import TrackRequest from 'root-js/components/your-space/TrackRequest.vue';
    import TrackSending from 'root-js/components/your-space/TrackSending.vue';
    import SendToSpace from 'root-js/components/your-space/SendToSpace.vue';
    import StartPacking from 'root-js/components/your-space/StartPacking.vue';
    import RequestBox from 'root-js/components/your-space/RequestBox.vue';
    import ProductList from 'root-js/components/your-space/ProductList.vue';
    import YourCart from 'root-js/components/your-space/YourCart.vue';
    import AddAddress from 'root-js/components/address-book/AddAddress.vue';
    import EditAddress from 'root-js/components/address-book/EditAddress.vue';
    import AddPaymentMethod from 'root-js/components/your-space/AddPaymentMethod.vue';
    import HelpCenter from 'root-js/components/core/HelpCenter.vue';
    import ContinuePacking from 'root-js/components/your-space/ContinuePacking.vue';
    import ProductDetails from 'root-js/components/your-space/ProductDetails.vue';

    const { selectedBox } = mapGetters('boxes');
    const { drawerShown } = mapGetters('drawers');
    const { SHOW_DRAWER } = mapMutations('drawers');
    const { SET_SELECTED_PRODUCT } = mapMutations('store');
    const { products } = mapGetters('store');

    const boxes = ref(BOXES);
    const boxState = ref(BOX_STATE);
    const boxSubState = ref(BOX_SUB_STATE);

    function onProductViewClicked(product) {
        SET_SELECTED_PRODUCT(product);
        SHOW_DRAWER(DRAWERS.PRODUCT_DETAILS);
    }
</script>
