const namespaced = true;

const getInitialState = () => {
    return {
        addresses: [],
        selectedAddress: null
    };
};

const state = getInitialState();

const getters = {
    addresses: state => state.addresses,
    selectedAddress: state => state.selectedAddress
};

const mutations = {
    ADD_ADDRESS: (state, payload) => {
        state.addresses = [...state.addresses, payload];
    },
    SET_ADDRESSES: (state, payload) => {
        state.addresses = payload;
    },
    REMOVE_ADDRESS_BY_ID: (state, payload) => {
        state.addresses = state.addresses.filter(address => address.id !== payload);
    },
    EDIT_ADDRESS: (state, payload) => {
        const addressIndex = state.addresses.findIndex(address => address.id === payload.id);

        state.addresses[addressIndex] = payload;

        state.addresses = [ ...state.addresses ];
    },

    SET_SELECTED_ADDRESS: (state, payload) => {
        state.selectedAddress = payload;
    },
    RESET_STATE: state => {
        Object.assign(state, getInitialState());
    }
};

const actions = {
    GET_ADDRESSES: async context => {
        try {
            const response = await window.api.call('get-addresses');

            context.commit('SET_ADDRESSES', response.data.data);

            return response.data.data;
        } catch (error) {
            console.error('Error fetching addresses:', error);

        }
    },

    GET_ADDRESSES_SUGGESTIONS: async context => {
        const response = await window.api.call('get-addresses-suggestions');

        context.commit('SET_ADDRESSES', response.data.data);

        return response.data.data;
    },
    DELETE_ADDRESS: async (context, payload) => {
        const response = await window.api.call('delete-address', { id: payload });

        context.commit('REMOVE_ADDRESS_BY_ID', payload);

        return response.data.data;
    }
};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
};
