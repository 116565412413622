/**
 * Endpoint.
 *
 * @author      Ben Carey <bdmc@sinemacula.co.uk>
 * @copyright   2022 Sine Macula Limited.
 */
export default class Endpoint {

    /**
     * Endpoint constructor.
     *
     * @param {object} data
     */
    constructor(data) {

        this.name = data.name;
        this.attributes = {};
        this.attributes.method = data.method;
        this.attributes.responseType = Object.prototype.hasOwnProperty.call(data, 'responseType') ? data.responseType : 'json';
        this.attributes.path = data.path;

    }

    /**
     * Get the method for the endpoint.
     *
     * @returns {string}
     */
    method() {
        return this.attributes.method;
    }

    /**
     * Get the response type for the endpoint.
     *
     * @returns {string}
     */
    responseType() {
        return this.attributes.responseType;
    }

    /**
     * Get the path for the endpoint.
     *
     * @param {object} data
     * @returns {string}
     */
    path(data) {

        let pattern = this.attributes.path;

        Object.keys(data).forEach(key => {

            if (pattern.includes(`{${key}}`)) {

                pattern = pattern.replace(`{${key}}`, data[key]);

                delete data[key];

            }

        });

        return pattern;

    }

}
