export const COUNTRIES = {
    US: {
        displayShort: '+1',
        displayOption: '+1 United States',
        value: 'US',
        image: require('static/assets/images/misc/icons-countries-us.png')
    },
    GB: {
        displayShort: '+44',
        displayOption: '+44 United Kingdom',
        value: 'GB',
        image: require('static/assets/images/misc/icons-countries-us.png')
    },
    PH: {
        displayShort: '+63',
        displayOption: '+63 Philippines',
        value: 'PH',
        image: require('static/assets/images/misc/icons-countries-us.png')
    }
};

export const WHITELISTED_COUNTRIES = [ COUNTRIES.US ];

if (process.env.APP_ENV !== 'production') {
    WHITELISTED_COUNTRIES.push(COUNTRIES.PH, COUNTRIES.GB);
}
