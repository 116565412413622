<template>
    <div
        id="scan-box-identifier"
        class="h-full flex flex-col"
    >
        <div class="flex flex-col justify-center px-6 py-8 md:py-8">
            <div class="flex justify-center items-center mb-14">
                <p class="text-navy text-[18px] items-center text-center">
                    Box Identifier
                </p>
            </div>
            <div class="flex relative items-center">
                <div>
                    <p class="text-navy text-[28px] w-[286px]">
                        Does this ID match?
                    </p>
                    <p class="text-navy text-[14px] w-[243px]">
                        Check the number below matches the one on your box
                    </p>
                </div>

                <img
                    class="absolute -right-[50px] -top-[20px] w-[140px] md:-right-[60px]"
                    :src="require('/static/assets/images/misc/clouds-grey.svg')"
                    alt="Clouds Grey"
                >
            </div>
        </div>
        <div class="px-6 mb-16 relative">
            <button
                disabled
                class="
					bg-navy
					rounded-full
					absolute
					h-[36px]
					w-[36px]
					top-[8px]
					left-[45px]
					opacity-50
				"
            >
                <i class="icon icon icon-camera text-white text-[16px]" />
            </button>
            <Input
                v-model:value="selectedBox.serialNumber"
                class="pl-[70px] mb-2"
                placeholder="Scan your label"
                :tabindex="5"
            />
            <div class="text-center">
                <span class="text-[14px] text-navy">
                    Not your number?
                    <button
                        class="text-flushing-pink font-medium hover:text-bloody-nose"
                        :tabindex="4"
                        data-message="Select manually"
                        @click="isScanSelectBoxIdentifierDrawerShown = true"
                    >
                        Select manually
                    </button>
                </span>
            </div>
        </div>

        <div class="flex flex-col mt-auto items-center justify-center p-[21px]">
            <button
                class="btn-secondary h-[65px] mb-2"
                :tabindex="1"
                data-message="Drop it Off Button"
                @click="onDropOffClicked"
            >
                Drop it Off
            </button>

            <button
                class="btn-primary mb-2 h-[65px]"
                :tabindex="2"
                data-message="Schedule Pick Up Button"
                @click="onPickUpClicked"
            >
                Schedule Pick Up
            </button>

            <div class="text-center">
                <p class="text-[14px] text-navy">
                    Not quite ready?
                    <button
                        class="text-flushing-pink font-medium hover:text-bloody-nose"
                        :tabindex="3"
                        data-message="Save and skip for now"
                        @click="onSaveSkipClicked"
                    >
                        Save and skip for now
                    </button>
                </p>
            </div>
        </div>
        <Drawer
            :is-shown="isScanSelectBoxIdentifierDrawerShown"
            bg-class="bg-white-noise bg-white"
            :is-back-shown="true"
            :is-close-shown="true"
            :back-tab-index="3"
            :close-tab-index="4"
            @close="SHOW_DRAWER(null)"
            @back="isScanSelectBoxIdentifierDrawerShown = false"
        >
            <ScanSelectBoxIdentifier
                :box="selectedBox"
                @view="isScanSelectBoxIdentifierDrawerShown = false"
                @success="isScanSelectBoxIdentifierDrawerShown = false"
            />
        </Drawer>
        <Drawer
            :is-shown="isTermsAndConditionDrawerShown"
            bg-class="bg-white-noise bg-white"
            :is-back-shown="true"
            :is-close-shown="true"
            :close-tab-index="3"
            :back-tab-index="2"
            @close="SHOW_DRAWER(null)"
            @back="isTermsAndConditionDrawerShown = false"
        >
            <TermsAndCondition @agree="isUpsProtectionPlanDrawerShown = true" />
        </Drawer>

        <Drawer
            :is-shown="isUpsProtectionPlanDrawerShown"
            bg-class="bg-white-noise bg-white"
            :is-back-shown="true"
            :is-close-shown="true"
            :close-tab-index="4"
            :back-tab-index="3"
            @close="SHOW_DRAWER(null)"
            @back="isUpsProtectionPlanDrawerShown = false"
        >
            <UpsProtectionPlan @view="onUpsAgree" />
        </Drawer>
        <Drawer
            :is-shown="isPickUpLocationDrawerShown"
            bg-class="bg-white-noise bg-white"
            :is-back-shown="true"
            :is-close-shown="true"
            :close-tab-index="4"
            :back-tab-index="3"
            @close="SHOW_DRAWER(null)"
            @back="isPickUpLocationDrawerShown = false"
        >
            <PickupLocation :box="selectedBox" />
        </Drawer>
        <Drawer
            :is-shown="isDropOffReviewDrawerShown"
            bg-class="bg-white-noise bg-white"
            :is-back-shown="true"
            :is-close-shown="true"
            :close-tab-index="6"
            :back-tab-index="5"
            @close="SHOW_DRAWER(null)"
            @back="isDropOffReviewDrawerShown = false"
        >
            <DropOffReview :box="selectedBox" />
        </Drawer>
    </div>
</template>

<script setup>
    import { ref } from 'vue';
    import { onMounted } from '@vue/runtime-core';
    import { mapMutations, mapGetters, mapActions } from 'root-js/store/lib.js';

    import Input from 'root-js/components/common/Input.vue';
    import Drawer from 'root-js/components/common/Drawer.vue';
    import ScanSelectBoxIdentifier from 'root-js/components/your-space/ScanSelectBoxIdentifier.vue';
    import TermsAndCondition from 'root-js/components/your-space/TermsAndCondition.vue';
    import UpsProtectionPlan from 'root-js/components/your-space/UpsProtectionPlan.vue';
    import PickupLocation from 'root-js/components/your-space/PickupLocation.vue';
    import DropOffReview from 'root-js/components/your-space/DropOffReview.vue';

    const emits = defineEmits([ 'close' ]);

    const { selectedBox } = mapGetters('boxes');
    const { SHOW_DRAWER } = mapMutations('drawers');
    const { UPDATE_BOX_BY_ID, GET_STORE_SHIPPING } = mapActions('boxes');


    const isScanSelectBoxIdentifierDrawerShown = ref(false);
    const isTermsAndConditionDrawerShown = ref(false);
    const isUpsProtectionPlanDrawerShown = ref(false);
    const isDropOffReviewDrawerShown = ref(false);
    const isPickUpLocationDrawerShown = ref(false);
    const selectedReturnType = ref(null);

    onMounted(() => {
        GET_STORE_SHIPPING({
            shipment_type: 'box_return',
            item_id: selectedBox.value.id
        });
    });

    function onDropOffClicked() {
        UPDATE_BOX_BY_ID({
            id: selectedBox.value.id,
            packing_status: 'packed'
        });

        isTermsAndConditionDrawerShown.value = true;
        selectedReturnType.value = 'DROP_OFF';
    }

    function onPickUpClicked() {
        UPDATE_BOX_BY_ID({
            id: selectedBox.value.id,
            packing_status: 'packed'
        });

        isTermsAndConditionDrawerShown.value = true;
        selectedReturnType.value = 'PICK_UP';
    }

    function onUpsAgree() {
        if (selectedReturnType.value === 'DROP_OFF') {
            isDropOffReviewDrawerShown.value = true;
        } else {
            isPickUpLocationDrawerShown.value = true;
        }
    }

    function onSaveSkipClicked() {
        UPDATE_BOX_BY_ID({
            id: selectedBox.value.id,
            packing_status: 'packed'
        });

        emits('close');
    }
</script>
