<template>
    <form
        class="w-full"
        autocomplete="off"
        novalidate
        @submit.prevent="submit"
        @keydown="
            form.resetValidation('general');
            form.resetValidation($event.target.name);
        "
        @keyup.enter.exact="submit"
    >
        <div class="flex items-center gap-2">
            <i class="icon icon-help-circle-outline text-navy text-[22px]" />

            <p class="text-navy text-[12px]">
                Didn’t receive a code?
                <span>
                    <button
                        type="submit"
                        class="
							text-flushing-pink
							font-medium
							disabled:text-flushing-pink
							hover:text-bloody-nose
						"
                        :disabled="isLoading"
                    >
                        {{ requestText }}
                    </button>
                </span>
            </p>
        </div>
    </form>
</template>

<script setup>
    import { reactive, ref } from '@vue/reactivity';
    import { computed, watch } from '@vue/runtime-core';
    import { mapGetters } from 'root-js/store/lib.js';
    import { useGtag } from 'vue-gtag-next';

    import Form from 'root-js/utils/API/Form';

    const emits = defineEmits(['success', 'error']);

    const { phoneDetails } = mapGetters('auth');

    const isLoading = ref(false);
    const isSent = ref(false);
    const isError = ref(false);
    const { event } = useGtag();

    const form = reactive(new Form('resend-otp',
                                   {
                                       id: { value: '', rule: 'required' },
                                       phone: { value: '', rule: 'required' }
                                   },
                                   null,
                                   {
                                       success: () => emits('success'),
                                       error: errors => emits('error', errors)
                                   }));

    const requestText = computed(() => {
        if (isSent.value) {
            return 'Code Requested';
        } else if (isError.value) {
            return 'Failed to resend code, try again?';
        } else if (isLoading.value) {
            return 'Requesting...';
        }

        return 'Request it again';
    });

    watch(() => phoneDetails,
          () => {
              form.id = phoneDetails.value.id;
              form.phone = encodeURIComponent(phoneDetails.value.completeNumber);
          },
          { immediate: true, deep: true });

    const submit = async () => {
        emits('error', false);
        isLoading.value = true;
        isError.value = false;

        try {
            await form.submit(true);
            isSent.value = true;

            // Send GA event for successful OTP resend
            event('otp_resent');
        } catch (error) {
            isError.value = true;
        }
        isLoading.value = false;
    };
</script>
