<template>
    <div
        id="select-address"
        class="h-full flex flex-col"
    >
        <div class="flex flex-col justify-center px-6 py-8 md:py-8">
            <div class="flex justify-center items-center mb-14">
                <p class="text-navy text-[18px] items-center text-center">
                    Delivery Address
                </p>
            </div>
            <div class="flex relative items-center">
                <div>
                    <p class="text-navy text-[28px]">
                        Select an address
                    </p>
                    <p class="text-navy text-[14px] w-[210px] md:w-[249px]">
                        Choose an existing address or add a new one
                    </p>
                </div>

                <img
                    class="absolute -right-[50px] -top-[20px] w-[140px] md:-right-[60px]"
                    :src="require('/static/assets/images/misc/clouds-grey.svg')"
                    alt="Clouds Grey"
                >
            </div>
        </div>

        <div class="flex flex-col items-center justify-center mb-16 px-4">
            <div
                v-for="(address, index) in addresses"
                :key="index"
                class="
					relative
					flex flex-col
					justify-center
					mb-2
					border-2
					bg-white
					shadow-wannabe-dutty
					w-full
					h-[100px]
					rounded-[20px]
					py-[27px]
					px-[24px]
					cursor-pointer
					hover:border-2 hover:border-flushing-pink
					lg:w-[360px]
				"
                :class="{
                    'border-flushing-pink':
                        selectedAddress && address.id === selectedAddress.id
                }"
                :tabindex="2"
                data-message="${address.label} Button"
                @click.prevent="onSelectedAddress(address)"
            >
                <div class="flex gap-2 mb-2">
                    <h2 class="text-navy text-[18px] font-medium">
                        {{ address.label }}
                    </h2>
                </div>
                <div>
                    <p class="text-[12px] text-dusk-stalk text-left truncate pr-4">
                        {{ address.full_address }}
                    </p>
                </div>

                <i class="absolute right-4 icon icon-chevron-right text-navy" />
            </div>
        </div>
        <div class="flex mt-auto items-center justify-center px-4 md:px-0">
            <button
                class="btn-primary h-[65px] mb-8 md:w-[360px]"
                :tabindex="1"
                data-message="Add Address Button"
                @click="onAddAddressClick"
            >
                Add Address
            </button>
        </div>

        <Drawer
            :is-shown="isAddAddressShown"
            bg-class="bg-white-noise bg-white"
            :is-back-shown="true"
            :is-close-shown="true"
            @close="SHOW_DRAWER(null)"
            @back="isAddAddressShown = false"
            @success="$emit('success')"
        >
            <AddAddress
                header-title="Delivery Address"
                title="You’re almost finished"
                description="We just need a few more details from you"
                submit-button-text="Next"
                :is-selected-address="true"
                @success="onSetDeliveryAddress"
            />
        </Drawer>
    </div>
</template>

<script setup>
    import { ref } from 'vue';
    import { mapGetters, mapMutations } from 'root-js/store/lib.js';
    import { useGtag } from 'vue-gtag-next';

    import AddAddress from 'root-js/components/address-book/AddAddress.vue';
    import Drawer from 'root-js/components/common/Drawer.vue';

    const emits = defineEmits(['createPaymentMethod', 'choosePaymentMethod']);

    const { addresses } = mapGetters('addresses');
    const { paymentMethods } = mapGetters('payments');
    const { SHOW_DRAWER } = mapMutations('drawers');
    const { SET_DELIVERY_ADDRESS } = mapMutations('boxes');

    const selectedAddress = ref(null);
    const isAddAddressShown = ref(false);

    const { event } = useGtag();

    function onSelectedAddress(address) {
        selectedAddress.value = address;
        SET_DELIVERY_ADDRESS(address);
        onSetDeliveryAddress();
    }

    function onSetDeliveryAddress() {
        if (!paymentMethods.value.length) {
            emits('createPaymentMethod');
        } else {
            emits('choosePaymentMethod');
        }

        isAddAddressShown.value = false;
    }

    function onAddAddressClick() {

        // Send event for adding shipping info
        event('add_shipping_info', {
            currency: 'USD',
            shipping_tier: 'Ground'
        });

        isAddAddressShown.value = true;
    }

</script>
