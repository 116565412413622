module.exports = {
    /*
     *|-------------------------------------------------------------------------
     *| Services Configuration
     *|-------------------------------------------------------------------------
     *|
     *| Define any information required for third-party services.
     *|
     */

    segment: { id: process.env.SEGMENT_TRACKING_ID },

    sentry: { dsn: process.env.SENTRY_DSN },
    
    googleAnalytics: { id:  process.env.GA_MEASUREMENT_ID },
};
