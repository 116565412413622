<template>
    <div
        id="scan-select-box-identifier"
        class="h-full flex flex-col"
    >
        <div class="flex flex-col justify-center px-6 py-8 md:py-8">
            <div class="flex justify-center items-center mb-14">
                <p class="text-navy text-[18px] items-center text-center">
                    Box Identifier
                </p>
            </div>
            <div class="flex relative items-center">
                <div>
                    <p class="text-navy text-[28px]">
                        Select your box ID
                    </p>
                    <p class="text-navy text-[14px] w-[243px]">
                        Check the number below matches the one on your box
                    </p>
                </div>

                <img
                    class="absolute -right-[50px] -top-[20px] w-[140px] md:-right-[60px]"
                    :src="require('/static/assets/images/misc/clouds-grey.svg')"
                    alt="Clouds Grey"
                >
            </div>
        </div>
        <div class="px-6 mb-16 relative">
            <div class="flex items-center relative group w-full">
                <SelectDropdown
                    v-model:value="selectedSwapBox"
                    class="w-full"
                    :tabindex="2"
                    :options="serialNumberOptions"
                    placeholder="Space Box ID"
                    :disabled="isLoading"
                />
            </div>
        </div>

        <div class="flex mt-auto items-center justify-center px-4 md:px-0">
            <div class="flex w-full h-[65px] md:w-[360px] mb-8">
                <button
                    v-if="!isLoading"
                    class="btn-primary mb-2 h-full"
                    :tabindex="1"
                    data-message="Continue Button"
                    @click="onUpdateBoxSerialNumber"
                >
                    Continue
                </button>
                <img
                    v-else
                    class="animate-spin mx-auto my-auto w-[30px] h-[30px]"
                    :src="
                        require('static/assets/images/misc/icons-spinners-i-os--black.svg')
                    "
                    alt="Icon Spinner"
                >
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref } from 'vue';
    import { computed } from '@vue/runtime-core';

    import { mapGetters, mapActions, mapMutations } from 'root-js/store/lib.js';

    import SelectDropdown from 'root-js/components/common/SelectDropdown.vue';

    const { boxes } = mapGetters('boxes');
    const { SET_SELECTED_BOX } = mapMutations('boxes');
    const { UPDATE_BOX_BY_ID, GET_BOXES } = mapActions('boxes');

    const emits = defineEmits([ 'success' ]);

    const props = defineProps({ box: Object });

    const isLoading = ref(false);
    const isBoxIdentifierDrawerShown = ref(false);
    const selectedSwapBox = ref('');

    const serialNumberOptions = computed(() => boxes.value
        .filter(box => box.status === 'DELIVERED_TO_CUSTOMER' && box.id !== props.box.id)
        .map(box => {
            return {
                displayText: box.serialNumber,
                value: box
            };
        }));

    async function onUpdateBoxSerialNumber() {
        isLoading.value = true;

        await UPDATE_BOX_BY_ID({
            id: props.box.id,
            box: selectedSwapBox.value.value.id
        });
        await GET_BOXES();
        SET_SELECTED_BOX(selectedSwapBox.value.value.id);
        emits('success');
        isLoading.value = false;
    }
</script>
