/**
 * Tracking Model.
 *
 * @author      Ben Carey <bdmc@sinemacula.co.uk>
 * @copyright   2022 Sine Macula Limited.
 */
export default class Tracking {

    /**
     * Create a new Tracking instance.
     *
     * @param {object}  data
     */
    constructor(data) {
        Object.assign(this, data);

        // Define the required fields for the model
        this._required = [];
    }

    /**
     * Determine whether the model is valid.
     *
     * @returns {boolean}
     */
    isValidModel() {
        return this._required.every(key => Object.keys(this).includes(key));
    }

    get destinationAddress() {
        if (!this.destination_address) {
            return '';
        }

        return this.destination_address.full_address;
    }

    get originAddress() {
        if (!this.origin_address) {
            return '';
        }

        return this.origin_address.full_address;
    }
}
