import bus from 'root-js/services/BusService';

/**
 * Preload component composable.
 *
 * Provide the component preloader to a component.
 *
 * @author      Ben Carey <bdmc@sinemacula.co.uk>
 * @copyright   2022 Sine Macula Limited.
 */
export default (images = []) => {

    /*
     * Pass the images to preload up the component tree in order to prepend to
     * the main app instance
     */
    if (images && images.length) {
        bus.emit('_preload.images', images);
    }

};
