<template>
    <AuthenticatedLayout>
        <div class="p-4 xs:p-8 lg:p-12">
            <div class="relative flex flex-wrap justify-center gap-8 mt-[80px] sm:justify-start md:mt-0 mb-10">
                <button
                    class="flex p-[23px] w-[320px] h-[100px] rounded-[20px] border-2 border-dashed border-flushing-pink space-x-4 items-center hover:bg-duller-than-dull lg:w-[360px]"
                    :tabindex="1"
                    @click="onClickAddAddress"
                >
                    <i class="icon icon-plus-circle-outline text-flushing-pink text-[55px]" />
                    <div>
                        <h2 class="text-navy text-[19px] text-left">
                            Add Address
                        </h2>
                        <p class="text-navy text-[12px] text-left">
                            Add a new location to send your boxes
                        </p>
                    </div>
                </button>

                <div
                    v-for="(address, index) in addresses"
                    :key="index"
                    class="relative flex flex-col justify-center border-2 bg-white shadow-wannabe-dutty w-[320px] h-[100px] rounded-[20px] py-[27px] px-[24px] cursor-pointer hover:border-2 hover:border-flushing-pink lg:w-[360px]"
                    :tabindex="2"
                    data-message="${address.label} Button"
                    @click="onClickAddress(address)"
                >
                    <div class="flex gap-2 mb-2">
                        <h2 class="text-navy text-[18px] font-medium">
                            {{ address.label }}
                        </h2>
                    </div>
                    <div>
                        <p class="text-[12px] text-dusk-stalk text-left truncate pr-4">
                            {{ address.full_address }}
                        </p>
                    </div>

                    <i class="absolute right-4 icon icon-chevron-right text-navy" />

                    <button
                        class="absolute -top-1 -right-1 rounded-full text-white bg-flushing-pink w-[18px] h-[18px] flex items-center justify-center hover:bg-bloody-nose"
                        :tabindex="3"
                        @click.stop="onClickDeleteAddress(address.id)"
                    >
                        <i class="icon icon-cross" />
                    </button>
                </div>
            </div>
            <button
                class="fixed right-4 bottom-[80px] h-[38px] w-[38px] bg-flushy-pink-gradient rounded-full shadow-ambient flex justify-center items-center hover:bg-bloody-nose-gradient lg:bottom-8 lg:right-10"
                @click="onClickAddAddress"
            >
                <i class="icon icon-plus text-white text-[19px]" />
            </button>

            <Dialog
                v-model:isShown="isDeleteDialogShown"
                size-class="w-[300px] sm:w-[541px] xs:w-[460px]"
                spacing-class="py-[25px] px-[30px] sm:py-[52px] sm:px-[47px]"
            >
                <div class="flex gap-8 items-center">
                    <div class="flex flex-col mb-6 sm:mb-10">
                        <h2 class="text-navy text-[28px] mb-2">
                            Confirm deletion
                        </h2>
                        <p class="text-navy text-[14px] max-w-full w-[250px] sm:w-[300px]">
                            Are you sure you want to delete this address?
                        </p>
                    </div>
                    <img
                        class="absolute w-[210px] top-10 -right-10 hidden sm:w-[249px] xs:block"
                        :src="require('/static/assets/images/misc/figures-alerts-general.png')
                        "
                        alt="Figures Alert General"
                    >
                </div>
                <div class="flex gap-2">
                    <div class="flex h-[43px] w-[105px] sm:w-[189px] sm:h-[65px] xs:w-[165px] xs:h-[55px]">
                        <button
                            v-if="!isLoading"
                            class="btn-secondary text-[14px] xs:[text-16px]"
                            @click="onClickDeleteConfirm"
                        >
                            Delete
                        </button>
                        <img
                            v-else
                            class="animate-spin mx-auto my-auto w-[30px] h-[30px]"
                            :src="require('static/assets/images/misc/icons-spinners-i-os--black.svg')
                            "
                            alt="Icon Spinner"
                        >
                    </div>
                    <div>
                        <button
                            class="btn-primary text-[14px] w-[105px] h-[43px] sm:w-[189px] sm:h-[65px] xs:w-[165px] xs:h-[55px] xs:[text-16px]"
                            @click="onClickCancel"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </Dialog>
        </div>
    </AuthenticatedLayout>
</template>

<script setup>
    import { ref } from '@vue/reactivity';
    import { useMeta } from 'vue-meta';
    import { onMounted } from '@vue/runtime-core';
    import { mapActions, mapMutations, mapGetters } from 'root-js/store/lib.js';
    import { DRAWERS } from 'root-js/constants/drawers.const.js';

    import AuthenticatedLayout from 'root-js/layouts/AuthenticatedLayout';
    import Dialog from 'root-js/components/common/Dialog.vue';
    import Drawer from 'root-js/components/common/Drawer.vue';

    useMeta({ title: 'Space / Address Book' });

    const { SHOW_DRAWER } = mapMutations('drawers');
    const { SET_SELECTED_ADDRESS } = mapMutations('addresses');
    const { addresses } = mapGetters('addresses');
    const { GET_ADDRESSES } = mapActions('addresses');
    const { DELETE_ADDRESS } = mapActions('addresses');

    const isDeleteDialogShown = ref(false);
    const selectedAddress = ref(null);
    const isLoading = ref(false);
    const addressIdForDelete = ref('');

    onMounted(() => {
        GET_ADDRESSES();
    });

    function onClickAddress(address) {
        SET_SELECTED_ADDRESS(address);
        SHOW_DRAWER(DRAWERS.SELECT_DELIVERY_ADDRESS);
    }

    function onClickDeleteAddress(id) {
        addressIdForDelete.value = id;
        isDeleteDialogShown.value = true;
    }

    function onClickCancel() {
        isDeleteDialogShown.value = false;
    }

    function closeAddAddressDrawer() {
        SHOW_DRAWER(null);
    }

    function onClickAddAddress() {
        SHOW_DRAWER(DRAWERS.ADD_DELIVERY_ADDRESS);
    }

    function onClickAddressDrawerClose() {
        SHOW_DRAWER(null);
    }

    async function onClickDeleteConfirm() {
        isLoading.value = true;

        try {
            await DELETE_ADDRESS(addressIdForDelete.value);
            isDeleteDialogShown.value = false;
        } catch (error) {
            console.error('Error deleting address:', error);
        }

        isLoading.value = false;
    }
</script>
