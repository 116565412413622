<template>
    <div
        id="sub-sidebar"
        class="
			bg-white
			text-navy
			w-full
			h-full
			relative
			flex flex-col
			pb-10
			lg:w-[370px]
			pt-20
			md:pt-0
		"
    >
        <router-link
            v-for="menu in menus"
            :key="menu.name"
            v-slot="{ href, navigate, isExactActive }"
            :to="{ name: menu.name }"
            custom
        >
            <a
                :class="{ 'bg-unknown-grey-100 pointer-events-none': isExactActive }"
                class="
					block
					relative
					border-b border-lighter-grey
					cursor-pointer
					py-7
					px-16
					hover:bg-unknown-grey-100
				"
                :href="href"
                @click="navigate"
            >
                <i
                    class="
						icon icon-chevron-right
						text-[14px]
						absolute
						top-1/2
						right-4
						transform
						-translate-y-1/2
					"
                />
                <div class="flex items-center mb-2 -ml-8">
                    <i
                        :class="menu.icon"
                        class="text-[24px] mr-4"
                    />

                    <p class="font-medium text-[18px]">{{ menu.name }}</p>
                </div>
                <p class="text-[14px] ml-2">
                    {{ menu.description }}
                </p>
            </a>
        </router-link>
        <div
            class="flex mt-auto items-center justify-center py-14 md:py-12 lg:py-0"
        >
            <button
                class="self-center w-max text-[18px] hover:opacity-80"
                @click="signOut"
            >
                <i class="icon icon-leave text-navy mr-2" />
                Sign out
            </button>
        </div>
    </div>
</template>

<script setup>
    import { ref } from '@vue/reactivity';
    import { mapActions } from 'root-js/store/lib.js';
    import router from 'root-js/routes.js';

    const { UNSET_AUTH } = mapActions('auth');

    const menus = ref([
        {
            name: 'Profile',
            isActive: true,
            icon: 'icon-profile-outline',
            description:
                'Make your account your own. Add a profile picture, your name, and email here.'
        },
        {
            name: 'Payment Methods',
            icon: 'icon-dollar-circle-outline',
            isDangerShown: true,
            description:
                'Manage your payment methods. Add one or more credit cards to your account here.'
        },
        {
            name: 'Deactivate Account',
            icon: 'icon-trash',
            description:
                'No longer need Space? Deactivate your account to remove your profile altogether.'
        }
    ]);

    async function signOut() {
        await UNSET_AUTH();
        router.push({ name: 'Welcome' });
    }
</script>
