<template>
    <AuthenticatedLayout>
        <SettingsSidebar
            :class="{
                block: isSettingSidebarFirst,
                'hidden lg:flex': !isSettingSidebarFirst
            }"
            class="flex-shrink-0"
        />
        <main
            :class="{ hidden: isSettingSidebarFirst }"
            class="py-28 px-6 h-full w-full overflow-auto md:py-12 md:px-12"
        >
            <router-view />
        </main>
    </AuthenticatedLayout>
</template>

<script setup>
    import { computed, onMounted, watch } from '@vue/runtime-core';
    import { useMeta } from 'vue-meta';

    import AuthenticatedLayout from 'root-js/layouts/AuthenticatedLayout';
    import SettingsSidebar from 'root-js/components/settings/SettingsSidebar';
    import router from 'root-js/routes.js';

    useMeta({ title: 'Space / Settings' });

    const isSettingSidebarFirst = computed(() => router.currentRoute.value.name === 'Settings');

    onMounted(() => {
        window.addEventListener('resize', redirectToProfile);
    });

    function redirectToProfile() {
        if (window.innerWidth > 1024 && isSettingSidebarFirst.value) {
            router.push({ name: 'Profile' });
        }
    }

    watch(() => router.currentRoute.value.name,
          () => {
              redirectToProfile();
          },
          { immediate: true });
</script>
