<template>
    <div class="relative group">
        <AppBoxImage
            class="rounded-t-[26px] h-[205px] w-full object-cover object-center"
            :box="props.box"
            :image="image"
            :is-deleted="isImageDeleted"
        />

        <div
            class="items-center justify-center hidden space-x-4 bg-dusk-stalk absolute top-0 w-full h-full cursor-pointer transition duration-75 ease-linear rounded-t-[26px] group-hover:flex"
        >
            <button
                v-if="false"
                class="icon icon icon-camera text-white border border-white rounded-full p-[10px] text-[24px] transition duration-75 ease-linear hover:bg-white hover:text-navy"
                @click.prevent=""
            />
            <button
                class="icon icon icon-upload text-white border border-white rounded-full p-[10px] text-[24px] transition duration-75 ease-linear hover:bg-white hover:text-navy"
                @click.prevent="$refs.boxImageInput.click"
            />
            <input
                ref="boxImageInput"
                type="file"
                class="hidden"
                accept="image/*"
                @change="onFileChange"
            >
            <button
                class="icon icon icon-trash text-white border border-white rounded-full p-[10px] text-[24px] transition duration-75 ease-linear hover:bg-white hover:text-navy"
                @click.prevent="onDeleteImage"
            />
        </div>

        <button
            class="absolute h-[36px] w-[36px] bg-navy rounded-full top-[140px] right-[20px] hover:bg-opacity-90 group-hover:hidden"
        >
            <i class="icon icon icon-camera text-white text-[16px]" />
        </button>
    </div>
</template>

<script setup>
    import { reactive, ref } from '@vue/reactivity';
    import { computed } from '@vue/runtime-core';

    import AppBoxImage from 'root-js/components/core/AppBoxImage.vue';
    import Form from 'root-js/utils/API/Form';

    const emits = defineEmits([
        'update:tags',
        'update:image',
        'loading',
        'loaded',
        'success'
    ]);

    const props = defineProps({
        box: Object,
        tags: Array
    });

    const tempImageUrl = ref(null);
    const isLoading = ref(false);
    const isImageDeleted = ref(false);

    const createBoxMediaForm = reactive(new Form('create-media-box-item',
                                                 {
                                                     id: { value: props.box.id },
                                                     media: { value: undefined, rule: 'file|image' }
                                                 },
                                                 null,
                                                 { success: () => emits('success') }));

    const updateBoxMediaForm = reactive(new Form('update-media-box-item',
                                                 {
                                                     id: { value: props.box.id },
                                                     mediaId: { value: props.box.imageId },
                                                     media: { value: undefined, rule: 'file|image' },
                                                     _method: { value: 'patch' }
                                                 },
                                                 null,
                                                 { success: () => emits('success') }));

    const deleteBoxMediaForm = reactive(new Form('delete-media-box-item',
                                                 {
                                                     id: { value: props.box.id },
                                                     mediaId: { value: props.box.imageId }
                                                 },
                                                 null,
                                                 { success: () => emits('success') }));

    const image = computed(() => {
        const boxImage = props.box.image ? props.box?.originalImage : null;

        return tempImageUrl.value || boxImage;
    });

    async function onDeleteImage() {
        isImageDeleted.value = true;
        emits('loading');
        try {
            await deleteBoxMediaForm.submit(true);
        } catch (error) {

        // Error intentionally ignored or handled elsewhere
        }

        emits('loaded');
    }

    async function onFileChange(e) {
        const files = e.target.files || e.dataTransfer.files;
        if (!files.length) {
            return;
        }

        const file = files[0];

        emits('loading');
        createBoxMediaForm.media = file;
        updateBoxMediaForm.media = file;

        const formUsed = props.box.imageId
            ? updateBoxMediaForm
            : createBoxMediaForm;

        formUsed.submit(true).then(() => emits('loaded'));

        const reader = new FileReader;

        reader.onload = e => {
            tempImageUrl.value = e.target.result;
        };
        reader.readAsDataURL(file);
    }
</script>
