<template>
    <button
        class="rounded-full"
        :class="[bgClass, sizeClass]"
        v-bind="$attr"
        v-on="$listener"
    >
        <slot />
    </button>
</template>

<script>
    export default {
        props: {
            bgClass: {
                default: 'bg-navy',
                type: String
            },

            sizeClass: {
                default: 'h-[34px] w-[120px]',
                type: String
            },

            fontClass: {
                default: 'text-[10px]',
                type: String
            }
        }
    };
</script>
