/**
 * Provide singleton instances of a service.
 *
 * @author      Ben Carey <bdmc@sinemacula.co.uk>
 * @copyright   2022 Sine Macula Limited.
 */
export default class ServiceProvider {

    /**
     * Service provider constructor.
     *
     * @return {void}
     */
    constructor() {

        // Prevent the class from being constructed
        if (new.target === ServiceProvider) {
            throw new TypeError('Cannot construct Service Provider instances directly');
        }

        this.instance = null;
    }

    /**
     * Create the service instance.
     *
     * @return {Object}
     */
    createInstance() {
        return {};
    }

    /**
     * Get the provided instance.
     *
     * @return {Object}
     */
    getInstance() {
        if (!this.instance) {
            this.instance = this.createInstance();
        }

        return this.instance;
    }
}
