import tailwindConfig from '../../../tailwind.config';

export const BOX_STATE = {
    TRACK: 'See Details',
    START_PACKING: 'Start Packing',
    CONTINUE_PACKING: 'Continue Packing',
    SEND_TO_SPACE: 'Send to Space',
    REQUEST: 'Request'
};

export const BOX_SUB_STATE = {
    ARRIVING_BOX: 'Arriving Box',
    ARRIVING_REQUEST: 'Arriving Request',
    SENDING: 'Sending'
};

export const CARD_BRAND_COLORS = {
    STRANGLE_HOLD_BLUE:
        tailwindConfig.theme.extend.colors['strangle-hold-blue'],
    SKY_BLUE: tailwindConfig.theme.extend.colors['blue-in-the-face'],
    MOUNTAIN_MEADOW: tailwindConfig.theme.extend.colors['mountain-meadow'],
    CARNATION_PINK: tailwindConfig.theme.extend.colors['carnation-pink'],
    MUSTARD: tailwindConfig.theme.extend.colors.mustard,
    TERRACOTTA: tailwindConfig.theme.extend.colors.terracotta,
    DEFAULT_NAVY: tailwindConfig.theme.extend.colors.navy.DEFAULT
};

export const CARD_BRAND = {
    MASTERCARD: 'mastercard',
    VISA: 'visa',
    DISCOVER: 'discover',
    AMEX: 'amex',
    SPACE: 'space'
};

export const CARD_BRAND_DETAILS = {
    [CARD_BRAND.MASTERCARD]: {
        image: require('/static/assets/images/misc/credit-cards-logos-mastercard.svg'),
        colors: [
            CARD_BRAND_COLORS.STRANGLE_HOLD_BLUE,
            CARD_BRAND_COLORS.SKY_BLUE
        ]
    },
    [CARD_BRAND.VISA]: {
        image: require('/static/assets/images/misc/credit-cards-logos-visa.svg'),
        colors: [
            CARD_BRAND_COLORS.CARNATION_PINK,
            CARD_BRAND_COLORS.MUSTARD,
            CARD_BRAND_COLORS.TERRACOTTA,
            CARD_BRAND_COLORS.SKY_BLUE
        ]
    },
    [CARD_BRAND.DISCOVER]: {
        image: require('/static/assets/images/misc/credit-cards-logos-discover.png'),
        class: 'h-[50px]',
        colors: [
            CARD_BRAND_COLORS.SKY_BLUE,
            CARD_BRAND_COLORS.CARNATION_PINK,
            CARD_BRAND_COLORS.MUSTARD,
            CARD_BRAND_COLORS.MOUNTAIN_MEADOW
        ]
    },
    [CARD_BRAND.AMEX]: {
        image: require('/static/assets/images/misc/credit-cards-logos-amex.png'),
        class: 'h-[50px]',
        colors: [
            CARD_BRAND_COLORS.MOUNTAIN_MEADOW,
            CARD_BRAND_COLORS.CARNATION_PINK,
            CARD_BRAND_COLORS.MUSTARD,
            CARD_BRAND_COLORS.SKY_BLUE,
            CARD_BRAND_COLORS.DEFAULT_NAVY
        ]
    },
    [CARD_BRAND.SPACE]: {
        image: require('/static/assets/images/misc/clouds-pink.png'),
        class: 'h-[50px]',
        colors: [ CARD_BRAND_COLORS.DEFAULT_NAVY ]
    }
};
