export default {

    // Boolean to indicate whether the DOM is locked
    locked: false,

    // Array of unique identifiers that are requesting the body to be locked
    identifiers: [],

    /**
     * Add the specified id to the lock array.
     *
     * @param {string} id
     * @returns {void}
     */
    lock(id) {

        // Add the id to the identifiers array if it does not already exist
        if (!this.identifiers.includes(id)) {
            this.identifiers.push(id);
        }

    },

    /**
     * Remove the specified id from the lock array.
     *
     * @param {string} id
     * @returns {void}
     */
    unlock(id) {

        const index = this.identifiers.indexOf(id);

        if (index > -1) {
            this.identifiers.splice(index, 1);
        }

    },

    /**
     * Remove all identifiers and unlock the scroll.
     *
     * @returns {void}
     */
    forceUnlock() {

        while (this.identifiers.length) {
            this.identifiers.pop();
        }

    }

};
