<template>
    <div
        id="request"
        class="h-full flex flex-col"
    >
        <BoxIntro
            :box="props.box"
            :address="props.box.tracking?.destinationAddress"
            :is-editable="true"
            :edit-tab-index="2"
            @editDetails="$emit('editDetails')"
            @changeImage="$emit('changeImage')"
        />
        <div class="px-[21px] mb-16">
            <div class="flex gap-2 items-center mb-4">
                <div class="w-10/12 bg-flushing-pink h-[6px] rounded-full" />
                <div>
                    <i class="icon icon-van text-navy text-[15px]" />
                </div>
            </div>
            <p class="text-navy text-[14px] mb-4">
                {{ props.box.description }}
            </p>
            <div class="flex flex-wrap gap-2">
                <p
                    v-for="(tag, index) in props.box.tags"
                    :key="index"
                    class="
						bg-transparent
						text-[12px] text-navy
						border border-dusk-stalk
						rounded-full
						break-all
						py-[5px]
						px-[11px]
					"
                >
                    {{ tag }}
                </p>
            </div>
        </div>
        <div class="flex mt-auto items-center justify-center p-[21px]">
            <button
                class="btn-primary h-[65px] mb-2"
                :tabindex="1"
                data-message="Request Box Button"
                @click="isRequestSendDetailsShown = true"
            >
                Request Box
            </button>
        </div>
        <Drawer
            :is-shown="isRequestSendDetailsShown"
            bg-class="bg-white-noise bg-white"
            :is-back-shown="true"
            :is-close-shown="true"
            :close-tab-index="6"
            :back-tab-index="5"
            @close="SHOW_DRAWER(null)"
            @back="isRequestSendDetailsShown = false"
        >
            <RequestSendDetails :box="props.box" />
        </Drawer>
    </div>
</template>

<script setup>
    import { ref } from '@vue/reactivity';
    import { onMounted } from '@vue/runtime-core';
    import { mapGetters, mapMutations, mapActions } from 'root-js/store/lib.js';

    import BoxIntro from 'root-js/components/your-space/BoxIntro.vue';
    import Drawer from 'root-js/components/common/Drawer.vue';
    import RequestSendDetails from 'root-js/components/your-space/RequestSendDetails.vue';

    const props = defineProps({ box: Object });

    const { SHOW_DRAWER } = mapMutations('drawers');
    const { GET_STORE_SHIPPING } = mapActions('boxes');

    const isRequestSendDetailsShown = ref(false);

    onMounted(() => {
        GET_STORE_SHIPPING({
            shipment_type: 'box_request',
            item_id: props.box.id
        });
    });
</script>
